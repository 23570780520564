<template>
  <div
    class="container-fulid"
    v-show='isShow.info'>
    <div
      class="setting-body"
      id="settingModule">
      <div
        class="config-T left"
        @click="showConfig"
        v-show="sourceItem.type === 'T' && (sourceItem.platform < 10 || sourceItem.platform > 14)">
        <i class="el-icon-setting el-icon--left green"></i>
        <span>{{$t('lang.Setting')}}</span>
      </div>
      <div class="selectBox">
        <div
          class="btn-group f_right"
          ref="btnGroup"
          @click='openSelectList'>
          <button
            type="button"
            class="btn dropdown-toggle"
            data-toggle="dropdown"
            aria-expanded="false"
            :disabled="(settingObj.CurrentTCanSetBitrate && !this.$store.state.isPreviewClip)?false:true">{{SettingObj.CurrentSetting?settingObj.CurrentSetting.Name == 'Internal_Live'?$t('lang.option'):SettingObj.CurrentSetting.DisplayName:$t('lang.option')}}
            <span :class="[visibleStatus ? 'el-icon-arrow-up': 'el-icon-arrow-top']"></span>
          </button>
          <ul
            class="dropdown-menu"
            role="menu"
            id="basicModes-ul">
            <li
              :title="item.DisplayName"
              @click.stop="setModeName(index,item)"
              @contextmenu.prevent="showDefaultBox(item)"
              v-for="(item,index) in SettingObj.ModeList"
              :key="index"
              v-if="item.IsVisible">
              <span
                class="checkBox"
                :class="settingObj|isHave(item.Name)"></span>
              <a
                href="javascript:;"
                class="ellipsis">{{item.DisplayName}}</a>
            </li>
            <div
              class="userBox"
              v-show="showUserBox">
              <div class="userTest">
                <span>Name</span>
                <input
                  type="text"
                  :value="selectMode.DisplayName"
                  @input="setValue($event,'name')"
                  @focus="notUpdateInfo">
              </div>
              <div class="userTest">
                <span>Bitrate</span>
                <input
                  class='w_27'
                  type="text"
                  :value="selectMode.Bitrate"
                  @input="setValue($event,'bitrate')"
                  @focus="notUpdateInfo">
                <div
                  class="useCurrent f_right"
                  @click.stop="showCurrentValue('bitrate')">
                  <input
                    type="checkBox"
                    :checked="useCurrentObj.useCurrentBitrate">
                  <span>Use current</span>
                </div>
              </div>
              <div class="userTest">
                <span>Latency</span>
                <input
                  class='w_27'
                  type="text"
                  :value="selectMode.Delay"
                  @input="setValue($event,'delay')"
                  @focus="notUpdateInfo">
                <div
                  class="useCurrent f_right"
                  @click.stop="showCurrentValue('delay')">
                  <input
                    type="checkBox"
                    :checked="useCurrentObj.useCurrentDelay">
                  <span>Use current</span>
                </div>
              </div>
              <div class="userTest">
                <span>Mode</span>
                <input
                  class="userRadio"
                  type="radio"
                  name="cbrvbr"
                  :checked="selectMode.VideoMode === 1"
                  @click="notUpdateInfo('cbr')">
                <span>CBR</span>
                <input
                  class="userRadio"
                  type="radio"
                  name="cbrvbr"
                  :checked="selectMode.VideoMode === 0"
                  @click="notUpdateInfo('vbr')">
                <span>VBR</span>
              </div>
              <div class="userBtn">
                <button @click="setModeInfo(selectMode)">Apply</button>
                <button @click="closePage">Cancel</button>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div class="delay-module">
        <div class="delay-top">
          <p class="f_left delay-text">{{$t('lang.Delay') + '(' + $t('lang.second') + ')'}}</p>
          <div class="cbrVbrModule f_right">
            <div class="cbrVbr-ul">
              <button
                class="pointer"
                :disabled="settingObj.CurrentTCanSetDelayConfigurable?false:true"
                @click="setDynamicOrFix('Dynamic', $event)"
                :class="{greenColor:!settingObj.CurrentTCanSetDelay}">{{$t('lang.Dynamic')}}</button>
              <span :disabled="settingObj.CurrentTCanSetDelayConfigurable?false:true"> | </span>
              <button
                class="pointer"
                :disabled="settingObj.CurrentTCanSetDelayConfigurable?false:true"
                @click="setDynamicOrFix('Fixed', $event)"
                :class="{greenColor:settingObj.CurrentTCanSetDelay}">{{$t('lang.Fixed')}}</button>
            </div>
          </div>
        </div>
        <div class="bitrateSlider">
          <el-slider
            :disabled="canSetBitrateOrDelay('delay')"
            @change="dragEnd"
            v-model="delayOption.delay"
            :step='step'
            :min="delayOption.min"
            :max="delayOption.max"></el-slider>
          <input
            type="text"
            :value="delInput"
            class="bitrateInput f_right showCenter"
            :disabled="silderDisable(settingObj.CurrentTCanSetDelay)"
            @focus="focus"
            @keyup.enter="setBitrateOrDelay('enter','delay')"
            @blur="blur"
            @input="setStatusValue($event,'delay')">
        </div>
      </div>
      <div class="bitrate-module">
        <div class="bitrate-top">
          <p class="f_left bitrate-text">{{$t('lang.Bitrate') + '(' + $t('lang.kbps') + ')'}}</p>
          <div class="cbrVbrModule f_right">
            <div class="cbrVbr-ul">
              <button
                class="pointer"
                :disabled="settingObj.CurrentTCanSetVideoMode?false:true"
                @click="setMode('vbr')"
                :class="{greenColor:vcbrObj.vbr}">VBR</button>
              <span :disabled="settingObj.CurrentTCanSetDelayConfigurable?false:true"> | </span>
              <button
                class="pointer"
                :disabled="settingObj.CurrentTCanSetVideoMode?false:true"
                @click="setMode('cbr')"
                :class="{greenColor:vcbrObj.cbr}">CBR</button>
            </div>
          </div>
        </div>
        <div class="bitrateSlider">
          <el-slider
            :disabled="canSetBitrateOrDelay('bitrate')"
            @change="dragEnd"
            v-model="bitrateOption.bitrate"
            :min="bitrateOption.min"
            :max="bitrateOption.max"></el-slider>
          <input
            type="text"
            :value="bitInput"
            class="bitrateInput f_right showCenter"
            :disabled="silderDisable(settingObj.CurrentTCanSetBitrate)"
            @focus="focus"
            @keyup.enter="setBitrateOrDelay('enter','bitrate')"
            @blur="blur"
            @input="setStatusValue($event,'bitrate')">
        </div>
      </div>
      <div class="quality-select-box">
        <div class="f_left">
          <a
            @click="getQualityUrl()"
            class="common setting-quality">{{$t('lang.Quality')}}</a>
          <span class="greenColor f_right">{{showTotalQuality}}</span>
        </div>
        <div class="f_right">
          <quality-list
            :selectTotal="selectTotal"
            @changeQuality="changeQuality"></quality-list>
        </div>
      </div>
      <div class="qulityWidth">
        <div
          id="quality"
          :style="{width:width}"></div>
      </div>
    </div>
    <div id="configTBox">
      <el-dialog
        :title="$t('lang.Setting')"
        :visible.sync="dialogVisible"
        class='dialogBox'
        @close="clearPageUrl">
        <iframe
          :src="configTUrl"
          frameborder="0"></iframe>
      </el-dialog>
    </div>
    <configTBox
      v-if="configTObj.show"
      :itemInfo="itemInfo"
      :configTObj="configTObj"></configTBox>
  </div>
</template>
<script type="text/javascript">
import { mapState } from 'vuex'
import Bus from '@/assets/js/vueBus.js'
// import Tool from '../../assets/js/utils'
import { mySourceAnalyze } from '@/assets/js/googleAnalyze/mySource.js'
import { adAnalytice } from '@/assets/js/googleAnalyze/advanceControl'
import { Receiver, receviceParams } from '@/assets/api/common/receiver.js'
import { setTimeout } from 'timers'
import qualityList from '@/components/qualityList'
import configTBox from '../ConfigT/configTBox'
var Highcharts = require('highcharts')
export default {
  components: {
    qualityList,
    configTBox
  },
  data () {
    return {
      ariaExpanded: false,
      visibleStatus: false,
      qualityStatus: false,
      userBehavFlag: true,
      isFirstInit: true,
      isExecuteFocus: false,
      bitInput: 100,
      delInput: 2,
      updateQuality: true,
      step: 0.5,
      isShow: {
        info: true
      },
      bitrateOption: {
        bitrate: 100
      },
      delayOption: {
        delay: 0.5
      },
      settingObj: {
        BitrateSettingModel: {},
        CurrentSetting: {},
        CurrentTCanSetBitrate: false
      },
      vcbrObj: {
        vbr: false,
        cbr: false
      },
      refreshBitrateAndDelay: true,
      qualityArray: ['1.2Mb/s', '2.4Mb/s', '6Mb/s', '12Mb/s'],
      qualityTotalPoints: 300,
      totalPoints: 300,
      qualityData: [],
      qualitySlot: [],
      qualitySlotOrange: [],
      qualitySlotRed: [],
      qualityPlot: null,
      selectTotal: '6Mb/s',
      buttonArray: ['Interview', 'Normal', 'FastMoving', 'SD', 'TapeFeed', 'User1', 'User2'],
      showUserBox: false,
      selectMode: {},
      useCurrentObj: {
        lastBitrate: '',
        lastDelay: '',
        useCurrentBitrate: false,
        useCurrentDelay: false
      },
      dialogVisible: false,
      configTUrl: '',
      lastTime: '',
      currentTime: '',
      timer: null,
      lastDelay: '',
      lastBit: '',
      setDelay: '',
      setBit: '',
      init: {
        delay: true,
        bit: true
      },
      options: {
        chart: {
          type: 'area',
          marginLeft: 38,
          marginRight: 1,
          marginBottom: 28,
          backgroundColor: '',
          animation: false
        },
        colors: ['Lime'],
        title: {
          text: ''
        },
        credits: {
          enabled: false // 版权信息
        },
        legend: {
          enabled: false // 图例
        },
        xAxis: {
          endOnTick: true,
          startOnTick: true,
          allowDecimals: false,
          gridLineWidth: 1, // x网格线宽度
          tickWidth: 2,
          tickLength: 1,
          lineColor: '#555',
          gridLineColor: '#555',
          tickColor: '#555',
          max: 10, // x最大值
          min: 0,
          tickPositions: [0, 60, 120, 180, 240, 300],
          labels: { // 轴坐标和单位
            formatter: function () {
              return `${this.value / 60}:00` // 格式化x坐标显示
            },
            style: {
              color: '#999'
            }
          }
        },
        yAxis: {
          title: {
            text: ''
          },
          gridLineWidth: 1, // x网格线宽度
          gridLineColor: '#555',
          lineColor: '#555',
          tickWidth: 0,
          tickLength: 0,
          tickInterval: 1500,
          tickColor: '#555',
          max: 6000,
          labels: {
            formatter: function () {
              return this.value / 1000
            },
            style: {
              color: '#999'
            }
          },
          x: -5 // 设置偏移y轴坐标
        },
        tooltip: {
          formatter: function () {
            return this.y + 'kbps'
          }
        },
        plotOptions: {
          area: {
            lineWidth: 0,
            marker: { // 鼠标移入选中的点
              enabled: false,
              symbol: 'circle',
              radius: 0,
              states: {
                hover: {
                  radius: 2,
                  fillColor: 'red',
                  lineColor: '',
                  lineWidth: 1,
                  border: 1
                }
              }
            }
          }
        },
        series: [{
          name: '',
          clip: false,
          zoneAxis: 'x',
          zones: [],
          data: [],
          states: {
            hover: {
              enabled: false
            }
          }
        }]
      },
      configTObj: {
        show: false,
        bindPeerId: '',
        ip: '',
        showIP: false
      }
    }
  },
  props: {
    sourceItem: {
      type: Object,
      default: () => {
        return { msg: 'Hello World' }
      }
    },
    width: {
      type: String,
      default: '3.37rem'
    },
    rid: {
      type: String,
      default: ''
    },
    // 这个字段是用来判断当前需不需要显示R的返回值，在source页面时显不显示需要判断当前Rlive的是不是当前展开的source
    checkSourceId: {
      type: Boolean,
      default: false
    },
    // 这个字段用来判断是否用旧的保存R返回值的字段
    useOldR: {
      type: Boolean,
      default: false
    },
    pn: {
      type: String,
      default: 'rc'
    }
  },
  computed: {
    ...mapState({
      // 映射
      State: state => state,
      ReceiverInfoObj1: state => state.ReceiverInfoObj,
      GetKernelStateObj1: state => state.GetKernelStateObj,
      SettingObj1: state => state.SettingObj
    }),
    ...mapState('common', {
      States: state => state
    }),
    getCurrentRInfo () {
      if (this.useOldR) {
        return this.GetKernelStateObj1
      } else {
        let rList = this.States.deviceListCurrentRInfoData; let data = {}
        rList.map(v => {
          const currentRid = v.rid
          if (this.rid == currentRid) {
            data = JSON.parse(v.data)
          }
        })
        return data
      }
    },
    ReceiverInfoObj () {
      if (this.useOldR) {
        return this.ReceiverInfoObj1
      } else {
        let rList = this.States.deviceListReceiverDataData; let data = {}
        rList.map(v => {
          const currentRid = v.rid
          if (this.rid == currentRid) {
            data = JSON.parse(v.data)
          }
        })
        return data
      }
    },
    SettingObj () {
      if (this.useOldR) {
        this.getSettingInfo(this.SettingObj1)
        return this.SettingObj1
      } else {
        let rList = this.States.deviceListSetInfoData; let data = {}
        rList.map(v => {
          const currentRid = v.rid
          if (this.rid == currentRid) {
            data = JSON.parse(v.data)
          }
        })
        if (this.checkSourceId) {
          if (this.getCurrentRInfo.CurrentTStrIdHex) {
            if (this.sourceItem.peerId == this.getCurrentRInfo.CurrentTStrIdHex.toLowerCase()) {
              this.getSettingInfo(data)
              return data
            } else {
              return {}
            }
          } else {
            return {}
          }
        } else {
          this.getSettingInfo(data)
          return data
        }
      }
    },
    peerId: function () {
      return this.sourceItem.peerId
    },
    showTotalQuality: function () {
      if (this.useOldR) {
        if (this.ReceiverInfoObj.QualityMeasureOnGUI) {
          return this.ReceiverInfoObj.QualityMeasureOnGUI + 'kbps'
        } else {
          return 0 + 'kbps'
        }
      } else {
        if (this.getCurrentRInfo.WorkMode == 1 && (this.sourceItem.peerId == this.getCurrentRInfo.CurrentTStrIdHex.toLowerCase() || !this.checkSourceId)) {
          if (this.ReceiverInfoObj.QualityMeasureOnGUI) {
            return this.ReceiverInfoObj.QualityMeasureOnGUI + 'kbps'
          } else {
            return 0 + 'kbps'
          }
        } else {
          this.settingObj = {
            BitrateSettingModel: {},
            CurrentSetting: {}
          }
          return ''
        }
      }
    }
  },
  created () {
    this.lastTime = new Date().getTime()
    Bus.$off('restoreDefaultConfig')
    Bus.$on('restoreDefaultConfig', () => {
      this.isShow.info = false
      this.initSettingVal(() => {
        setTimeout(() => {
          this.isShow.info = true
        })
      })
    })
    let _self = this
    this.addListener()
    // 延迟为了解决可能清空后下一秒还是有数据
    Bus.$off('playVideo')
    Bus.$on('playVideo', function () {
      setTimeout(function () {
        _self.silderDisable(false)
        _self.canSetBitrateOrDelay('bitrate')
        _self.canSetBitrateOrDelay('delay')
      }, 1000)
    })
    Bus.$off('clearVideo') // 组件多次加载会绑定多次监听事件，解决方法先解除事件在绑定。
    Bus.$on('clearVideo', () => {
      this.createQuality()
    })
    this.getUserBehavior()
  },
  mounted: function () {
    this.createQuality()
    this.updataData()
  },
  watch: {
    ariaExpanded (val, oldVal) {
      console.log(val)
    },
    bitrateOption: {
      handler (newVal, oldVal) {
        if (newVal.bitrate != this.lastBit) {
          this.lastBit = newVal.bitrate
          this.isExecuteFocus = true
          setTimeout(() => {
            this.isExecuteFocus = false
            if (this.init.bit) {
              this.refreshBitrateAndDelay = true
              this.init.bit = false
            }
          })
        }
        if (this.isExecuteFocus) {
          this.refreshBitrateAndDelay = false
        }
      },
      deep: true
    },
    delayOption: {
      handler (newVal, oldVal) {
        if (newVal.delay != this.lastDelay) {
          this.lastDelay = newVal.delay
          this.isExecuteFocus = true
          setTimeout(() => {
            this.isExecuteFocus = false
            if (this.init.delay) {
              this.refreshBitrateAndDelay = true
              this.init.delay = false
            }
          })
        }
        if (this.isExecuteFocus) {
          this.refreshBitrateAndDelay = false
        }
      },
      deep: true
    },
    sourceItem: {
      handler (newVal, oldVal) {
        if (oldVal) {
          if (newVal.peerId != oldVal.peerId && oldVal.peerId != undefined) {
            this.initSettingVal()
            this.createQuality()// 切换了source
            this.getUserBehavior()
            this.lastTime = new Date().getTime()
          }
        }
      },
      immediate: true,
      deep: true
    },
    getCurrentRInfo (newVal, oldVal) {
      // 状态不是live时清空信息
      if (newVal.status == 0) {
        this.initSettingVal()
      }
      if (newVal.CurrentTStrIdHex != oldVal.CurrentTStrIdHex) {
        this.createQuality()// 切换了source
        this.getUserBehavior()
      }

      if ((newVal.WorkMode != oldVal.WorkMode) && newVal.WorkMode == '0') {
        this.createQuality()
        this.getUserBehavior()
      }
    }
  },
  methods: {
    showCurrentValue (key) {
      if (key === 'bitrate') {
        if (this.useCurrentObj.useCurrentBitrate) {
          this.selectMode.Bitrate = this.$store.state.SettingObj.CurrentSetting.Bitrate
        } else {
          this.selectMode.Bitrate = this.useCurrentObj.lastBitrate
        }
      } else {
        if (this.useCurrentObj.useCurrentDelay) {
          this.selectMode.Delay = this.$store.state.SettingObj.CurrentSetting.Delay / 1000
        } else {
          this.selectMode.Delay = this.useCurrentObj.lastDelay
        }
      }
    },
    // 创建流量图
    createQuality () {
      this.options.series[0].data = []
      for (var i = 0; i < this.qualityTotalPoints; i++) {
        this.options.series[0].data.push({ color: '', y: null, x: i })
      }

      if (this.timers) clearTimeout(this.timers)
      this.timers = setTimeout(() => {
        if ($('#quality')[0]) {
          this.chart = Highcharts.chart('quality', this.options) // 绘制图表
        }
      }, 1000)
    },
    // 更新流量图里面的数据
    updateTotalGraph (qualityVal, errorRate) {
      if (!this.chart || typeof qualityVal != 'number' || errorRate == undefined || this.getCurrentRInfo.WorkMode == 0) {
        return
      }
      // 区分显示颜色
      let color
      if (errorRate <= 0) {
        // 绿色
        color = 'Lime'
      } else if (errorRate > 0 && errorRate <= 10) {
        // 橙色
        color = 'orange'
      } else if (errorRate > 10) {
        // 红色
        color = 'red'
      }
      // 更新数组
      let data = JSON.parse(JSON.stringify(this.options.series[0].data))
      data.splice(this.qualityTotalPoints - 1, 1)
      data.forEach(v => {
        v.x += 1
      })
      data.unshift({ color, y: qualityVal || null, x: 0 })
      // // 处理图表显示色值区域
      let zones = this.formatColor(data)
      // //更新图表
      // 使用updata会重绘图表，会有性能问题，但是为了更新图标颜色，需要使用update函数
      if (!this.chart || !this.chart.series.length) return
      this.chart.series[0].update({
        data,
        zones
      })
    },
    // 用来处理图表显示不同颜色区域
    formatColor (data) {
      let color = []
      let lastColor
      lastColor = lastColor || data[0].color
      data.map(v => {
        if (v.y) {
          if (!color.length) {
            if (v.x == 0) {
              color.push({ value: v.x, color: v.color })
              lastColor = v.color
            }
          } else {
            if (lastColor != v.color) {
              color.push({ value: v.x, color: v.color })
              lastColor = v.color
            } else {
              if (color.length) {
                color[color.length - 1].value = v.x
              }
            }
          }
        }
      })
      return color
    },
    // 获取用户行为记录
    getUserBehavior () {
      this.$nextTick(() => {
        if (!this.userBehavFlag) return
        let rid = this.rid || this.ReceiverInfoObj.StrId
        if (!rid) {
          setTimeout(() => {
            this.getUserBehavior()
          }, 200)
          return
        }
        this.userBehavFlag = false
        rid = rid.toLowerCase()
        let params = {
          pn: location.hash.split('?')[0].slice(2),
          remark: rid,
          serverName: 'ccp'
        }
        this.axios.get('/ccp/tvucc-user/userBehavior/get', {
          params: params
        }).then(res => {
          this.userBehavFlag = true
          if (res.data.errorCode == '0x0') {
            let data = JSON.parse(res.data.result[rid])
            if (!data) {
              data = {
                data: '6Mb/s',
                key: 'three'
              }
            }
            this.changeQuality(obj, true)
          }
        }).catch(e => {
          this.userBehavFlag = true
        })
      })
    },
    // 记录用户选择的流量图显示
    recordUserBehavior (data, key) {
      let rid = this.State.selectRId.toLowerCase() || this.rid.toLowerCase() || (this.ReceiverInfoObj.StrId && this.ReceiverInfoObj.StrId.toLowerCase()) || ''
      let parmas = {
        pn: location.hash.split('?')[0].slice(2),
        content: JSON.stringify({ data, key, rid }),
        remark: rid
      }
      this.axios.post('/ccp/tvucc-user/userBehavior/set', parmas).then(res => { }).catch(() => {})
    },
    // 更改和显示当前的流量范围
    changeQuality (obj, init) {
      if (!this.chart) {
        setTimeout(() => {
          this.changeQuality(obj, init)
        }, 50)
        return
      }
      mySourceAnalyze(`mySourceSpeed${obj.data}`, this.$route.path) // 谷歌统计
      // 埋点统计
      let routeName = this.$route.name
      if (routeName == 'AdvanceControl') adAnalytice('adVideo', obj.data)
      // 更新流量图坐标
      this.selectTotal = obj.data
      let index = this.selectTotal.indexOf('M')
      let maxValue = Number(this.selectTotal.substring(0, index)) * 1000
      this.chart.yAxis[0].update({ // 更新坐标轴
        max: maxValue,
        tickInterval: maxValue / 4
      })
      this.options.yAxis.max = maxValue
      this.options.yAxis.tickInterval = maxValue / 4
      if (init) return
      this.recordUserBehavior(obj.data, obj.key)
    },
    updataData () {
      // 更新流量
      this.timer = setInterval(() => {
        if (this.useOldR) {
          this.updateTotalGraph(this.ReceiverInfoObj.QualityMeasureOnGUI, this.ReceiverInfoObj.ErrorRateLastSec)
        } else {
          if (this.getCurrentRInfo.WorkMode == 1 && (this.sourceItem.peerId == this.getCurrentRInfo.CurrentTStrIdHex.toLowerCase() || !this.checkSourceId)) {
            this.updateTotalGraph(this.ReceiverInfoObj.QualityMeasureOnGUI, this.ReceiverInfoObj.ErrorRateLastSec)
          }
        }
      }, 1000)
    },
    getQualityUrl () {
      if (!this.State.selectRId) return
      let params = {
        rid: this.State.selectRId.toLowerCase() || this.rid.toLowerCase() || this.ReceiverInfoObj.StrId.toLowerCase(),
        OperationType: 102,
        categoryId: 2152866816
      }

      this.axios.post(Receiver().getQualityUrl.qualityUrl, params)
        .then(res => {
          if (res.status == 200 && res.data.errorInfo == 'Success' && !JSON.parse(res.data.result).errorInfo) {
            window.open(JSON.parse(res.data.result).LiveHistogramUrl)
          } else if (JSON.parse(res.data.result).errorInfo) {
            this.$message({
              type: 'error',
              message: this.$t('lang.notSupportFunction')
            })
          }
        }).catch(() => {})
    },
    // 展开速度列表
    openSpeedList () {
      mySourceAnalyze('mySourceSpeed', this.$route.path) // 谷歌统计
      setTimeout(() => {
        let groupObj = this.$refs.qualityGroup
        this.qualityStatus = groupObj.classList.contains('open')
      })
    },
    // 展开下拉列表
    openSelectList () {
      mySourceAnalyze('mySourceSelect', this.$route.path) // 谷歌统计
      setTimeout(() => {
        let groupObj = this.$refs.btnGroup
        this.visibleStatus = groupObj.classList.contains('open')
      })
    },
    // 初始化setting值和状态
    initSettingVal (callback) {
      this.init.delay = true
      this.init.bit = true
      // 滑块值和输入框值置为0
      this.delayOption.delay = 0
      this.bitrateOption.bitrate = 0
      this.delInput = 0
      this.bitInput = 0
      // 禁用输入值
      this.settingObj.CurrentTCanSetDelayConfigurable = false
      this.settingObj.CurrentTCanSetVideoMode = false
      // 禁用滑块
      this.settingObj.CurrentTCanSetBitrate = false
      this.settingObj.CurrentTCanSetDelay = false
      this.ReceiverInfoObj.QualityMeasureOnGUI = false
      // this.createQuality();   // init 流量图
      callback && callback()
    },
    addListener () {
      document.addEventListener('click', function (e) {
        e = e || window.event
        var tar = e.srcElement || e.target
        var tarObj = $(tar).parent()
        if (!tarObj.hasClass('dropdown-menu') && !tarObj.hasClass('userTest') && !tarObj.hasClass('userBox') && !tarObj.hasClass('useCurrent')) {
          this.showUserBox = false
        } else {
          $('.statusBtn .btn-group').addClass('opent')
        }
        if (!tarObj.hasClass('dropdown-toggle')) {
          this.visibleStatus = false
          this.qualityStatus = false
        }
      }.bind(this), true)
    },

    // 获取比特率，延迟等相关信息
    getSettingInfo (data) {
      if (this.$store.state.interputObj.userObj) {
        this.$store.state.SettingObj = data
      }
      if (!data.CurrentSetting) return
      let settingInfoObj = data.CurrentSetting
      let bitrate = settingInfoObj.Bitrate
      let delay = settingInfoObj.Delay / 1000
      let canSetBitrate = data.CurrentTCanSetBitrate
      let canSetDelay = data.CurrentTCanSetDelay
      let videoMode = data.CurrentTVideoMode
      // 刷新插件的最大最小值
      this.settingObj = data
      this.bitrateOption.max = data.BitrateSettingModel ? data.BitrateSettingModel.MaximumValue : 10480
      this.bitrateOption.min = data.BitrateSettingModel ? data.BitrateSettingModel.MinimumValue : 100
      this.bitrateOption.disabled = canSetBitrate ? this.bitrateOption.disabled = false : this.bitrateOption.disabled = true
      this.delayOption.disabled = canSetDelay ? this.delayOption.disabled = false : this.delayOption.disabled = true
      this.delayOption.min = data.DelaySettingModel ? data.DelaySettingModel.MinimumValue : 0.5
      this.delayOption.max = data.DelaySettingModel ? data.DelaySettingModel.MaximumValue : 50
      var obj = {}
      if (videoMode == '0') {
        obj.vbr = true
        obj.cbr = false
        this.vcbrObj = obj
      } else {
        obj.vbr = false
        obj.cbr = true
        this.vcbrObj = obj
      }
      // 用来控制是否刷新比特率延迟的最新值
      if (this.refreshBitrateAndDelay && ((!this.setDelay || this.setDelay == delay) && (!this.setBit || this.setBit == bitrate))) {
        if (this.delayOption.delay != delay) {
          this.init.delay = true
          this.setDelay = ''
        }
        if (this.bitrateOption.bitrate != bitrate) {
          this.init.bit = true
          this.setBit = ''
        }
        if (this.setDelay == delay) {
          this.setDelay = ''
        }
        if (this.setBit == bitrate) {
          this.setBit = ''
        }
        this.bitrateOption.bitrate = bitrate || 5000
        this.delayOption.delay = delay || 2
        this.bitInput = bitrate || 5000
        this.delInput = delay || 2
      }
    },
    // send比特率延迟
    setBitrateOrDelay (key1, key2) {
      let bitrate, delay
      if (key1) {
        if (typeof this.bitInput === 'string') {
          const index = this.bitInput.indexOf('.')
          if (index > 0) {
            bitrate = this.bitInput.slice(0, index)
          } else {
            bitrate = this.bitInput
          }
        } else {
          bitrate = this.bitInput
        }
        delay = this.delInput * 1000
      } else {
        bitrate = this.bitrateOption.bitrate
        delay = this.delayOption.delay * 1000
      }
      if (isNaN(bitrate) || isNaN(delay)) {
        this.$message({
          type: 'warning',
          message: this.$t('lang.rightNumber')
        })
        return
      }
      // 超出范围提示
      if (key2 === 'bitrate') {
        if (bitrate < this.bitrateOption.min || bitrate > this.bitrateOption.max) {
          this.$message({
            type: 'warning',
            message: this.$t('lang.bitrateDelay', [this.bitrateOption.min, this.bitrateOption.max])
          })
          return
        }
      }
      if (key2 === 'delay') {
        if (delay < this.delayOption.min * 1000 || delay > this.delayOption.max * 1000) {
          this.$message({
            type: 'warning',
            message: this.$t('lang.bitrateDelay', [this.delayOption.min, this.delayOption.max])
          })
          return
        }
        delay = Math.floor(delay)
        this.delInput = delay / 1000
      }
      const params = {
        rid: this.rid.toLowerCase() || this.ReceiverInfoObj.StrId.toLowerCase(),
        bitrate: bitrate,
        delay: delay,
        pn: this.pn
      }
      if (!params.rid) return
      this.axios.post('/ccp/tvucc-device/receiver/setBitrateAndDelay', params)
        .then(res => {
          if (res.status === 200 && res.data.errorCode == '0x0') {
            if (key2 == 'bitrate') {
              this.bitrateOption.bitrate = Number(bitrate)
            } else {
              this.delayOption.delay = delay / 1000
            }
            if (!key2) {
              this.setBit = Number(bitrate)
              this.setDelay = delay / 1000
            }
          }
          this.blur()
        }).catch(() => {})
    },
    // 设置cbr,vbr
    setMode (mode) {
      mySourceAnalyze(`mySource${mode}`, this.$route.path) // 谷歌统计
      let dataStr = {
        rid: this.State.selectRId.toLowerCase() || this.rid.toLowerCase() || this.ReceiverInfoObj.StrId.toLowerCase(),
        data: '{"VideoMode":"' + mode + '"}'
      }
      let params = Object.assign(dataStr, receviceParams.setVideo.setCbrVbr)
      this.axios.post(Receiver().setVideo.setCbrVbr, params).then(res => {
        if (res.status == 200) {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
        } else {
          this.$message({
            type: 'warning',
            message: 'Error'
          })
        }
      }).catch(() => {})
    },
    setDynamicOrFix (key, element) {
      mySourceAnalyze(`mySource${key}`, this.$route.path) // 谷歌统计
      let value
      key == 'Fixed' ? value = true : value = false
      let dataStr = {
        rid: this.rid.toLowerCase() || this.ReceiverInfoObj.StrId.toLowerCase(),
        Data: '{"CanSetDelay":"' + value + '"}'
      }
      let params = Object.assign(dataStr, receviceParams.setVideo.setVideoDynamic)
      this.axios.post(Receiver().setVideo.setVideoDynamic, params)
        .then(res => {
          if (res.status == 200) {
            this.$message({
              type: 'success',
              message: this.$t('lang.success')
            })
          } else {
            this.$message({
              type: 'warning',
              message: 'Error'
            })
          }
        })
    },
    dragEnd () {
      this.refreshBitrateAndDelay = false
      this.setBit = this.bitrateOption.bitrate
      this.setDelay = this.delayOption.delay
      this.setBitrateOrDelay()
    },
    focus () {
      this.refreshBitrateAndDelay = false
      this.isExecuteFocus = true
    },
    blur () {
      this.refreshBitrateAndDelay = true
      this.isExecuteFocus = false
      $('.bitrateInput').blur()
    },
    setStatusValue (event, key) {
      if (key === 'delay') {
        this.delInput = event.currentTarget.value
      } else {
        this.bitInput = event.currentTarget.value
      }
    },
    canSetBitrateOrDelay (key) {
      if (key === 'bitrate') {
        if (!this.settingObj.CurrentTCanSetBitrate || this.$store.state.isPreviewClip) {
          return true
        } else {
          return false
        }
      } else {
        if (!this.settingObj.CurrentTCanSetDelay || this.$store.state.isPreviewClip) {
          return true
        } else {
          return false
        }
      }
    },
    silderDisable (canSet) {
      if (!canSet || this.$store.state.isPreviewClip) {
        return true
      } else {
        return false
      }
    },
    // 更改视频信息
    setModeName (index, item) {
      mySourceAnalyze(`mySourceModel${item.Name}`, this.$route.path) // 谷歌统计
      let routeName = this.$route.name
      if (routeName == 'AdvanceControl') {
        adAnalytice('adVideo', item.Name)
      }
      this.refreshBitrateAndDelay = true
      let dataStr = {
        rid: this.State.selectRId.toLowerCase() || this.rid.toLowerCase() || this.ReceiverInfoObj.StrId.toLowerCase(),
        Data: '{"ModeId":' + index + ',"ModeName":"' + item.Name + '"}'
      }
      let params = Object.assign(dataStr, receviceParams.setVideo.setVideoWorkmode)
      this.axios.post(Receiver().setVideo.setVideoWorkmode, params).then(res => {}).catch(() => {})
    },
    showDefaultBox (data) {
      if (!data.IsUserDefine) return
      this.showUserBox = true
      data.Delay = data.Delay / 1000
      this.selectMode = data
      this.useCurrentObj.lastBitrate = data.Bitrate
      this.useCurrentObj.lastDelay = data.Delay
      this.useCurrentObj.useCurrentBitrate = false
      this.useCurrentObj.useCurrentDelay = false
    },
    setValue (event, key) {
      if (key === 'delay') {
        this.selectMode.Delay = Number(event.currentTarget.value)
      } else if (key === 'bitrate') {
        this.selectMode.Bitrate = Number(event.currentTarget.value)
      } else {
        this.selectMode.DisplayName = event.currentTarget.value
      }
    },
    setModeInfo (data) {
      let videoMode
      videoMode = this.selectMode.VideoMode ? 'CBR' : 'VBR'
      let dataStr = {
        rid: this.State.selectRId.toLowerCase() || this.rid.toLowerCase() || this.ReceiverInfoObj.StrId.toLowerCase(),
        Data: '{"SettingName":"' + this.selectMode.Name + '","DisplayName":"' + data.DisplayName + '","BitRate":' + this.selectMode.Bitrate + ',"Delay":' + this.selectMode.Delay * 1000 + ',"VideoMode":"' + videoMode + '"}'
      }
      let params = Object.assign(dataStr, receviceParams.setVideo.setUserInfo)
      if (isNaN(this.selectMode.Bitrate) || isNaN(this.selectMode.Delay)) {
        this.$message({
          type: 'warning',
          message: this.$t('lang.rightNumber')
        })
        return
      }

      // 超出范围提示
      if (this.selectMode.Bitrate < this.$store.state.SettingObj.BitrateSettingModel.MinimumValue || this.selectMode.Bitrate > this.$store.state.SettingObj.BitrateSettingModel.MaximumValue) {
        this.$message({
          type: 'warning',
          message: 'Please input number between' + this.$store.state.SettingObj.BitrateSettingModel.MinimumValue + '~' + this.$store.state.SettingObj.BitrateSettingModel.MaximumValue
        })
        return
      }

      if (this.selectMode.Delay * 1000 < this.$store.state.SettingObj.DelaySettingModel.MinimumValue * 1000 || this.selectMode.Delay * 1000 > this.$store.state.SettingObj.DelaySettingModel.MaximumValue * 1000) {
        this.$message({
          type: 'warning',
          message: 'Please input number between' + this.$store.state.SettingObj.DelaySettingModel.MinimumValue + '~' + this.$store.state.SettingObj.DelaySettingModel.MaximumValue
        })
        return
      }
      this.axios.post(Receiver().setVideo.setUserInfo, params)
        .then(res => {
          if (res.status == 200) {
            this.$store.state.interputObj.userObj = true
            this.showUserBox = false
            this.$message({
              type: 'success',
              message: this.$t('lang.success')
            })
          }
        }).catch(() => {})
    },
    closePage () {
      this.showUserBox = false
      this.$store.state.interputObj.userObj = true
    },
    notUpdateInfo (key) {
      this.$store.state.interputObj.userObj = false
      if (key === 'cbr') {
        this.selectMode.VideoMode = 1
      } else if (key === 'vbr') {
        this.selectMode.VideoMode = 0
      }
    },
    showConfig () {
      mySourceAnalyze('mySourceConfitT', this.$route.path) // 谷歌统计
      this.currentTime = new Date().getTime()
      if (this.currentTime - this.lastTime < 3000) return
      this.configTObj = {
        show: true,
        bindPeerId: this.bindPeerId,
        showIP: false
      }
    },
    clearPageUrl () {
      this.configTUrl = ''
    }
  },
  filters: {
    isHave: function (obj, name) {
      if (obj.CurrentSetting.Name == name) return 'greenBox'
    }
  },
  beforeDestroy () {
    if (this.timer) clearInterval(this.timer)
  }
}

</script>
<style type="text/css" scoped>
::v-deep #configTBox .el-dialog {
  width: 59%;
}
::v-deep .el-slider .el-slider__runway {
  z-index: 0;
}
.container {
  max-width: 390px;
  margin-top: 10px;
}
.green {
  color: #33aa50;
  font-size: 20px;
  cursor: pointer;
}
#quality {
  width: 100%;
  height: 180px;
}
.config-T {
  height: 32px;
  line-height: 32px;
  cursor: pointer;
}
.quality-select-box {
  height: 30px;
  margin-bottom: 10px;
}
.setting-quality {
  margin-right: 5px;
  color: #dbdbdb;
  text-decoration: underline;
}
.bitrate-left-value,
.delay-left-value {
  position: absolute;
  top: 42px;
}
.bitrate-right-value,
.delay-right-value {
  position: absolute;
  right: 0;
  top: 42px;
}
.cbrVbr-ul,
#quality-ul {
  min-width: 100px;
}
.cbrVbr-ul span,
#quality-ul span {
  margin-left: 5px;
}
.cbrVbr-ul li:hover,
#quality-ul li:hover {
  background-color: #455f45;
}
.cbrVbr-ul a,
#quality-ul a {
  display: inline-block;
  color: #fff;
  padding: 2px 3px;
}
.cbrVbr-ul span[disabled] {
  color: gray;
}
.cbrVbr-ul button[disabled] {
  cursor: not-allowed;
  opacity: 0.48;
}
.cbrVbr-ul button {
  background: transparent;
  border: 0;
  outline: none;
}
.setting-top p {
  margin-bottom: 0;
}
.vbrCbrBtn {
  padding: 0;
  color: #fff;
  background-color: #444;
  width: 72px;
  height: 27px;
}
.cbrVbrModule {
  margin-left: 0.12rem;
}
.pointer {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.bitrateBtn {
  margin-right: 0;
}
.delay-module {
  margin-top: 25px;
}
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  color: #fff;
}
.bitrate-top,
.delay-top {
  height: 25px;
  line-height: 25px;
  overflow: hidden;
}
.bitrate-text,
.delay-text {
  margin-right: 5px;
  width: 27%;
  min-width: 95px;
}
.bitrateInput,
.delayInput {
  width: 60px;
  outline: none;
  border-radius: 3px;
  color: #fff;
  border: 1px solid #33aa50;
  background: #2e2e2e;
  text-align: center;
  /* margin-top: -20px; */
}
.border-green {
  border: 1px solid rgb(42, 134, 133);
}
input[disabled] {
  background: #666;
  color: #333;
  border: 1px solid #666;
}
.vue-slider-component {
  left: 10%;
}
.selectBox button {
  background: #444;
  border: 0;
  color: #fff;
  padding: 4px 10px;
  line-height: 22px;
  border-radius: 3px;
}
/* .selectBox button:hover{
        background:#209d3d;
    }
    .selectBox button:active{
        background:#5cb85c;
    }*/
.batterySpan {
  display: inline-block;
  width: 21%;
}
.selectBox {
  height: 32px;
}
.selectBox .dropdown-menu > li > a {
  color: #fff;
  display: inline-block;
  padding: 3px 15px 3px 10px;
  max-width: 95px;
}
#basicModes-ul {
  z-index: 1005;
}
#basicModes-ul li {
  position: relative;
}
.selectBox .dropdown-menu {
  min-width: 125px;
}
.selectBox .dropdown-menu > li {
  height: 26px;
  line-height: 26px;
}
.selectBox .dropdown-menu > li:hover {
  background-color: #455f45;
}
.bitrateSlider {
  position: relative;
  height: 42px;
}
.el-slider {
  float: left;
}
.bitrateSlider .el-slider {
  width: 75%;
}
.checkBox {
  margin-left: 5px;
}
.userBox {
  top: 50px;
  position: absolute;
  left: 102%;
  padding: 8px 10px;
  width: 250px;
  background: #252525;
  -webkit-box-shadow: 5px 5px 4px #333;
  box-shadow: 5px 5px 4px #333;
}
.userTest {
  height: 25px;
  line-height: 25px;
  margin-bottom: 10px;
}
.userTest span {
  float: left;
  display: inline-block;
  width: 24%;
}
.userTest input {
  float: left;
  width: 76%;
  height: 20px;
  margin-top: 3px;
  background: transparent;
  outline: none;
  border: 1px solid;
  text-indent: 3px;
}
.userBtn {
  text-align: center;
  margin-top: 5px;
  height: 35px;
  line-height: 35px;
}
.userBtn button:nth-child(1) {
  margin-right: 5px;
}
.userTest .userRadio {
  width: 18px;
  height: 18px;
}
.useCurrent {
  width: 45%;
}
.useCurrent input {
  width: 17px;
  height: 17px;
  margin-top: 5px;
  margin-right: 5px;
}
.useCurrent span {
  width: 72%;
  cursor: pointer;
}
</style>
