<template>
  <div class="container-fluid record-module">
    <div class="content-left f_left">
      <!-- 选择日期区域 -->
      <div class="content-header">
        <i
          class="el-icon-arrow-left f_left header-left"
          @click="setRecordDate(-setRecordDateDay)"></i>
        <ul class="dateUl">
          <li
            class="dateLi f_left"
            v-for="(item,index) in dateArray"
            :key="index"
            :class="{selectDate:selectDateColor[index]}"
            @click="chooseDate(item,index)">{{item.time}}
            <span
              class="clipNumberBox"
              :class="item|isHaveClip">{{item.clipNumber}}</span>
          </li>
        </ul>
        <i
          class="el-icon-arrow-right f_right header-right"
          @click="setRecordDate(setRecordDateDay)"></i>
      </div>
      <!-- 显示缩略图区域 -->
      <div class="content-body">
        <div class='downloadAll'>
          <div class="d-number f_left">
            <div
              class="d-n-text"
              :class="{opacity:needOpacity}">{{recordLeftObj.text}}</div>
            <button
              class='d-n-btn btnCommon'
              @click="downloadAll"
              :disabled="recordLeftObj.downloadAllEnable">{{$t('lang.downloadAll')}}</button>
          </div>
          <div class="d-clip f_right">
            <i
              class="el-icon-arrow-left f_left header-left"
              :class="{opacity:needOpacity}"
              @click="last"></i>
            <div class="thumnailList f_left">
              <ul class="dateUl">
                <li
                  v-for="(item,index) in selectDate"
                  :key="index"
                  :class="selectImgIndex|addSelect(index)"
                  @click="showClipThumnail(selectDateInfoList[index],index,'click',$event)">
                  <img
                    class="clipThumbnail"
                    :src="imgUrl(selectDateInfoList[index])"
                    :onerror="errorImg" />
                  <div class="clipStartTime">{{showTime(selectDateInfoList[index])}}</div>
                </li>
              </ul>
            </div>
            <i
              class="el-icon-arrow-right f_right header-right"
              :class="{opacity:needOpacity}"
              @click="next"></i>
          </div>
        </div>
        <!-- 文件开始结束时间 -->
        <div class="time-module">
          <div class="time-left f_left">
            <div
              class='clip-start'
              :disabled="recordLeftObj.startEnable">{{recordLeftObj.selectClipStartTime}}</div>
            <div
              class="clip-end"
              :disabled="recordLeftObj.endEnable">{{recordLeftObj.selectClipEndTime}}</div>
            <button
              class="btnCommon previewBtn"
              @click="previewOrStop($event)"
              :disabled="recordLeftObj.previewEnable || selectSourcePlatformType == 'TM1000v3'">{{$t(`lang.${recordLeftObj.previewText}`)}}</button>
            <button
              class="btnCommon downloadBtn"
              @click="download"
              :disabled="recordLeftObj.downloadEnable">{{$t('lang.Download')}}</button>
          </div>
          <!-- 左右图片区域 -->
          <div class="time-right f_left">
            <div class="sliderModule">
              <div class="slider-box"></div>
              <div
                id="hoverImgDiv"
                :class="{hide:showHoverImg}">
                <div style="width:100%;height:90px;background:#000;">
                  <div id="hoverImg">
                    <img
                      :src="hoverImg"
                      :onerror="errorImg">
                  </div>
                </div>
                <div class="sj"></div>
              </div>
            </div>
            <div class="imgArea">
              <ul
                class="dateUl"
                :class="{hide:showTwoImg}">
                <li class="f_left">
                  <img
                    class="clipThumbnail"
                    :src="selectClipInfo?selectClipInfo.startImgUrl : ''"
                    :onerror="errorImg" />
                  <div class="clipStartTime">{{recordLeftObj.selectClipStartTime}}</div>
                </li>
                <li class="f_right">
                  <img
                    class="clipThumbnail"
                    :src="selectClipInfo.endImgUrl"
                    :onerror="errorImg" />
                  <div class="clipStartTime">{{recordLeftObj.selectClipEndTime}}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右边文件区域 -->
    <div class="content-right f_right file-module">
      <div class="header">
        <button
          class="btnCommon fileDownload"
          :class="{gray:!showDownloadModule}"
          @click="selectModule('download')">{{$t('lang.Download')}}</button>
        <button
          class="btnCommon fileFtp"
          :class="{gray:showDownloadModule}"
          @click="selectModule('ftp')">FTP</button>
      </div>
      <div class="body">
        <ul class="file-tabel text-left">
          <li class="f_left w_10">{{$t('lang.state')}}</li>
          <li class="f_left w_48">{{$t('lang.name')}}</li>
          <li class="f_left w_17">{{$t('lang.size')}}</li>
          <li class="f_left w_25">{{$t('lang.compeleted')}}</li>
        </ul>
        <div class='fileModule'>
          <el-scrollbar style="height:100%">
            <div class="download-box">
              <ul>
                <li
                  v-for="(item,index) in fileList"
                  :key="index"
                  @click="saveFileInfo(item)"
                  :class="returnFileClass(item,'file')">
                  <div class="f_left w_10 clearfix"><img :src="returnImgUrl(item)"></div>
                  <div
                    class="f_left text_left w_48 text-indent ellipsis"
                    :title="item.Name">{{item.Name}}</div>
                  <div class="f_left  text_left w_17 text-indent">{{item.DisplaySize}}</div>
                  <div class="f_left w_25">
                    <span class="percentBox">
                      <span class="percentText">{{item.Percent + '%'}}</span>
                      <span
                        class="percentColor"
                        :style="returnWidth(item)"></span>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <!-- 文件区域按钮 -->
      <div class="foot text_left">
        <button
          class="w_20"
          @click="downloadFile"
          :class="{visibility:!showDownloadModule}"
          :disabled="fileBtnInfo.download">{{$t(`lang.${fileBtnInfo.downloadText}`)}}</button>
        <button
          class="w_10"
          @click="filePreviewOrStop($event)"
          :disabled="fileBtnInfo.play">{{$t(`lang.${fileBtnInfo.playText}`)}}</button>
        <button
          class="w_14"
          @click="deleteFile"
          :disabled="fileBtnInfo.delete">{{$t('lang.delete')}}</button>
        <button
          class="w_20"
          @click="deleteAll"
          :disabled="fileBtnInfo.deleteAll">{{$t('lang.deleteAll')}}</button>
        <button
          class="w_20"
          @click="getDownloadedList"
          :disabled="(this.$store.state.GetKernelStateObj.CurrentTStrIdHex && this.$store.state.GetKernelStateObj.CurrentTType == 2)?false:true"
          :class="{visibility:!showDownloadModule}">{{$t('lang.downList')}}</button>
        <span
          @click="showExport"
          class="exportBtn"
          :class="{hide:!showDownloadModule}"></span>
        <div
          class="exportBox"
          :class="{hide:exportInfo.isShow}">
          <div class='export-header'>
            <div class="selectionAs f_left">
              <p>{{$t('lang.exportSelection')}}:</p>
              <div class="btn-group f_left">
                <button
                  type="button"
                  class="btn dropdown-toggle exportContent"
                  data-toggle="dropdown"
                  aria-expanded="false">{{exportInfo.selectType}}
                  <span class="caret"></span>
                </button>
                <ul
                  class="dropdown-menu"
                  role="menu"
                  id="export-ul">
                  <li @click="setExportType('TS')">
                    <a href="#">TS</a>
                  </li>
                  <li @click="setExportType('QuickTime')">
                    <a href="#">QuickTime</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="saveAs f_left">
              <p>{{$t('lang.saveAs')}}:</p>
              <input
                class="saveInput"
                type="text"
                v-model="exportInfo.saveAs">
            </div>
            <div
              class="collapseBtn f_right"
              @click="hideExport"></div>
          </div>
          <div class='export-body'>
            <button
              @click="exportFile"
              class="sendExport"
              :disabled="!showDownloadModule">{{$t('lang.export')}}</button>
          </div>
          <div class='export-foot hide'>
            <input type="checkbox">
            <p class="f_left">Convert .ts to .mov(for Progressive Download only)</p>
          </div>
        </div>
      </div>
    </div>
    <!-- downloadList弹出框 -->
    <div
      id="downloadList"
      class="downloadList"
      :class="{hide:showFileDownloadList}">
      <div class="downloadList-header">
        {{$t('lang.downloadedFiles')}}
        <i
          class="iconfont configT-icon password-icon f_right"
          @click="closePage('download')"></i>
      </div>
      <div class="downloadListBox">
        <ul class="file-tabel text-left">
          <li class="f_left w_25">{{$t('lang.FileName')}}</li>
          <li class="f_left w_25">{{$t('lang.startTime')}}(UTC)</li>
          <li class="f_left w_25">{{$t('lang.endTime')}}(UTC)</li>
          <li class="f_left w_25">{{$t('lang.compeletedTime')}}(UTC)</li>
        </ul>
        <el-scrollbar style="height:100%">
          <div class='fileModule'>
            <div class="download-box">
              <ul>
                <li
                  v-for="(item,index) in downloadedList"
                  :key="index"
                  @click="saveDownloadedInfo(item)"
                  :class="returnFileClass(item,'downloadList')">
                  <div class="f_left w_25 text_left text-indent">{{item.FileName}}</div>
                  <div class="f_left text_left w_25 text-indent">{{changeTime(item.StartTime)}}</div>
                  <div class="f_left text_left w_25 text-indent">{{changeTime(item.EndTime)}}</div>
                  <div class="f_left w_25 text_left text-indent">{{changeTime(item.FinishedAt)}}</div>
                </li>
              </ul>
            </div>
          </div>
        </el-scrollbar>
        <div class="text_center">
          <button
            @click="deleteDownloadFile"
            :disabled="popDownloadInfo.delete"
            class="deleteBtn btnCommon">{{$t('lang.delete')}}</button>
          <button
            @click="deleteDownloadAllFile"
            :disabled="popDownloadInfo.deleteAll"
            class="deleteAllBtn btnCommon">{{$t('lang.deleteAll')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import Tool from '@/assets/js/utils.js'
import Bus from '@/assets/js/vueBus.js'
import Vue from 'vue'
import { mapState } from 'vuex'
import { Receiver, receviceParams } from '@/assets/api/common/receiver.js'
import { adAnalytice } from '@/assets/js/googleAnalyze/advanceControl'
export default {
  name: 'record',
  data () {
    return {
      showTwoImg: true,
      selectTime: '',
      oneDay: 1000 * 60 * 60 * 24,
      startTime: '',
      endTime: '',
      dateArray: [],
      timerInfo: { record: '', download: '', ftp: '' },
      selectDate: [],
      selectDateInfoList: [],
      selectImgIndex: 0,
      selectDateColor: [false, false, false, false, false, false, false],
      recordLeftObj: {
        text: '0/0',
        downloadAllEnable: true,
        selectClipStartTime: '',
        startEnable: true,
        selectClipEndTime: '',
        endEnable: true,
        previewEnable: true,
        previewText: 'Preview',
        downloadEnable: true,
        selectInitStartTime: '',
        selectInitEndTime: ''
      },
      selectClipInfo: {
        startImgUrl: '',
        endImgUrl: '',
        startTime: '',
        endTime: ''
      },
      fileList: [],
      showDownloadModule: true,
      selectDateClipNumber: 0,
      scrollNumber: 0,
      errorImg: 'this.src="' + require('@/assets/img/logot.png') + '"',
      fileBtnInfo: {
        download: true,
        downloadText: 'Download',
        play: true,
        playText: 'Play',
        delete: true,
        deleteAll: true
      },
      selectFileInfo: {},
      showFileDownloadList: true,
      downloadedList: [],
      selectDownloadedInfo: {},
      exportInfo: {
        isShow: true,
        saveAs: '',
        selectType: 'TS'
      },
      selectClipTimeInfo: {
        start: 0,
        end: 0
      },
      hoverImg: require('@/assets/img/logot.png'),
      showHoverImg: true,
      popDownloadInfo: {
        delete: true,
        deleteAll: true,
        downloadTimer: null
      },
      lastLiveTID: '',
      isPreview: false,
      isFilePreview: false,
      isHaveSelectFile: false,
      isHaveSelectClip: false,
      getDateClipTimer: null,
      isFirstInit: true,
      alertInfo: {
        downloadList: true
      },
      needOpacity: true,
      setRecordDateDay: 7,
      setSelectDateClipNumber: 5,
      blobUrl: ''
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      selectSourcePlatformType () {
        return this.$store.state.selectSourcePlatformType
      }
    })
  },
  async created () {
    this.blobUrl = this.$store.state.common.baseUrl.blobUrl
    this.createClipDate()
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    Bus.$on('showModule', async (key) => {
      if (key == 2) {
        this.getRecordOperation()
        this.State.websocket.pageRWebsocket.send({
          message: { categoryId: '2152866560', operationType: '101', rid: this.State.selectRId },
          time: 1000,
          key: 'getDownloadList',
          success: res => {
            let data = res.data ? JSON.parse(res.data) : {}
            this.getDownloadList('', data)
          }
        })
        if (this.timerInfo.record) clearInterval(this.timerInfo.record)
        this.timerInfo.record = setInterval(() => {
          this.getRecordOperation()
        }, 1000)
      } else {
        this.defaultSet()
        if (this.timerInfo.record) clearInterval(this.timerInfo.record)
        if (this.State.websocket.pageRWebsocket.socket) {
          this.State.websocket.pageRWebsocket.stop('getDownloadList')
          this.State.websocket.pageRWebsocket.stop('getFTPList')
        }
      }
    })

    Bus.$on('playVideo', function (el, obj) {
      if (obj) {
        if (obj.status === 0) {
          this.defaultSet()
        }
      }
    }.bind(this))
  },
  methods: {
    thumbNailDisplay (item) {
      const url = `${window.location.protocol}//${this.blobUrl}/${this.State.GetKernelStateObj.CurrentTStrIdHex.toUpperCase()}/${Math.round(item / 1000)}?tl=20&direct=1`
      return url
    },
    createClipDate (ts, num) {
      const oneDay = 1000 * 60 * 60 * 24
      var _endTime = new Date().getTime()
      this.endTime = this.dateToStr(_endTime, 'e')
      var _startTime = this.endTime - oneDay * (this.setRecordDateDay - 1)
      this.startTime = this.dateToStr(_startTime, 's')
      var dateArray = []
      for (var i = 0; i < this.setRecordDateDay; i++) {
        var dateVal = Tool.changeDate(this.startTime, i)
        var obj = {}
        obj.time = dateVal
        obj.clipNumber = 0
        obj.clipList = []
        dateArray.push(obj)
      }
      this.dateArray = dateArray
    },
    dateToStr (ts, index) {
      var dateVal = Tool.changeDate(ts, 0)
      var newDate = ''
      if (index == 'e') {
        newDate = dateVal + ' 23:59:59'
      } else {
        newDate = dateVal + ' 00:00:00'
      }
      var returnVal = new Date(newDate).getTime()
      return returnVal
    },
    handleResize () {
      if (window.innerWidth > 1919) {
        this.setRecordDateDay = 7
        this.createClipDate()
        this.setSelectDateClipNumber = 5
      }

      if (window.innerWidth >= 1600 && window.innerWidth < 1919) {
        this.setRecordDateDay = 4
        this.createClipDate()
        this.setSelectDateClipNumber = 4
      }

      if (window.innerWidth >= 500 && window.innerWidth < 760) {
        this.setRecordDateDay = 4
        this.createClipDate()
        this.setSelectDateClipNumber = 3
      }

      if (window.innerWidth >= 760 && window.innerWidth < 1280) {
        this.setRecordDateDay = 4
        this.createClipDate()
        this.setSelectDateClipNumber = 3
      }
      if (window.innerWidth >= 1280 && window.innerWidth < 1600) {
        this.setRecordDateDay = 6
        this.createClipDate()
        this.setSelectDateClipNumber = 4
      }
    },
    setRecordDate (day) {
      // 埋点统计
      if (day == this.setRecordDateDay) {
        adAnalytice('adRecordNextWeek')
      } else {
        adAnalytice('adRecordLastWeek')
      }

      const nowDateTime = new Date().getTime()
      let nowDate = Tool.changeDate(nowDateTime, 0)
      let endDate = Tool.changeDate(this.endTime, 0)
      if (day === this.setRecordDateDay && (nowDate == endDate)) {

      } else {
        this.defaultSet()
        this.startTime = this.startTime + this.oneDay * day - 0
        this.endTime = this.endTime + this.oneDay * day - 0
        var dateArray = []
        for (var i = 0; i < this.setRecordDateDay; i++) {
          var dateVal = Tool.changeDate(this.startTime, i)
          var obj = {}
          obj.time = dateVal
          obj.clipNumber = 0
          obj.clipList = []
          dateArray.push(obj)
        }
        this.dateArray = dateArray
      }
    },
    // 获取clip数量及图片的接口
    getRecordOperation () {
      let data = {
        rid: this.State.selectRId,
        data: '{"StartTimeStamp":' + this.startTime + ',"EndTimeStamp":' + this.endTime + '}'
      }
      let params = Object.assign(data, receviceParams.Record.getRecordInfo)
      this.axios.post(Receiver().Record.getRecordInfo, params)
        .then(res => {
          const result = JSON.parse(res.data.result)
          if (res.data.errorInfo == 'Success' && (result.errorCode && result.errorCode == '0x0' || !result.errorCode)) {
            // 把之前的数据全部清空
            $.each(this.dateArray, function (index, item) {
              item.clipNumber = 0
              item.clipList = []
            })
            // var result = jQuery.parseJSON(res.data.result)
            var clipList = Tool.CompareTime(result.Items)
            if (clipList) {
              $.each(clipList, function (idx, item) {
                var time = Tool.changeDate(item.StartTimeStamp, 0)
                for (var i = 0; i < this.setRecordDateDay; i++) {
                  if (time == this.dateArray[i].time) {
                    this.dateArray[i].clipList.push(item)
                    this.dateArray[i].clipNumber++
                  }
                }
              }.bind(this))
            }
            if (this.$store.state.GetKernelStateObj.WorkMode == 1 && this.$store.state.GetKernelStateObj.CurrentTStrIdHex != this.lastLiveTID) {
              this.lastLiveTID = this.$store.state.GetKernelStateObj.CurrentTStrIdHex
              this.defaultSet()
            }
          }
        }).catch(() => {})
    },
    imgUrl (item) {
      const url = this.thumbNailDisplay(item.StartTimeStamp)
      return url
    },
    chooseDate (item, index) {
      // 埋点统计
      adAnalytice('adRecordDay')
      // 用来保存所选择得日期时间,要与下面定义选择clip的时间去分开来.
      this.selectTime = item.time
      this.selectDateColor = [false, false, false, false, false, false, false]
      Vue.set(this.selectDateColor, index, true)
      if (item.clipList.length <= 0) {
        this.defaultSet(index)
        this.needOpacity = true
      } else {
        if (this.getDateClipTimer) clearInterval(this.getDateClipTimer)
        this.needOpacity = false
        this.selectDate = item.clipList
        // 这个用来更新缩略图的,不用selectDate是防止每次都会重新创建结构而导致缩略图会一闪一闪的
        this.selectDateInfoList = item.clipList
        this.recordLeftObj.text = '1/' + item.clipNumber
        this.selectDateClipNumber = item.clipNumber
        this.selectClipTimeInfo.start = item.clipList[0].StartTimeStamp
        this.selectClipTimeInfo.end = item.clipList[0].EndTimeStamp
        this.$store.state.selectClipStartTime = item.clipList[0].StartTimeStamp
        this.$store.state.selectClipEndTime = item.clipList[0].EndTimeStamp
        this.recordLeftObj.selectInitStartTime = item.clipList[0].StartTimeStamp
        this.recordLeftObj.selectInitEndTime = item.clipList[0].EndTimeStamp
        this.showClipThumnail(item.clipList[0], 0)
        // 循环更新里面的值
        this.getDateClipTimer = setInterval(function () {
          if (this.dateArray[index].clipNumber != this.selectDate.length) {
            this.selectDate = item.clipList
          }
          this.selectDateInfoList = item.clipList
          // 没有选择其他clip的话更新默认值就可以
          if (!this.isHaveSelectClip) {
            this.selectClipInfo = item.clipList[0]
          } else {
            this.selectClipInfo = item.clipList[this.selectImgIndex]
          }
          this.selectDateClipNumber = item.clipNumber
          this.selectClipTimeInfo.start = item.clipList[this.selectImgIndex].StartTimeStamp && (this.selectClipTimeInfo.start < item.clipList[this.selectImgIndex].StartTimeStamp) ? item.clipList[this.selectImgIndex].StartTimeStamp : this.selectClipTimeInfo.start
          this.selectClipTimeInfo.end = (this.selectClipTimeInfo.end < item.clipList[this.selectImgIndex].EndTimeStamp) ? this.selectClipTimeInfo.end : item.clipList[this.selectImgIndex].EndTimeStamp
          var number = this.isHaveSelectClip ? this.selectImgIndex : 0
          this.showClipThumnail(this.selectClipInfo, number)
        }.bind(this), 2000)
      }
    },
    showTime (item) {
      return Tool.changeNewTime(item.StartTimeStamp)
    },
    showClipThumnail (item, index, isClick, event) {
      if (isClick && event.currentTarget.className.indexOf('select') >= 0) return
      if (isClick) {
        this.isHaveSelectClip = true
        this.recordLeftObj.selectInitStartTime = item.StartTimeStamp
        this.recordLeftObj.selectInitEndTime = item.EndTimeStamp
      }
      this.recordLeftObj.text = (index + 1) + '/' + this.selectDateClipNumber
      this.selectImgIndex = index
      this.selectClipInfo = JSON.parse(JSON.stringify(item))
      this.showTwoImg = false
      this.recordLeftObj.downloadAllEnable = false
      this.recordLeftObj.startEnable = false
      this.recordLeftObj.endEnable = false
      const isLive = $('#videoBtn').html()
      if (isLive != 'Stop' && isLive != '停止') {
        this.recordLeftObj.previewEnable = false
      }
      this.recordLeftObj.downloadEnable = false
      if (this.$store.state.GetKernelStateObj.IsPreview) {
        this.recordLeftObj.previewText = 'Stop'
        this.$store.state.isPreviewClip = true
      } else {
        this.recordLeftObj.previewText = 'Preview'
        this.$store.state.isPreviewClip = false
      }

      if (!isClick) {
        this.selectClipTimeInfo.start = (this.selectClipTimeInfo.start < item.StartTimeStamp) ? item.StartTimeStamp : this.selectClipTimeInfo.start
        this.selectClipTimeInfo.end = (this.selectClipTimeInfo.end < item.EndTimeStamp) ? this.selectClipTimeInfo.end : item.EndTimeStamp
        // 如果没有拖动则更新结束时间
        if (this.recordLeftObj.selectInitEndTime < item.EndTimeStamp && this.selectClipTimeInfo.end >= this.recordLeftObj.selectInitEndTime) {
          this.selectClipTimeInfo.end = item.EndTimeStamp
        }
      } else {
        this.selectClipTimeInfo.start = item.StartTimeStamp
        this.selectClipTimeInfo.end = item.EndTimeStamp
      }

      if (!isClick) {
        // 如果当前所选中的clip初始时间发生变化进行更新
        if (this.recordLeftObj.selectInitStartTime != item.StartTimeStamp || this.recordLeftObj.selectInitEndTime != item.EndTimeStamp) {
          // 如果当前所选中的时间和初始时间不同就不在更新左侧的时间
          if (Tool.changeNewTime(this.recordLeftObj.selectInitStartTime) == this.recordLeftObj.selectClipStartTime) {
            this.recordLeftObj.selectClipStartTime = Tool.changeNewTime(item.StartTimeStamp)
          }

          if (Tool.changeNewTime(this.recordLeftObj.selectInitEndTime) == this.recordLeftObj.selectClipEndTime) {
            this.recordLeftObj.selectClipEndTime = Tool.changeNewTime(item.EndTimeStamp)
          }
          // 更新初始时间
          this.recordLeftObj.selectInitStartTime = item.StartTimeStamp
          this.recordLeftObj.selectInitEndTime = item.EndTimeStamp
        } else {
          this.recordLeftObj.selectClipStartTime = this.recordLeftObj.selectClipStartTime < item.StartTimeStamp ? Tool.changeNewTime(item.StartTimeStamp) : this.recordLeftObj.selectClipStartTime
          this.recordLeftObj.selectClipEndTime = this.recordLeftObj.selectClipEndTime < item.EndTimeStamp ? Tool.changeNewTime(item.EndTimeStamp) : this.recordLeftObj.selectClipEndTime
        }
      } else {
        this.recordLeftObj.selectClipStartTime = Tool.changeNewTime(item.StartTimeStamp)
        this.recordLeftObj.selectClipEndTime = Tool.changeNewTime(item.EndTimeStamp)
      }
      this.selectClipInfo.startImgUrl = this.thumbNailDisplay(this.selectClipTimeInfo.start)
      this.selectClipInfo.endImgUrl = this.thumbNailDisplay(this.selectClipTimeInfo.end)
      this.$store.state.selectClipStartTime = item.StartTimeStamp
      this.$store.state.selectClipEndTime = item.EndTimeStamp
      this.drawSlider(item.StartTimeStamp, item.EndTimeStamp)
    },
    drawSlider (start, end) {
      var that = this
      $('.slider-box').slider({
        range: true,
        min: 0,
        max: end - start,
        values: [parseInt(that.selectClipTimeInfo.start - start), parseInt((end - start) - (end - that.selectClipTimeInfo.end))],
        slide: function (event, ui) {
          var startTime = Tool.changeNewTime(parseInt(start) + parseInt(ui.values[0]))
          var stopTime = Tool.changeNewTime(parseInt(start) + parseInt(ui.values[1]))

          that.selectClipTimeInfo.start = parseInt(start) + parseInt(ui.values[0])
          that.selectClipTimeInfo.end = parseInt(start) + parseInt(ui.values[1])

          that.recordLeftObj.selectClipStartTime = startTime
          that.recordLeftObj.selectClipEndTime = stopTime

          that.selectClipInfo.startTime = startTime
          that.selectClipInfo.endTime = stopTime

          that.selectClipInfo.startImgUrl = that.thumbNailDisplay(that.selectClipTimeInfo.start)
          that.selectClipInfo.endImgUrl = that.thumbNailDisplay(that.selectClipTimeInfo.end)
        }
      })
      $('.slider-box').slider().mousemove(function (e) {
        var width = $(this).width() - (-2)
        var offset = $(this).offset()
        var position = e.clientX - offset.left
        if (position < 0 || position > width) { return };
        position = (position < 4) ? 0 : position
        var positionDataVal = Math.round(position * (end - start) / width) - (-start)
        that.showHover(position, positionDataVal)
      }).mouseout(function () {
        that.showHoverImg = true
      })
    },
    // 展示鼠标放到拖拽条时候的图片
    showHover (leftPosition, dateStream) {
      this.hoverImg = this.thumbNailDisplay(dateStream)
      if (this.selectClipInfo && this.selectClipInfo.ID) {
        this.showHoverImg = false
      }
      var w = $('#hoverImgDiv').width()
      $('#hoverImgDiv').css('left', leftPosition - w / 2)
    },
    // 获取FTPlist
    getFTP (playVideo, data) {
      this.fileList = data.FileCollection
      if (this.fileList.length > 0) {
        this.fileBtnInfo.delete = false
        this.fileBtnInfo.deleteAll = false
        var isPlay = false
        for (var i = 0; i < this.fileList.length; i++) {
          var isPlaying = this.fileList[i].Playing
          var name = this.fileList[i].Name
          // 第一次进来检查是否之前在play视频
          if (playVideo && isPlaying) {
            this.selectFileInfo = this.fileList[i]
          }
          if (isPlaying) isPlay = true
          if (name == this.selectFileInfo.Name) {
            this.selectFileInfo = this.fileList[i]
          }
        }
      } else {
        this.fileBtnInfo.delete = true
        this.fileBtnInfo.deleteAll = true
        this.selectFileInfo = {}
      }
      this.showBtnStatus(this.selectFileInfo, isPlay, 'FTP')
    },
    // 获取download list
    getDownloadList (playVideo, data) {
      this.fileList = data.FileCollection
      if (this.fileList.length > 0) {
        this.fileBtnInfo.delete = false
        this.fileBtnInfo.deleteAll = false
        var isPlay = false
        for (var i = 0; i < this.fileList.length; i++) {
          var isPlaying = this.fileList[i].Playing
          var name = this.fileList[i].Name
          // 第一次进来检查是否之前在play视频
          if (playVideo && isPlaying) {
            this.selectFileInfo = this.fileList[i]
          }
          if (isPlaying) isPlay = true
          if (name == this.selectFileInfo.Name) {
            this.selectFileInfo = this.fileList[i]
          }
        }
      } else {
        this.fileBtnInfo.delete = true
        this.fileBtnInfo.deleteAll = true
        this.fileBtnInfo.downloadText = 'Download'
        this.fileBtnInfo.download = true
      }
      this.showBtnStatus(this.selectFileInfo, isPlay, 'Download')
    },
    returnImgUrl (item) {
      var _imgUrl = ''
      var fileState = item.State
      switch (fileState) {
        case 0: _imgUrl = require('@/assets/img/downloading.png'); break
        case 1: _imgUrl = require('@/assets/img/paused.png'); break
        case 2: _imgUrl = require('@/assets/img/task.png'); break
        case 3: _imgUrl = require('@/assets/img/downloaded.png'); break
        case 4: _imgUrl = require('@/assets/img/transcoding.png'); break
        case 5: _imgUrl = require('@/assets/img/exclam_ico.png'); break
        case 6: _imgUrl = require('@/assets/img/Record_folder.png'); break
        case 7: _imgUrl = require('@/assets/img/autosync_ico.png'); break
        default: ;
      }
      return _imgUrl
    },
    returnWidth (item) {
      const percent = item.Percent
      var obj = { width: percent + '%' }
      return obj
    },
    selectModule (key) {
      this.selectFileInfo = {}
      if (this.State.websocket.pageRWebsocket.socket) {
        this.State.websocket.pageRWebsocket.stop('getDownloadList')
        this.State.websocket.pageRWebsocket.stop('getFTPList')
      }
      if (key === 'download') {
        // 埋点统计
        adAnalytice('adRecordDownloadModule')

        if (this.timerInfo.ftp) clearInterval(this.timerInfo.ftp)
        this.isHaveSelectFile = false
        this.showDownloadModule = true
        this.State.websocket.pageRWebsocket.send({
          message: { categoryId: '2152866560', operationType: '101', rid: this.State.selectRId },
          time: 1000,
          key: 'getDownloadList',
          success: res => {
            let data = res.data ? JSON.parse(res.data) : {}
            this.getDownloadList('', data)
          }
        })
      } else {
        // 埋点统计
        adAnalytice('adRecordFTPModule')

        this.isHaveSelectFile = false
        this.showDownloadModule = false
        this.exportInfo.isShow = true
        this.State.websocket.pageRWebsocket.send({
          message: { categoryId: '2152866560', operationType: '102', rid: this.State.selectRId },
          time: 1000,
          key: 'getFTPList',
          success: res => {
            let data = res.data ? JSON.parse(res.data) : {}
            this.getFTP('', data)
          }
        })
      }
    },
    defaultSet (index) {
      this.showTwoImg = true
      this.selectDate = []
      this.recordLeftObj.text = '0/0'
      this.recordLeftObj.downloadAllEnable = true
      this.recordLeftObj.startEnable = true
      this.recordLeftObj.endEnable = true
      this.recordLeftObj.previewEnable = true
      this.recordLeftObj.downloadEnable = true
      this.recordLeftObj.selectClipStartTime = ''
      this.recordLeftObj.selectClipEndTime = ''
      this.selectClipInfo = {}
      this.selectClipInfo.startImgUrl = ''
      this.selectClipInfo.endImgUrl = ''
      this.selectClipInfo.startTime = ''
      this.selectClipInfo.endTime = ''
      this.$store.state.selectClipStartTime = 0
      this.$store.state.selectClipEndTime = 0
      this.selectDateColor = [false, false, false, false, false, false, false]
      if (index || index == 0) {
        Vue.set(this.selectDateColor, index, true)
      }
      if (this.getDateClipTimer) clearInterval(this.getDateClipTimer)
      this.hoverImg = require('@/assets/img/logot.png')
      $('.slider-box').slider({
        range: true,
        min: 0,
        max: 100,
        values: [0, 100],
        slide: function (event, ui) {}
      })
    },
    last () {
      if (this.selectDateClipNumber <= this.setSelectDateClipNumber) return
      if (this.scrollNumber > -1) {
        this.scrollNumber = 0
      } else {
        this.scrollNumber = this.scrollNumber + this.setSelectDateClipNumber
      }
      var liWidth = (document.getElementsByClassName('thumnailList')[0].clientWidth - 50) / this.setSelectDateClipNumber
      $('.thumnailList ul').css('left', ((this.scrollNumber / this.setSelectDateClipNumber) * (liWidth + 10) * this.setSelectDateClipNumber))
    },
    next () {
      if (this.selectDateClipNumber <= this.setSelectDateClipNumber) return
      if (this.scrollNumber > this.setSelectDateClipNumber - this.selectDateClipNumber) {
        this.scrollNumber = this.scrollNumber - this.setSelectDateClipNumber
      }
      var liWidth = (document.getElementsByClassName('thumnailList')[0].clientWidth - 50) / this.setSelectDateClipNumber
      $('.thumnailList ul').css('left', this.setSelectDateClipNumber * (liWidth + 10) * (this.scrollNumber / this.setSelectDateClipNumber))
    },
    downloadAll () {
      // 埋点统计
      adAnalytice('adRecordDownloadAll')
      const selectDateStart = this.selectTime + ' 00:00:00'
      const selectDateEnd = this.selectTime + ' 24:00:00'
      const selectDateStartStream = new Date(selectDateStart).getTime()
      const selectDateEndStream = new Date(selectDateEnd).getTime()

      let data = {
        rid: this.State.selectRId,
        data: '{"StartTimeStamp":' + selectDateStartStream + ',"EndTimeStamp":' + selectDateEndStream + '}'
      }
      let params = Object.assign(data, receviceParams.Record.downloadAll)
      this.axios.post(Receiver().Record.downloadAll, params).then(res => {
        const result = JSON.parse(res.data.result)
        if (res.data.errorCode == '0x0' && (result.errorCode && result.errorCode == '0x0' || !result.errorCode)) {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
        } else {
          this.$message({
            type: 'error',
            message: result.errorInfo
          })
        }
      }).catch(() => {})
    },
    download () {
      adAnalytice('adRecordDownload')
      let data = {
        rid: this.State.selectRId,
        data: '{"ID":' + this.selectClipInfo.ID + ',"StartTimeStamp":' + this.selectClipTimeInfo.start + ',"EndTimeStamp":' + this.selectClipTimeInfo.end + '}'
      }
      let params = Object.assign(data, receviceParams.Record.download)
      this.axios.post(Receiver().Record.download, params).then(res => {
        const result = JSON.parse(res.data.result)
        if (res.data.errorCode == '0x0' && (result.errorCode && result.errorCode == '0x0' || !result.errorCode)) {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
        } else {
          this.$message({
            type: 'error',
            message: result.errorInfo
          })
        }
      }).catch(() => {})
    },
    // 预览所选择的文件片段
    previewOrStop (event) {
      var currentText = event.currentTarget.innerText

      if (currentText === 'Preview' || currentText === '预览') {
        adAnalytice('adRecordPreview')
        let data = {
          rid: this.State.selectRId,
          data: '{"ID":' + this.selectClipInfo.ID + ',"StartTimeStamp":' + this.selectClipTimeInfo.start + ',"EndTimeStamp":' + this.selectClipTimeInfo.end + '}'
        }
        let params = Object.assign(data, receviceParams.Record.previewClip)
        this.axios.post(Receiver().Record.previewClip, params).then(res => {
          const result = JSON.parse(res.data.result)
          if (res.data.errorCode == '0x0' && (result.errorCode && result.errorCode == '0x0' || !result.errorCode)) {
            this.$message({
              type: 'success',
              message: this.$t('lang.success')
            })
            this.recordLeftObj.previewText = 'Stop'
            this.$store.state.isPreviewClip = true
            var array = []
            array.push(this.$store.state.GetKernelStateObj.WebRM3U8PlaybackUrl)
            $('#currentTime').hide()
          } else {
            this.$message({
              type: 'error',
              message: result.errorInfo
            })
          }
        }).catch(() => {})
      } else {
        this.stopPreview()
      }
    },
    // 停掉预览
    stopPreview () {
      let params = Object.assign({ rid: this.State.selectRId }, receviceParams.Record.stopPreviewClip)
      this.axios.post(Receiver().Record.stopPreviewClip, params).then(res => {
        const result = JSON.parse(res.data.result)
        if (res.data.errorCode == '0x0' && (result.errorCode && result.errorCode == '0x0' || !result.errorCode)) {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
          this.recordLeftObj.previewText = 'Preview'
          this.$store.state.isPreviewClip = false
          if (this.isPreview) {
            this.recordLeftObj.previewText = 'Preview'
            this.recordLeftObj.previewEnable = true
          }
        } else {
          this.$message({
            type: 'error',
            message: result.errorInfo
          })
        }
      }).catch(() => {})
    },
    // 预览file模块里面的视频文件
    filePreviewOrStop (event) {
      var currentText = event.currentTarget.innerText
      if (currentText === 'Play') {
        // 值为true的时候说明当前是在download模块
        if (this.showDownloadModule) {
          this.previewDownload()
          // 埋点统计、
          adAnalytice('adRecordDownloadPlay')
        } else {
          this.previewFtp()
          // 埋点统计、
          adAnalytice('adRecordFTPPlay')
        }
      } else {
        if (this.showDownloadModule) {
          this.stopDownloadPreview()
        } else {
          this.stopFtpPreview()
        }
      }
    },
    previewDownload () {
      let data = {
        rid: this.State.selectRId,
        data: '{"SelectedFileName":"' + this.selectFileInfo.Name + '"}'
      }
      let params = Object.assign(data, receviceParams.Record.previewDownloadClip)
      this.axios.post(Receiver().Record.previewDownloadClip, params).then(res => {
        const result = JSON.parse(res.data.result)
        if (res.data.errorCode == '0x0' && (result.errorCode && result.errorCode == '0x0' || !result.errorCode)) {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
          this.fileBtnInfo.playText = 'Stop'
          this.$store.state.isFilePreview = true
          var array = []
          array.push(this.$store.state.GetKernelStateObj.WebRM3U8PlaybackUrl)
          $('#currentTime').hide()
        } else {
          this.$message({
            type: 'error',
            message: result.errorInfo
          })
        }
      }).catch(() => {})
    },
    previewFtp () {
      let data = {
        rid: this.State.selectRId,
        data: '{"SelectedFileName":"' + this.selectFileInfo.Name + '"}'
      }
      let params = Object.assign(data, receviceParams.Record.previewFTP)
      this.axios.post(Receiver().Record.previewFTP, params).then(res => {
        const result = JSON.parse(res.data.result)
        if (res.data.errorCode == '0x0' && (result.errorCode && result.errorCode == '0x0' || !result.errorCode)) {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
          this.fileBtnInfo.playText = 'Stop'
          this.$store.state.isFilePreview = true
          var array = []
          array.push(this.$store.state.GetKernelStateObj.WebRM3U8PlaybackUrl)
          $('#currentTime').hide()
        } else {
          this.$message({
            type: 'error',
            message: result.errorInfo
          })
        }
      }).catch(() => {})
    },
    stopDownloadPreview () {
      let data = {
        rid: this.State.selectRId,
        data: '{"SelectedFileName":"' + this.selectFileInfo.Name + '"}'
      }
      let params = Object.assign(data, receviceParams.Record.stopDownloadPreview)
      this.axios.post(Receiver().Record.stopDownloadPreview, params).then(res => {
        const result = JSON.parse(res.data.result)
        if (res.data.errorCode == '0x0' && (result.errorCode && result.errorCode == '0x0' || !result.errorCode)) {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
          this.fileBtnInfo.playText = 'Play'
          this.$store.state.isFilePreview = false
          if (this.isFilePreview) {
            this.selectFileInfo = {}
          }
        } else {
          this.$message({
            type: 'error',
            message: result.errorInfo
          })
        }
      }).catch(() => {})
    },
    stopFtpPreview () {
      let data = {
        rid: this.State.selectRId,
        data: '{"SelectedFileName":"' + this.selectFileInfo.Name + '"}'
      }
      let params = Object.assign(data, receviceParams.Record.stopFtpPreview)
      this.axios.post(Receiver().Record.stopFtpPreview, params).then(res => {
        const result = JSON.parse(res.data.result)
        if (res.data.errorCode == '0x0' && (result.errorCode && result.errorCode == '0x0' || !result.errorCode)) {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
          this.fileBtnInfo.playText = 'Play'
          this.$store.state.isFilePreview = false
        } else {
          this.$message({
            type: 'error',
            message: result.errorInfo
          })
        }
      }).catch(() => {})
    },
    // 用来保存所选择的的文件信息
    saveFileInfo (item) {
      this.selectFileInfo = item
      this.isHaveSelectFile = true
    },
    // 用来给在file模块选择的当前行加一个背景色
    returnFileClass (item, key) {
      var _str = ''
      if (key === 'downloadList') {
        const thisID = item.FileName
        if (thisID == this.selectDownloadedInfo.FileName) {
          _str = 'select'
        }
      } else {
        const thisID = item.Name
        if (thisID == this.selectFileInfo.Name) {
          _str = 'select'
        }
      }
      return _str
    },
    // 根据选择的file来显示按钮的状态
    showBtnStatus (item, isPlay, key) {
      const isLive = $('#videoBtn').html()
      if (isLive == 'Stop' || isLive == '停止') {
        this.fileBtnInfo.download = true
        this.fileBtnInfo.play = true
        this.fileBtnInfo.downlaodText = 'Download'
        this.fileBtnInfo.playText = 'Play'
        this.$store.state.isFilePreview = false
        this.$store.state.isPreviewClip = false
        this.recordLeftObj.previewEnable = true
      } else {
        // 有名字代表有选行
        if (this.selectFileInfo.Name) {
          const state = item.State
          if (state == 0 || state == 4) {
            this.fileBtnInfo.downloadText = 'Stop'
            this.fileBtnInfo.download = false
          } else if (state == 3) {
            this.fileBtnInfo.downloadText = 'Download'
            this.fileBtnInfo.download = true
          } else {
            this.fileBtnInfo.downloadText = 'Download'
            this.fileBtnInfo.download = false
          }
          // 判断是否播放
          const play = item.Playing
          if (play) {
            if (this.showDownloadModule && isPlay && key === 'Download') {
              this.fileBtnInfo.playText = 'Stop'
            }
            if (!this.showDownloadModule && isPlay && key === 'FTP') {
              this.fileBtnInfo.playText = 'Stop'
            }

            if (!this.isFilePreview) {
              var array = []
              array.push(this.$store.state.GetKernelStateObj.WebRM3U8PlaybackUrl)
              $('#currentTime').hide()
              this.isFilePreview = true
              this.$store.state.isFilePreview = true
            }
          } else {
            this.fileBtnInfo.playText = 'Play'
            this.fileBtnInfo.play = true
          }
          // play按钮是否可以点击
          const fileSize = item.Size
          if (fileSize > 0) {
            this.fileBtnInfo.play = false
          } else {
            this.fileBtnInfo.play = true
          }
        } else {
          this.fileBtnInfo.download = true
          this.fileBtnInfo.play = true
          this.fileBtnInfo.delete = true
          this.fileBtnInfo.downloadText = 'Download'
          this.fileBtnInfo.playText = 'Play'
        }
      }
    },
    // 下载或停止下载
    downloadFile () {
      const downloadText = this.fileBtnInfo.downloadText
      if (downloadText === 'Download') {
        // 埋点统计
        adAnalytice('adRecordFileDownload')
        this.startFileDownload()
      } else {
        this.stopFileDownload()
      }
    },
    startFileDownload () {
      let data = {
        rid: this.State.selectRId,
        data: '{"SelectedFileName":"' + this.selectFileInfo.Name + '"}'
      }
      let params = Object.assign(data, receviceParams.Record.startFileDownlaod)
      this.axios.post(Receiver().Record.startFileDownlaod, params).then(res => {
        const result = JSON.parse(res.data.result)
        if (res.data.errorCode == '0x0' && (result.errorCode && result.errorCode == '0x0' || !result.errorCode)) {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
          this.fileBtnInfo.downloadText = 'Stop'
        } else {
          this.$message({
            type: 'error',
            message: result.errorInfo
          })
        }
      }).catch(() => {})
    },
    stopFileDownload () {
      let data = {
        rid: this.State.selectRId,
        data: '{"SelectedFileName":"' + this.selectFileInfo.Name + '","IsInterruptExplicitly":' + false + '}'
      }
      let params = Object.assign(data, receviceParams.Record.stopFileDownlaod)
      this.axios.post(Receiver().Record.stopFileDownlaod, params).then(res => {
        const result = JSON.parse(res.data.result)
        if (res.data.errorCode == '0x0' && (result.errorCode && result.errorCode == '0x0' || !result.errorCode)) {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
          this.fileBtnInfo.downloadText = 'Download'
        } else {
          this.$message({
            type: 'error',
            message: result.errorInfo
          })
        }
      }).catch(() => {})
    },
    // 删除下载列表里面的文件
    deleteFile () {
      const currentTab = this.showDownloadModule
      // ture的时候选择的是downloadTab
      if (currentTab) {
        // 埋点统计
        adAnalytice('adRecordDownloadDelete')

        this.downloadFileDelete()
      } else {
        // 埋点统计
        adAnalytice('adRecordFTPDelete')

        this.ftpFileDelete()
      }
    },
    // 删除download里面的文件
    downloadFileDelete () {
      let data = {
        rid: this.State.selectRId,
        data: '{"SelectedFileName":"' + this.selectFileInfo.Name + '"}'
      }
      let params = Object.assign(data, receviceParams.Record.downloadFileDelete)
      this.axios.post(Receiver().Record.downloadFileDelete, params).then(res => {
        const result = JSON.parse(res.data.result)
        if (res.data.errorCode == '0x0' && (result.errorCode && result.errorCode == '0x0' || !result.errorCode)) {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
          this.selectFileInfo = {}
        } else {
          this.$message({
            type: 'error',
            message: result.errorInfo
          })
        }
      }).catch(() => {})
    },
    // 删除FTP里面的文件
    ftpFileDelete () {
      let data = {
        rid: this.State.selectRId,
        data: '{"SelectedFileName":"' + this.selectFileInfo.Name + '"}'
      }
      let params = Object.assign(data, receviceParams.Record.ftpFileDelete)
      this.axios.post(Receiver().Record.ftpFileDelete, params).then(res => {
        const result = JSON.parse(res.data.result)
        if (res.data.errorCode == '0x0' && (result.errorCode && result.errorCode == '0x0' || !result.errorCode)) {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
          this.fileBtnInfo.delete = true
          this.selectFileInfo = {}
        } else {
          this.$message({
            type: 'error',
            message: result.errorInfo
          })
        }
      }).catch(() => {})
    },
    // 删除下载列表里面的文件
    deleteAll () {
      const currentTab = this.showDownloadModule
      // ture的时候选择的是downloadTab
      if (currentTab) {
        // 埋点统计
        adAnalytice('adRecordFileDownloadAll')

        this.downloadFileDeleteAll()
      } else {
        // 埋点统计
        adAnalytice('adRecordFTPDownloadAll')

        this.ftpFileDeleteAll()
      }
    },
    // 删除download里面全部的下载任务
    downloadFileDeleteAll () {
      let params = Object.assign({ rid: this.State.selectRId }, receviceParams.Record.deleteAllDownload)
      this.axios.post(Receiver().Record.deleteAllDownload, params).then(res => {
        const result = JSON.parse(res.data.result)
        if (res.data.errorCode == '0x0' && (result.errorCode && result.errorCode == '0x0' || !result.errorCode)) {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
          this.fileBtnInfo.deleteAll = true
          this.fileBtnInfo.play = true
          this.selectFileInfo = ''
        } else {
          this.$message({
            type: 'error',
            message: result.errorInfo
          })
        }
      }).catch(() => {})
    },
    // 删除ftp里面的全部下载任务
    ftpFileDeleteAll () {
      let params = Object.assign({ rid: this.State.selectRId }, receviceParams.Record.deleteAllFtp)
      this.axios.post(Receiver().Record.deleteAllFtp, params).then(res => {
        const result = JSON.parse(res.data.result)
        if (res.data.errorCode == '0x0' && (result.errorCode && result.errorCode == '0x0' || !result.errorCode)) {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
        } else {
          this.$message({
            type: 'error',
            message: result.errorInfo
          })
        }
      }).catch(() => {})
      this.fileBtnInfo.deleteAll = true
      this.fileBtnInfo.play = true
      this.selectFileInfo = ''
    },
    // 关闭弹窗
    closePage (key) {
      if (key === 'download') {
        // 埋点统计
        adAnalytice('adDialogDownloadClose')

        this.showFileDownloadList = true
        if (this.popDownloadInfo.downloadTimer) clearInterval(this.popDownloadInfo.downloadTimer)
      }
      this.$store.state.showBg = true
    },
    // 获取全部的下载好的列表
    getDownloadedList () {
      // 埋点统计
      adAnalytice('adDialogDownloadOpen')

      this.createDownloadList()
      this.popDownloadInfo.downloadTimer = setInterval(function () {
        this.createDownloadList()
      }.bind(this), 2000)
    },
    createDownloadList () {
      let params = Object.assign({ rid: this.State.selectRId }, receviceParams.Record.getFileDownloadList)
      this.axios.post(Receiver().Record.getFileDownloadList, params).then(res => {
        const result = JSON.parse(res.data.result)
        if (res.data.errorCode == '0x0' && (result.errorCode && result.errorCode == '0x0' || !result.errorCode)) {
          this.downloadedList = jQuery.parseJSON(res.data.result)
          this.$store.state.showBg = false
          this.showFileDownloadList = false
          this.alertInfo.downloadList = true
          if (this.downloadedList.length > 0 && !this.selectDownloadedInfo.FileName && this.isFirstInit) {
            this.selectDownloadedInfo = this.downloadedList[0]
            this.isFirstInit = false
          }

          if (this.downloadedList.length <= 0) {
            this.popDownloadInfo.delete = true
            this.popDownloadInfo.deleteAll = true
          } else {
            this.popDownloadInfo.delete = false
            this.popDownloadInfo.deleteAll = false
          }
        } else {
          if (this.alertInfo.downloadList) {
            this.$message({
              type: 'error',
              message: result.errorInfo
            })
            this.alertInfo.downloadList = false
          }
        }
      }).catch(() => {})
    },
    saveDownloadedInfo (item) {
      this.selectDownloadedInfo = item
    },
    // 显示export模块
    showExport () {
      if (this.selectClipInfo.ID) {
        this.exportInfo.isShow = false
      } else {
        this.$message({
          type: 'warning',
          message: this.$t('lang.notSelectClip')
        })
      }
    },
    // 隐藏export模块
    hideExport () {
      this.exportInfo.isShow = true
    },
    // 发送导出命令
    exportFile () {
      const type = this.exportInfo.selectType
      let name = this.exportInfo.saveAs
      let format = 0
      if (name == '') {
        alert("The export name can't be empty")
        return
      }
      if (type == 'QuickTime') {
        format = 1
        if (name.indexOf('.mov') == -1) {
          name = name + '.mov'
        }
      } else {
        format = 0
        if (name.indexOf('.ts') == -1) {
          name = name + '.ts'
        }
      }
      let data = {
        rid: this.State.selectRId,
        data: "{\"ExportFileIndex\":\'" + format + "\',\"ExportFileName\":\"" + name + '","StartTimeStamp":"' + this.selectClipTimeInfo.start + '","EndTimeStamp":"' + this.selectClipTimeInfo.end + '"}'
      }
      let params = Object.assign(data, receviceParams.Record.exportFile)
      this.axios.post(Receiver().Record.exportFile, params).then(res => {
        const result = JSON.parse(res.data.result)
        if (res.data.errorCode == '0x0' && (result.errorCode && result.errorCode == '0x0' || !result.errorCode)) {
          this.exportInfo.isShow = true
        } else {
          this.$message({
            type: 'error',
            message: result.errorInfo
          })
        }
      }).catch(() => {})
    },
    setExportType (key) {
      this.exportInfo.selectType = key
    },
    // 转换成当前时间
    changeTime (time) {
      var _time = ''
      _time = time.replace('T', ' ')
      var index = _time.indexOf('+')
      _time = _time.slice(0, index)
      return _time
    },
    // 删除弹出框downloadlist里面的单个文件
    deleteDownloadFile () {
      if (!this.selectDownloadedInfo.FileName) {
        this.$message({
          type: 'warning',
          message: 'Please select one file'
        })
        return
      }
      // 埋点统计
      adAnalytice('deleteDownloadFile')

      let data = {
        rid: this.State.selectRId,
        data: '{"SelectedFileName":"' + this.selectDownloadedInfo.FileName + '"}'
      }
      let params = Object.assign(data, receviceParams.Record.deleteDownloadFile)
      this.axios.post(Receiver().Record.deleteDownloadFile, params).then(res => {
        const result = JSON.parse(res.data.result)
        if (res.data.errorCode == '0x0' && (result.errorCode && result.errorCode == '0x0' || !result.errorCode)) {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
          this.popDownloadInfo.delete = true
          this.selectDownloadedInfo.FileName = ''
        } else {
          this.$message({
            type: 'error',
            message: result.errorInfo
          })
        }
      }).catch(() => {})
    },
    // 删除弹出框里面的全部文件
    deleteDownloadAllFile () {
      // 埋点统计
      adAnalytice('adDialogDownloadDeleteAll')

      let params = Object.assign({ rid: this.State.selectRId }, receviceParams.Record.deleteAllDownloadFile)
      this.axios.post(Receiver().Record.deleteAllDownloadFile, params).then(res => {
        const result = JSON.parse(res.data.result)
        if (res.data.errorCode == '0x0' && (result.errorCode == '0x0' || result.errorInfo == 'Success')) {
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
          this.popDownloadInfo.deleteAll = true
        } else {
          this.$message({
            type: 'error',
            message: result.errorInfo
          })
        }
      }).catch(() => {})
    },
    playPreview () {
      if (this.$store.state.GetKernelStateObj.IsPreview) {
        this.isPreview = true
        this.$store.state.isPreviewClip = true
        var array = []
        array.push(this.$store.state.GetKernelStateObj.WebRM3U8PlaybackUrl)
        this.recordLeftObj.previewText = 'Stop'
        this.recordLeftObj.previewEnable = false
        $('#currentTime').hide()
      }
    }
  },
  filters: {
    isHaveClip (item) {
      var _str = 'hide'
      if (item.clipNumber > 0) {
        _str = ''
      }
      return _str
    },
    addSelect (selectImgIndex, index) {
      var _str = ''
      if (selectImgIndex == index) {
        _str = 'select'
      }
      return _str
    }
  },
  beforeDestroy () {
    if (this.getDateClipTimer) clearInterval(this.getDateClipTimer)
    if (this.timerInfo.record) clearInterval(this.timerInfo.record)
  }
}
</script>
<style lang="less" scoped>
::v-deep .ui-slider-handle {
  cursor: pointer;
}
.record-module {
  margin-top: 15px;
}
.content-left {
  width: 70%;
}
.content-right {
  width: 29%;
  border: 1px solid #666;
  height: 100%;
}
.content-right .header {
  text-align: left;
}
.content-right .body {
  height: 250px;
}
.content-header {
  height: 32px;
  margin-bottom: 5px;
}
.dateUl {
  overflow: hidden;
  float: left;
  width: 90%;
}
.dateUl > li {
  padding: 5px 25px;
  border: 1px solid #666;
  float: left;
  width: 14.2%;
  position: relative;
}
.downloadAll {
  width: 100%;
  height: 124px;
  text-align: center;
  padding: 2px 0;
  border: 1px solid #666;
  position: relative;
}
.d-n-text {
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.d-n-btn {
  height: 30px;
}
.d-number {
  width: 120px;
  padding: 0 10px;
  position: absolute;
}
.d-clip {
  width: 85%;
  height: 100%;
  margin-right: 5px;
}
.header-left,
.header-right {
  margin-top: 7px;
  width: 5%;
  cursor: pointer;
  font-size: 18px;
}
.time-module {
  height: 150px;
  margin-top: 5px;
  border: 1px solid #666;
  position: relative;
}
.time-left {
  width: 120px;
  height: 150px;
  padding: 0 10px;
  position: absolute;
}
.time-left input {
  width: 80px;
  height: 20px;
  margin-top: 10px;
}
.time-right {
  height: 150px;
  width: 85%;
  float: right;
  margin-right: 5px;
}
.btnCommon {
  width: 100%;
  height: 30px;
  background: #33aa50;
  outline: none;
  border: 0;
  border-radius: 3px;
  color: #fff;
}
.btnCommon.gray {
  background: gray;
}
.btnCommon:hover {
  background: #209d3d;
}
.previewBtn,
.downloadBtn {
  margin-top: 10px;
}
.time-right .imgArea {
  height: 80%;
  width: 100%;
  margin-top: 5px;
  border: 1px solid #666;
}
/* file module */
.file-module .btnCommon {
  width: 100px;
  text-align: center;
  line-height: 30px;
}
.fileModule img {
  width: 16px;
  height: 13px;
}
.fileModule li {
  overflow: hidden;
  margin-bottom: 5px;
  line-height: 25px;
  height: 25px;
}
.fileModule li.select {
  background: #333;
}
.fileModule .percentBox {
  border: 1px solid #fff;
  width: 50%;
  display: inline-block;
  position: relative;
  height: 20px;
  line-height: 20px;
  top: 3px;
}
.fileModule .percentText {
  display: inline-block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  text-align: center;
  z-index: 1;
}
.fileModule .percentColor {
  display: inline-block;
  background: #209d3d;
  height: 18px;
  position: absolute;
  left: 0;
}
.fileDownload {
  margin-right: 5px;
}
.file-tabel {
  width: 100%;
  height: 25px;
  line-height: 25px;
  border: 1px solid #666;
  border-right: 0;
  border-left: 0;
  margin-top: 5px;
}
.file-tabel li {
  text-indent: 5px;
  border-right: 1px solid #666;
}
.file-tabel li:last-child {
  border-right: 0;
}
.foot button {
  height: 30px;
  background: #33aa50;
  padding: 0 5px;
  outline: none;
  border: 0;
  border-radius: 3px;
  color: #fff;
  margin-right: 3px;
}
.foot button:hover {
  background: #209d3d;
}
.clipNumberBox {
  position: absolute;
  background: #6b8d6d;
  border-radius: 50%;
  border: 1px solid #00d16c;
  top: 0;
  right: 0;
  color: #fff;
  height: 22px;
  width: 22px;
  line-height: 22px;
}
.d-clip .header-left,
.d-clip .header-right {
  font-size: 40px;
  margin-top: 38px;
  position: relative;
  z-index: 1;
}
.d-clip ul > li {
  padding: 0;
  width: 1.5rem;
  margin-top: 4px;
  border: 0;
  margin-right: 7px;
}
.d-clip li.select img {
  border: 2px solid #00cbea;
}
.d-clip .clipThumbnail {
  width: 100%;
  height: 80px;
  background: #333;
}
.d-clip .clipStartTime {
  border: 1px solid #666;
  background: none;
}
.clip-start {
  width: 80%;
  height: 20px;
  border: 1px solid #666;
  margin: 10px auto;
}
.clip-end {
  width: 80%;
  height: 20px;
  border: 1px solid #666;
  margin: 10px auto 0;
}
.dateUl .select {
  background: none;
}
.record-module button[disabled] {
  //background:gray;
  background: #6fba80;
}
.record-module button[disabled]:hover {
  //background:gray;
  background: #6fba80;
}
.imgArea li {
  width: 20%;
  border: none;
  padding: 0;
  margin-top: 8px;
}
.imgArea img {
  height: 80px;
  background: #333;
}
.imgArea .dateUl {
  width: 100%;
}
.imgArea .clipStartTime {
  border: 1px solid #666;
}
.imgArea .f_right {
  float: right;
}
.content-header .selectDate {
  color: #33aa50;
}
.thumnailList {
  width: 90%;
  position: relative;
  overflow: hidden;
  height: 100%;
}
.thumnailList ul {
  width: 999999999999px;
  position: absolute;
  left: 0;
}
.fileModule li div:nth-child(2),
.fileModule li div:nth-child(3) {
  height: 20px;
}
#downloadList {
  position: absolute;
  top: 10%;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  z-index: 1002;
  background: #444;
  height: 600px;
  width: 800px;
}
.downloadList-header {
  background: #33ab4f;
  height: 34px;
  line-height: 34px;
  width: 100%;
  text-align: left;
  text-indent: 7px;
}
#downloadList .file-tabel {
  margin-top: 4px;
  border-top: 0;
  border-bottom: 0;
}
.exportBtn {
  background: url('../../assets/img/expand.png') 0 no-repeat;
  display: inline-block;
  width: 20px;
  height: 30px;
  cursor: pointer;
  float: right;
}
.collapseBtn {
  background: url('../../assets/img/collapse.png') 0 no-repeat;
  display: inline-block;
  width: 20px;
  height: 30px;
  cursor: pointer;
}
.foot {
  position: relative;
}
.exportBox {
  background: #333;
  top: -110px;
  position: absolute;
  width: 100%;
  padding-left: 10px;
  height: 140px;
  z-index: 1;
}
.export-header {
  height: 75px;
}
.selectionAs {
  width: 40%;
  margin-right: 10%;
  margin-top: 10px;
}
.saveAs {
  width: 40%;
  margin-top: 10px;
}
.export-foot {
  margin-top: 5px;
}
.export-foot input {
  float: left;
  margin-right: 3px;
}
#export-ul a {
  color: #fff;
}
.slider-box {
  width: 100%;
  height: 10px;
  margin-top: 5px;
  margin-bottom: 8px;
  border: 0;
  background: #333;
}
#hoverImgDiv {
  position: absolute;
  top: -105px;
  width: 1.5rem;
  z-index: 100;
}
#hoverImg img {
  width: 100%;
  height: 100%;
}
#hoverImgDiv .sj {
  margin: 0 auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px;
  border-color: #000 transparent transparent transparent;
}
#hoverImg {
  width: 100%;
  height: 100%;
}
.sliderModule {
  position: relative;
}
#downloadList .text_center .btnCommon {
  width: 82px;
}
#downloadList .text_center .btnCommon:nth-child(1) {
  margin-right: 10px;
  margin-left: -10px;
}
.fileModule .download-box {
  max-height: 200px;
}
#downloadList .download-box {
  max-height: 480px;
  height: 480px;
}
.saveInput {
  color: #333;
}

@media screen and (min-width: 1600px) and (max-width: 1919px) {
  .content-left {
    width: 60%;
  }
  .content-right {
    width: 39%;
  }

  .dateUl > li {
    width: 25%;
  }

  .time-left .btnCommon {
    width: 89%;
  }

  .d-clip {
    width: 573px;
    float: left;
    margin-left: 13%;
  }
  .thumnailList ul > li {
    margin-left: 18px;
    margin-right: 0;
    width: 107px;
  }
  .time-right {
    width: 80%;
  }
}

@media screen and (min-width: 1650px) and (max-width: 1919px) {
  .d-clip {
    margin-left: 18%;
  }
}

@media screen and (min-width: 1750px) and (max-width: 1919px) {
  .d-clip {
    width: 666px;
    float: left;
    margin-left: 15%;
  }

  .thumnailList ul > li {
    margin-left: 18px;
    margin-right: 0;
    width: 130px;
  }
}

@media screen and (min-width: 1919px) and (max-width: 3200px) {
  .imgArea li {
    width: 30%;
  }
  .imgArea .clipStartTime {
    border: none;
  }
}

@media screen and (min-width: 760px) and (max-width: 1279px) {
  .time-left {
    width: 107px;
  }
  .content-right {
    width: 100%;
    margin-top: 20px;
  }
  .content-right .header {
    padding: 10px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1599px) {
  .dateUl > li {
    width: 16.6%;
  }

  .content-right {
    width: 100%;
    width: 100%;
    margin-top: 20px;
  }
  .content-right .header {
    padding: 10px;
  }
  .content-left {
    width: 100%;
  }

  .d-clip {
    width: 703px;
    float: left;
    margin-left: 13%;
  }
  .thumnailList ul > li {
    margin-left: 18px;
    margin-right: 0;
    width: 138px;
  }
}

@media screen and (min-width: 1350px) and (max-width: 1599px) {
  .d-clip {
    margin-left: 18%;
  }
}

@media screen and (min-width: 760px) and (max-width: 1279px) {
  .foot {
    padding: 10px;
  }
  .content-right {
    width: 100%;
  }
  .content-left {
    width: 100%;
  }

  .d-clip {
    width: 341px;
    float: left;
    margin-left: 20%;
  }
  .thumnailList ul > li {
    margin-left: 15px;
    margin-right: 0;
    width: 87px;
  }
  .d-clip .clipThumbnail {
    width: 100%;
    height: 80px;
  }

  .time-right {
    height: 150px;
    width: 75%;
    float: right;
    margin-right: 5px;
  }

  .dateUl > li {
    padding: 5px 5px;
    width: 25%;
    font-size: 11px;
  }

  .imgArea .dateUl > li {
    width: 30%;
  }
  .imgArea img {
    width: 100%;
  }
}

@media screen and (min-width: 800px) and (max-width: 1279px) {
  .d-clip {
    width: 341px;
    float: left;
    margin-left: 25%;
  }
}

@media screen and (min-width: 900px) and (max-width: 1279px) {
  .d-clip {
    width: 457px;
    float: left;
    margin-left: 20%;
  }
  .thumnailList ul > li {
    margin-left: 18px;
    margin-right: 0;
    width: 118px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1279px) {
  .d-clip {
    margin-left: 25%;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1279px) {
  .d-clip {
    margin-left: 32%;
  }
}

@media screen and (min-width: 500px) and (max-width: 759px) {
  .content-right .header {
    padding: 10px;
  }
  .foot {
    padding: 10px;
  }
  .content-right {
    width: 100%;
    margin-top: 10px;
  }
  .content-left {
    width: 100%;
  }

  .d-clip {
    width: 356px;
    float: left;
    margin-left: 22%;
  }
  .thumnailList ul > li {
    margin-left: 16px;
    margin-right: 0;
    width: 90px;
  }

  .time-right {
    height: 150px;
    width: 75%;
    float: right;
    margin-right: 5px;
  }

  .dateUl > li {
    padding: 5px 5px;
    width: 25%;
    font-size: 11px;
  }
  .time-module {
    height: auto;
  }
  .imgArea .dateUl > li {
    width: 30%;
  }
  .imgArea img {
    width: 100%;
  }
}

@media screen and (min-width: 580px) and (max-width: 759px) {
  .d-clip {
    margin-left: 28%;
  }
}

@media screen and (min-width: 680px) and (max-width: 759px) {
  .d-clip {
    margin-left: 35%;
  }
}
</style>
