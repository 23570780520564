<template>
  <div class="container peerListBox">
    <div
      class="availableInfo"
      :title="`${$t(`lang.allChannel`)}：${channelData.all} | ${$t(`lang.offlineChannel`)}：${channelData.offline}`">{{$t(`lang.alreadyUse`)}}：
      <span>{{channelData.using}}</span> | {{$t(`lang.availabelUser`)}}：
      <span>{{channelData.useable}}</span>
    </div>
    <div class="sourceModule">
      <search-input
        :value="params.condition"
        :placeholder="$t('lang.searchTText')"
        :callback="search"></search-input>
    </div>
    <div class="filterModule">
      <div class="item f_left">
        <!-- status 筛选 -->
        <div class="selectBox">
          <drop-menu-select
            :data="statusData"
            :title="$t('lang.status')"
            :callback="changeDropmenuFilter"></drop-menu-select>
        </div>
      </div>
      <!-- type 筛选 -->
      <div class="item f_left">
        <div class="selectBox selectType">
          <drop-menu-select
            :data="typeData"
            :title="$t('lang.Type')"
            :callback="changeDropmenuFilter"></drop-menu-select>
        </div>
      </div>
      <!-- bookmark 筛选 -->
      <div class="item f_left">
        <book-mark
          :callback="this.filterInfo"
          :from="{Unclassified:true}"
          :defaultKeys="defaultBookmark"></book-mark>
      </div>
    </div>

    <div class="sourceListModule">
      <div class="btnBox clearfix">
        <div class="f_left addExtModule">
          <!-- <i class="iconfont sourceAddIcon" @click.prevent="showSelectTypeBox"></i> -->
          <ul
            class="selectTypeBox"
            :class="{hide:showIt}">
            <li
              v-for="(item,index) in addSourceImgArray"
              :key="index"
              @click="showAddBox(item)"
              class="addLi">
              <img
                :src="item.img"
                class="f_left">
              <span>{{item.text}}</span>
            </li>
          </ul>
          <Add-video
            :info="addSourceInfo"
            v-if="addSourceInfo.showAlert"
            :filterInfo="filterInfo"></Add-video>
        </div>

        <div
          class="f_right m-r-5"
          @click="showModule('right')">
          <i
            class="iconfont sourceInfoIcon"
            :class="{active:infoIcon}"></i>
        </div>
        <div
          class="f_right m-r-5"
          @click="showModule('left')">
          <i
            class="iconfont sourceListIcon"
            :class="{active:listIcon}"></i>
        </div>
        <div
          class="selectFilter f_right m-r-5 select-box"
          v-if="ifShowSearch">
          <select-menu
            :data="selectBySort"
            :callback="changeSort"
            :selectVal="selectSort"></select-menu>
        </div>
      </div>

      <el-scrollbar
        class="source-box"
        style="height:100%"
        v-if="isShowList">
        <div
          class="s-l-array"
          :class="{'hide':showArray}"
          @scroll="onScroll('repeat')">
          <div class="s-l-ul list-unstyled row">
            <!-- sourceTotalNumber -->
            <div
              v-for="(val,index) in sourceNumArray"
              :key="index"
              class="peerRem f_left peerBox pointer"
              :class="addBottom20Class(socketList[val.peerId],index)">
              <div
                class="imgBox"
                :class="{'redBorder':val.redBorder}"
                @click="setSourceId(socketList[val.peerId], index, $event)"
                @mouseenter="showDeviceInfo($event,socketList[val.peerId],'show')"
                @mouseleave="showDeviceInfo($event,socketList[val.peerId],'hide')">
                <div class="hide Thomas_test">{{ val.peerId }}</div>
                <div class='hide Thomas_test'>{{ socketList[val.peerId]?socketList[val.peerId].test:'' }}</div>
                <div class='hide Thomas_test'>{{ Math.random() }}}</div>
                <img
                  :src="socketList[val.peerId]?socketList[val.peerId].img:''"
                  :onerror="errorImg">
              </div>
              <div class="sourceName">
                <span
                  class="statusStyle iconfont"
                  :class="socketList[val.peerId] | SHOWICON"></span>
                <span
                  class="ellipsis"
                  :class="socketList[val.peerId] | WIDTHCLASS"
                  :title="val.name">{{val.name}}</span>
                <!-- 暂时注释 -->
                <i
                  class="iconfont editIcon f_right"
                  @click.stop="showEditDiv(socketList[val.peerId],val.peerId)"
                  v-if="(socketList[val.peerId].type == 'Ext' || socketList[val.peerId].type == 'YouTube' || socketList[val.peerId].type == 'SMPTE2022')"></i>
                <span
                  :class="socketList[val.peerId] | SWITCHBUSSTATUS"
                  title="Switch Bus"></span>
                <span
                  class="green font12 voipBox"
                  :class="socketList[val.peerId] | RETURNSTATUS($store.state.ReceiverInfoObj.IsWindows)"
                  v-if="socketList[val.peerId].type === 'TVUPack'"
                  :disabled="socketList[val.peerId] | CANSUE($store.state.ReceiverInfoObj.IsWindows)"
                  @click.stop="setVoIPOrIFB(socketList[val.peerId])">{{voipText(socketList[val.peerId])}}</span>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
      <el-scrollbar
        class="source-box"
        style="height:100%"
        v-if="isShowList">
        <div
          class="s-l-info"
          :class="{'hide':showInfo}"
          @scroll="onScroll('list')">
          <table
            class="table table-hover"
            id="s-l-info-content">
            <thead>
              <tr>
                <th>{{$t('lang.source')}}</th>
                <th>{{$t('lang.Type')}}</th>
                <th>{{$t('lang.version')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(val,index) in sourceNumArray"
                :key="index">
                <td
                  class="ellipsis w_47"
                  :title="val.name">
                  <span
                    class="statusStyle iconfont"
                    :class="socketList[val.peerId] | SHOWICON"></span>
                  {{val.name}}
                </td>
                <td classs="w_20">{{socketList[val.peerId].type}}</td>
                <td class="ellipsis w_15">{{val.version}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-scrollbar>
    </div>
    <!-- 视频信息 -->
    <div class="deviceInfoBox">
      <video-info
        :deviceInfoObj="deviceInfoObj"
        :isShowBattery1="isShowBattery1"
        :isShowBattery2="isShowBattery2"
        :batteryObj="batteryObj"
        v-if="deviceInfoFlag"
        :fn="this.showDeviceInfo"></video-info>
    </div>
    <!-- 编辑视频信息 -->
    <edit-video
      :info="editSourceInfo"
      v-if="editSourceInfo.showEditAlert"
      :filterInfo="filterInfo"></edit-video>
  </div>
</template>
<script>
import Bus from '@/assets/js/vueBus.js'
import SearchInput from '@/components/Search'
import DropMenuSelect from '@/components/dropMenuSelect'
import {
  StatusSourceSelect,
  SourceTypeSelect,
  SelectBySorts
} from '@/config'
import AddVideo from './AddVideo.vue'
import EditVideo from './EditVideo.vue'
import VideoInfo from './VideoInfo'
import BookMark from '@/components/bookMark'
import SelectMenu from '@/components/SelectMenu'
import { mapState } from 'vuex'
import '@/assets/css/peerList.css'
import { adAnalytice } from '@/assets/js/googleAnalyze/advanceControl'
import { Receiver, receviceParams } from '@/assets/api/common/receiver.js'
import { pageUserSource } from '@/assets/api/sourceControl.js'
import { SHOWDEVICEIMG } from '@/assets/js/filters'
export default {
  name: 'PeerList',
  props: {
    changeSourceId: Function,
    result: Object
  },
  data () {
    return {
      name: '', // 搜索框输入值
      statusData: StatusSourceSelect,
      statusNum: 4,
      typeData: this.$route.path.includes('pool') ? SourceTypeSelect.slice(0, 4) : SourceTypeSelect,
      typeNum: 5,
      selectBySort: SelectBySorts,
      bookmark: '',
      editSourceInfo: {
        name: '',
        url: '',
        type: '',
        id: '',
        canSave: '',
        showEditAlert: false
      },
      sourceList: [],
      socketList: {},
      isShowList: false,
      ifShowSearch: false,
      params: {
        living: true,
        online: true,
        offline: true,
        noCamera: true,
        pack: true,
        anywhere: true,
        gridPack: true,
        gridSdi: true,
        ext: false,
        pageNum: 1,
        pageSize: 30,
        condition: '',
        bookmardIds: 'all',
        unClassified: true,
        token: true,
        onlineFirst: true,
        liveFirst: false,
        // globalGrid: true,
        gridViaToken: false,
        pn: 'sc',
        tokenRegardlessOfUser: true
      },
      defaultBookmark: { // 默认bookmark选中的值
        bookmardIds: [],
        token: true,
        unClassified: true
      },
      sourceNumArray: [],
      loading: false,
      noMore: false,
      selectSort: 'onlineFirst',
      showIt: true,
      deviceInfoFlag: false,
      deviceInfoObj: {
        showInfoObj: {
          showName: false,
          showPlatform: false,
          showVersion: false,
          showLivePeerId: false,
          showBattery: false,
          showInput: true,
          showFormat: true,
          showCodec: true,
          showUploader: false,
          showTitle: false,
          showCheckStatus: false,
          showEncoder: false,
          showDirect: false,
          showRelay: false,
          showChannel: false
        },
        contentInfoObj: {},
        gridChannels: []
      },
      addSourceInfo: { name: '', url: '', type: '', showAlert: false },
      addSourceImgArray: [
        {
          img: require('@/assets/img/SMPTE.png'),
          text: 'SMPTE2022 Source',
          type: 'SMPTE2022',
          showAddBox: true
        },
        {
          img: require('@/assets/img/youtube.png'),
          text: 'YouTube Source',
          type: 'YouTube',
          showAddBox: true
        }
      ],

      constSourceList: [],

      showArray: false,
      showInfo: true,
      infoIcon: false,
      listIcon: true,
      picture: {
        pack: {
          offline: require('@/assets/img/offlinePng.png'),
          online: require('@/assets/img/logot.png'),
          noCamera: require('@/assets/img/null.png')
        },
        grid: {
          offlineRs: require('@/assets/img/offline_rs.png'),
          unknown: require('@/assets/img/unknown.png')
        },
        ext: {
          // online: require("@/assets/img/ext.jpg")
          online: require('@/assets/img/External.png')

        },
        youtube: {
          online: require('@/assets/img/null.png')
        },
        smpte: {
          online: require('@/assets/img/SMPTE.png')
        }
      },
      errorImg: 'this.src="' + require('@/assets/img/logot.png') + '"',
      sortVersionFlag: false,
      haveSortByVersion: false,
      versionArray: [],
      sortTypeFlag: false,
      haveSortByType: false,
      haveSortByName: false,
      sortNameFlag: false,
      nameArray: [],
      packListNumber: 0,
      deviceInfoTimer: null,
      batteryObj: {
        isShowSpan1: true,
        isShowSpan2: true
      },
      channelData: {
        all: 0,
        offline: 0,
        using: 0,
        useable: 0
      },
      getChannelFirst: true,
      imgTimer: null,
      imgUrlTime: {
        current: {},
        last: {}
      },
      curDeviceList: [] // 当前的设备列表
    }
  },
  computed: {
    ...mapState('common', {
      States: state => state,
      Webrtc: state => state.webrtc
    }),
    ...mapState({
      State: state => state,
      pageRWebsocket: state => state.websocket.pageRWebsocket,
      pageRequest: state => state.websocket.pageRequest
    })
  },
  created () {
    this.ifShowSearch = true
    this.querySourceList()
    Bus.$on('createNewWs', () => {
      this.createNewWebsocket()
    })
  },
  mounted () {
    this.getChannelCount()
    this.closeAddBox()
  },
  methods: {
    getInitData () {
      const params = {
        apiType: 'SourceContent'
      }
      this.axios.post('/ccp/tvucc-user/userDevice/sourceControlWithInit', params)
        .then(res => {
          if (res.data.errorCode === '0x0') {
            let result = res.data.result.content.list ? res.data.result.content.list : []
            this.sourceNumArray = this.sourceNumArray.concat(result)
            this.sourceNumArray.map(v => {
              v.redBorder = false
              v.img = SHOWDEVICEIMG(v)
            })
            if (this.sourceNumArray[0]) {
              this.sourceNumArray[0].redBorder = true
              this.sendSourceSockect(this.sourceNumArray)
              if (this.imgTimer) {
                clearInterval(this.imgTimer)
              }
              this.imgTimer = setInterval(() => {
                this.thumbnailUpdate()
              }, 5000)
              this.State.selectSourceObj = this.sourceNumArray[0]
              Bus.$emit('changeSourceObj', this.sourceNumArray[0], 'Clear')
            }

            this.loading = false
            if (result.length < this.params.pageSize) {
              this.noMore = true // 没有下一页数据
            }
            // 根据用户行为记录显示
            const param = res.data.result.param
            this.statusData.forEach(v => {
              v.isChecked = param[v.value]
              this.$set(this.statusData, 'isChecked', param[v.value])
            })
            this.typeData.forEach((v, i) => {
              v.isChecked = param[v.value] != undefined ? param[v.value] : false
              this.$set(this.typeData, i, v)
            })
            let flag = true
            this.selectBySort.forEach(v => {
              const key = v.value
              if (param[key]) {
                flag = false
                this.selectSort = key
              }
              if (flag) {
                this.selectSort = 'nameFirst'
              }
            })
            this.defaultBookmark.bookmardIds = param.bookmardIds === 'all' ? ['all'] : param.bookmardIds.split(',')
            this.params.conditions = param.condition
          }
        }).catch(() => {})
    },
    sendChannel () {
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'getChannelCount')
      }
      let flag = this.States.sourceControlTab === 'manual'
      this.pageRequest.send({
        message: {
          type: 'getChannelCount',
          module: 'getChannelCount',
          data: { manual: flag }
        },
        time: 3000,
        key: 'getChannelCount',
        success: res => {
          this.channelData = res.result
        }
      })
    },
    getChannelCount () {
      // 获取手动channel使用信息
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'getChannelCount')
      }
      let flag = this.States.sourceControlTab === 'manual'
      this.pageRequest.send({
        message: {
          type: 'getChannelCount',
          module: 'getChannelCount',
          data: { manual: flag }
        },
        time: 100,
        key: 'getChannelCount',
        isOnce: true,
        success: res => {
          this.channelData = res.result
          this.sendChannel()
        }
      })
    },
    // 保存所选择的的tid
    setSourceId (obj, index, event) {
      if (this.sourceNumArray[index].redBorder || this.sourceNumArray[index].status == '0') return
      // CCTV中切换选中的Source
      this.sourceNumArray.map(v => {
        v.redBorder = false
      })
      this.sourceNumArray[index].redBorder = true
      this.$set(this.sourceNumArray, index, this.sourceNumArray[index])

      this.State.selectSourceObj = obj
      Bus.$emit('changeSourceObj', obj, 'Clear')
      // 埋点统计
      adAnalytice('adListSelectSource')
    },
    onScroll (type) {
      this.scrollTop = 0
      this.listHeight = 0
      if (type == 'repeat') {
        this.scrollTop = $('.sourceListModule .s-l-array').scrollTop()
        this.listHeight = $('.sourceListModule .list-unstyled').height()
      } else {
        this.scrollTop = $('.sourceListModule .s-l-info').scrollTop()
        this.listHeight = $('.sourceListModule #s-l-info-content').height()
      }
      if (this.scrollTop && this.listHeight && (this.listHeight - this.scrollTop <= 750 && !this.loading && !this.noMore)) {
        // 在请求没返回结果前 和 请求的数据长度小于pagesize时 不在滚动加载
        this.loading = true

        this.pageNum += 1
        this.params.pageNum += 1
        this.querySourceList()
      }
    },
    search (val) {
      // 埋点统计
      adAnalytice('adSearch')

      // 搜索
      this.params.condition = val
      this.filterInfo()
    },
    // 过滤type
    changeDropmenuFilter (data, all) {
      // 埋点统计
      let lastParams = JSON.parse(JSON.stringify(this.params))
      let type = 'adStatus'
      if (typeof data.pack == 'boolean') { // 选择的是type
        type = 'adType'
      }
      let params = all ? Object.assign(data, all) : Object.assign(data)
      adAnalytice(type, params, lastParams)

      Object.keys(data).map(v => {
        this.params[v] = data[v]
      })
      this.filterInfo()
    },
    changeSort (val) { // 排序
      // 埋点统计
      adAnalytice('adSort', val)

      this.params.pageNum = 1
      this.params.onlineFirst = val == 'onlineFirst'
      this.params.liveFirst = val == 'liveFirst'
      this.filterInfo()
    },
    filterInfo (bookMark, rObj, str) {
      Bus.$emit('clearVideo')
      this.isShowList = false
      this.sourceNumArray = []
      this.noMore = false
      this.bookmark = bookMark || this.bookmark
      this.params.pageNum = 1
      this.pageNum = 1
      this.params.pageSize = this.params.pageSize
      this.params.condition = this.params.condition
      this.params.bookmardIds = this.bookmark ? this.bookmark.bookmardIds : this.params.bookmardIds
      this.params.unClassified = this.bookmark ? this.bookmark.unClassified : this.params.unClassified

      delete this.params.allType
      this.querySourceList(str)
    },
    querySourceList (str) {
      /*
      * delete this.params.gridViaToken;
      * delete this.params.globalGrid;
      * TODO: 自动及手动都不查询ext，自动通道不查询 GridPack 和 GridSdi
      */
      this.params.ext = false
      if (this.States.sourceControlTab === 'pool') {
        this.params.gridPack = false
        this.params.gridSdi = false
        this.params.unClassified = false
      }
      pageUserSource(this.params).then(res => {
        if (res.data.errorCode == '0x0') {
          let result = res.data.result.list ? res.data.result.list : []
          this.sourceNumArray = this.sourceNumArray.concat(result)
          if (this.sourceNumArray.length <= 0) return
          this.sourceNumArray.map(v => {
            v.redBorder = false
            v.img = SHOWDEVICEIMG(v)
          })
          this.sourceNumArray[0].redBorder = true
          this.sendSourceSockect(this.sourceNumArray)
          if (this.imgTimer) {
            clearInterval(this.imgTimer)
          }
          this.imgTimer = setInterval(() => {
            this.thumbnailUpdate()
          }, 5000)
          this.State.selectSourceObj = this.sourceNumArray[0]
          Bus.$emit('changeSourceObj', this.sourceNumArray[0], 'Clear')
          this.loading = false
          if (result.length < this.params.pageSize) {
            this.noMore = true // 没有下一页数据
          }
        }
      }).catch(() => {})
    },
    // 更新缩略图
    thumbnailUpdate () {
      let peerIds = []
      this.curDeviceList.map(v => {
        if (v && v.status != 2) peerIds.push(v.peerId)
        if (v && v.status == 2) peerIds.push(v.livePeerId)
      })
      if (!peerIds.length) return
      this.axios.post(`${location.protocol}//${this.$store.state.common.baseUrl.blobUrl.split('/')[0]}/thumbnailVersion`, peerIds, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
        .then(res => {
          this.imgUrlTime.last = this.imgUrlTime.current
          this.imgUrlTime.current = res.data
        }).catch(() => {})
    },
    sendSourceSockect (data) {
      if (data && data.length > 0) {
        let sendParam = []
        this.curDeviceList = []
        data.forEach(item => {
          sendParam.push(item.peerId)
          this.curDeviceList.push(item)
          this.socketList[item.peerId] = {}
        })
        if (this.$createWebsocket.pageRequest) {
          this.$createWebsocket.pageRequest('stop', 'taskInfo_T_S') // 停止websocket
        }
        this.pageRequest.send({
          message: {
            type: 'taskInfo',
            module: 'taskInfo_T_S',
            data: { peerIds: sendParam.join(',') }
          },
          time: 1000,
          key: 'taskInfo_T_S',
          success: res => {
            let results = res.result
            this.curDeviceList = []
            if (results.length > 0) {
              results.map(item => {
                let tempKey = item.peerId
                item.test = Math.random()
                // 如果时间戳跟上次相同，使用上次的url
                if ((item.status == 2 && this.imgUrlTime.current[item.livePeerId] === this.imgUrlTime.last[item.livePeerId]) || (item.status == 1 && this.imgUrlTime.current[tempKey] === this.imgUrlTime.last[tempKey])) {
                  item.img = this.socketList[tempKey].img
                } else { // 如果不同重新获取
                  item.img = SHOWDEVICEIMG(item)
                }
                this.$set(this.socketList, tempKey, { ...item })
                if (this.State.selectSourceObj.peerId === item.peerId) {
                  this.State.selectSourceObj = item
                }
                // 更新获取缩略图时间戳参数
                this.curDeviceList.push(item)
              })
              this.isShowList = true
            }
          }
        })
      }
    },
    closeAddBox () {
      let that = this
      document.addEventListener('click', function (e) {
        e = e || window.event
        var tar = e.srcElement || e.target
        var tarObj = $(tar).parent()
        if (!tarObj.hasClass('addExtModule') && !tarObj.hasClass('addLi')) {
          that.showIt = true
        }
      }, true)
    },
    showModule (key) {
      // 埋点统计
      let type = key == 'left' ? 'ThumbnailList' : 'SourceList'
      adAnalytice('adList', type)

      if (key == 'left') {
        this.showArray = false
        this.showInfo = true
        this.infoIcon = false
        this.listIcon = true
        this.haveSortByVersion = false
        this.haveSortByType = false
        this.haveSortByName = false
      } else {
        this.showArray = true
        this.showInfo = false
        this.infoIcon = true
        this.listIcon = false
      }
    },
    showSelectTypeBox () {
      this.showIt = !this.showIt
      // 埋点统计
      let type = this.showIt ? 'VideoClose' : 'VideoOpen'
      adAnalytice('adDialog', type)
    },
    // 显示添加的盒子
    showAddBox (item) {
      item.showAddBox = false
      this.showIt = true

      // 埋点统计
      adAnalytice('adDialog', 'VideoClose') // 关闭type弹窗埋点
      adAnalytice('adDialog', item.type + 'Open')

      this.addSourceInfo.type = item.type
      this.addSourceInfo.showAlert = true
      this.addSourceInfo.rid = this.rid
    },
    // 显示修改的盒子
    showEditDiv (item) {
      this.editSourceInfo.showEditAlert = true
      this.showEditBox = false
      this.editSourceInfo.name = item.sourceName
      this.editSourceInfo.url = item.url
      this.editSourceInfo.type = item.type
      this.editSourceInfo.id = item.id
      this.editSourceInfo.rid = this.rid
      this.editSourceInfo.StrIdHex = item.StrIdHex
      this.editSourceInfo.StrAppendixIdHex = item.StrAppendixIdHex
      this.editSourceInfo.canSave = item.IsRemote
    },
    // 排序
    sort (key) {
      // 先关闭所有条件排序在根据所选择的进行排序
      this.isHaveReverse = false
      this.haveSortByType = false
      this.haveSortByVersion = false
      this.haveSortByName = false

      switch (key) {
        case 'name':
          this.haveSortByName = true
          this.sortByName('click')
          break
        case 'type':
          this.haveSortByType = true
          this.sortByType('click')
          break
        case 'version':
          this.haveSortByVersion = true
          this.sortByVersion('click')
          break
      }
    },
    // 根据名字字母排序
    sortByName (key) {
      let nameList = []
      if (key === 'click') this.sortNameFlag = !this.sortNameFlag
      if (this.haveSortByName) {
        if (this.sortNameFlag) {
          nameList = this.sourceList.sort(function (data1, data2) {
            if (/^\d/.test(data1.sourceName) ^
              /^\D/.test(data2.sourceName)
            ) {
              return data1.sourceName > data2.sourceName ? 1 : data1.sourceName == data2.sourceName ? 0 : -1
            }
            return data1.sourceName > data2.sourceName ? -1 : data1.sourceName == data2.sourceName ? 0 : 1
          })
          this.sourceList = nameList
          this.nameArray = nameList
          this.isHaveReverse = false
        } else {
          if (!this.isHaveReverse) {
            this.isHaveReverse = true
            this.sourceList = this.nameArray.reverse()
          } else {
            this.sourceList = this.nameArray
          }
        }
      }
    },
    // 根据类型排序pack>grid>ext
    sortByType (key) {
      // 只有在主动点击TYPE排序的时候才更改值.
      if (key === 'click') this.sortTypeFlag = !this.sortTypeFlag
      if (this.haveSortByType) {
        if (this.sortTypeFlag) {
          this.sourceList.reverse()
        }
      }
    },
    // 根据版本过滤
    sortByVersion (key) {
      let sourceList = []
      // 只有在主动点击version排序的时候才更改值.
      if (key === 'click') this.sortVersionFlag = !this.sortVersionFlag
      if (this.haveSortByVersion) {
        if (this.sortVersionFlag) {
          sourceList = this.constSourceList.sort(function (data1, data2) {
            let version1 = data1.version ? Number(data1.version.slice(0, 3)) : 1
            let version2 = data2.version ? Number(data2.version.slice(0, 3)) : 1
            if (version1 < version2) {
              return 1
            } else if (version1 > version2) {
              return -1
            } else {
              return 0
            }
          })
          this.sourceList = sourceList
          this.versionArray = sourceList
          this.isHaveReverse = false
        } else {
          if (!this.isHaveReverse) {
            this.isHaveReverse = true
            this.sourceList = this.versionArray.reverse()
          } else {
            this.sourceList = this.versionArray
          }
        }
      }
    },
    voipText (item) {
      let _str = ''
      _str = typeof item.voipEnable == 'boolean' ? item.voipEnable ? 'VoIP' : 'IFB' : 'VoIP'
      const connectStatus = item.voipConnection
      if (connectStatus) {
        _str = 'VoIP...'
      }
      return _str
    },
    setVoIPOrIFB (item) {
      let id = item.id
      let voipEnable = item.voipEnable
      let dataStr = {
        rid: this.rid,
        data: '{"IdHex":"' + id + '"}'
      }
      let params = Object.assign(dataStr, receviceParams.MediaDevice.getMediaDeviceVoip)
      if (voipEnable === true && item.status != 0) {
        this.axios.post(Receiver().MediaDevice.getMediaDeviceVoip, params).then(res => {}).catch(() => {})
      }
      if (voipEnable === false) {
        const isChecked = !item.IFBProperty.IsChecked
        if (item.IFBProperty && item.IFBProperty.IfbEnabled && item.status != 0) {
          let dataStr = {
            rid: this.rid,
            data: '{"IdHex":"' + id + '","IsEnabled":' + isChecked + '}'
          }
          let params = Object.assign(dataStr, receviceParams.MediaDevice.getMediaDeviceIFB)
          this.axios.post(Receiver().MediaDevice.getMediaDeviceIFB, params).then(res => {}).catch(() => {})
        }
      }
    },
    // 添加marginbottom class
    addBottom20Class (option, index) {
      let obj = {}
      let packNumber = this.packListNumber
      obj.bottom_20 = true
      switch (packNumber % 3) {
        case 1:
          if (packNumber != 1) {
            if (index == packNumber - 1) obj.bottom_20 = false
          } else {
            if (index == 0) obj.bottom_20 = false
          }
          break
        case 2:
          if (index == packNumber - 1 || index == packNumber - 2) { obj.bottom_20 = false }
          break
      }
      return obj
    },
    // 显示设备的信息
    showDeviceInfo (event, item, key) {
      if (key === 'show') {
        if (this.deviceInfoTimer) clearInterval(this.deviceInfoTimer)
        this.deviceInfoTimer = setTimeout(
          function () {
            const x = event.x
            const y = event.y
            this.deviceInfoFlag = true
            $('.deviceInfoBox').css('top', y)
            $('.deviceInfoBox').css('left', x)
            this.deviceInfoObj.contentInfoObj.name = item.name
            this.deviceInfoObj.contentInfoObj.id = item.peerId
            this.deviceInfoObj.contentInfoObj.rName = item.livePeerName
            this.deviceInfoObj.contentInfoObj.status = item.status
            this.deviceInfoObj.contentInfoObj.workMode = this.$store.state.GetKernelStateObj.WorkMode
            // 如果状态是0不在线的话就只显示那么
            Object.keys(this.deviceInfoObj.showInfoObj).forEach(
              function (key) {
                this.deviceInfoObj.showInfoObj[key] = true
              }.bind(this)
            )
            // 根据状态显示要显示的内容
            if (item.status != 0) {
              switch (item.type) {
                case 'T':
                  this.showPackInfo(item)
                  break
                case 'Ext':
                  this.deviceInfoObj.showInfoObj.showName = false
                  break
                case 'YouTube':
                  this.deviceInfoObj.contentInfoObj.Uploader =
                    item.Uploader
                  this.deviceInfoObj.contentInfoObj.FullTitle =
                    item.FullTitle
                  this.deviceInfoObj.showInfoObj.showName = false
                  this.deviceInfoObj.showInfoObj.showUploader = false
                  this.deviceInfoObj.showInfoObj.showTitle = false
                  break
                case 'X':
                  this.showGridInfo(item)
                  break
              }
            }
          }.bind(this),
          500
        )
      } else {
        if (this.deviceInfoTimer) clearInterval(this.deviceInfoTimer)
        if (event.relatedTarget) {
          if (event.relatedTarget.className == 'deviceInfo') return
          this.deviceInfoFlag = false
          this.deviceInfoObj.name = ''
          this.deviceInfoObj.platform = ''
          this.deviceInfoObj.version = ''
          this.deviceInfoObj.live = ''
          this.deviceInfoObj.battery = ''
          this.deviceInfoObj.input = ''
          this.deviceInfoObj.format = ''
          this.deviceInfoObj.codec = ''
          this.deviceInfoObj.id = ''
          this.deviceInfoObj.status = ''
        }
      }
    },
    showPackInfo (item) {
      this.deviceInfoObj.contentInfoObj.transportType =
        item.transportType
      this.deviceInfoObj.contentInfoObj.platform = item.platform
      this.deviceInfoObj.contentInfoObj.version = item.version
      this.deviceInfoObj.contentInfoObj.live = item.livePeerId
      this.deviceInfoObj.contentInfoObj.battery = item.battery
      this.deviceInfoObj.contentInfoObj.input = item.input
      this.deviceInfoObj.contentInfoObj.format = item.format
      this.deviceInfoObj.contentInfoObj.codec = item.codec
      this.deviceInfoObj.showInfoObj.showName = false
      this.deviceInfoObj.showInfoObj.showPlatform = false
      this.deviceInfoObj.showInfoObj.showVersion = false
      this.deviceInfoObj.showInfoObj.showLivePeerId = false
      this.deviceInfoObj.showInfoObj.showBattery = false
      this.deviceInfoObj.showInfoObj.showInput = true
      this.deviceInfoObj.showInfoObj.showFormat = true
      this.deviceInfoObj.showInfoObj.showCodec = true
      // 显示电量的逻辑
      const batteryXml = this.string2XML(item.BatteryInfo)
      const battery1 = $(batteryXml).find('BATTERY').attr('BAT1')
      const battery2 = $(batteryXml).find('BATTERY').attr('BAT2')
      if (battery1 == -1 || battery1 == null || battery1 == '') {
        this.batteryObj.isShowSpan1 = true
      } else {
        this.batteryObj.isShowSpan1 = false
      }
      if (battery2 == -1 || battery2 == null || battery2 == '') {
        this.batteryObj.isShowSpan2 = true
      } else {
        this.batteryObj.isShowSpan2 = false
      }
      this.isShowBattery1(batteryXml)
      this.isShowBattery2(batteryXml)
    },
    showGridInfo (item) {
      this.deviceInfoObj.contentInfoObj.IsCheckStatus = item.IsCheckStatus == true ? 'True' : 'False'
      var _str = ''
      var direct = 'True'
      var XAlive = -1
      // 转换类型
      if (item.GridEncoderStatus == -1) {
        _str = 'Unknown'
      } else if (item.GridEncoderStatus == 0) {
        _str = 'Disabled'
      } else if (item.GridEncoderStatus == 1) {
        _str = 'NoInput'
      } else {
        _str = 'Encoding'
      }
      // 转换大小写
      if (item.IsDirectConnection == true) {
        direct = 'True'
      } else {
        direct = 'False'
      }
      // 转换xalive
      if (item.XAlive == -1) {
        XAlive = 'UNKNOWN'
      } else if (item.XAlive == 0) {
        XAlive = 'NO_CONNECTION'
      } else if (item.XAlive == 1) {
        XAlive = 'DEAD'
      } else if (item.XAlive == 2) {
        XAlive = 'ALIVE'
      }
      this.deviceInfoObj.contentInfoObj.GridEncoderStatus = _str
      this.deviceInfoObj.contentInfoObj.IsDirectConnection = direct
      this.deviceInfoObj.contentInfoObj.XAlive = XAlive
      this.deviceInfoObj.gridChannels = item.AdditionalChannels
      this.deviceInfoObj.showInfoObj.showName = false
      this.deviceInfoObj.showInfoObj.showCheckStatus = false
      this.deviceInfoObj.showInfoObj.showEncoder = false
      this.deviceInfoObj.showInfoObj.showDirect = false
      this.deviceInfoObj.showInfoObj.showRelay = false
      this.deviceInfoObj.showInfoObj.showChannel = false
    },
    string2XML (xmlString) {
      // 所有浏览器统一用这种方式处理(因为高版本的浏览器都支持)
      var parser = new DOMParser()
      var xmlObject = parser.parseFromString(xmlString, 'text/xml')
      return xmlObject
    },
    isShowBattery1: function (batteryXml) {
      let bat1, batValue1
      bat1 = $(batteryXml).find('BATTERY').attr('BAT1')
      batValue1 = $(batteryXml).find('BATTERY').attr('BAT1VAL')
      if (
        (bat1 == '0' && batValue1 != '0') ||
        (bat1 != -1 && bat1 != null && bat1 != '')
      ) {
        if (Number(batValue1) <= 20) {
          $('.battery1-value')
            .siblings('.battery-dot')
            .addClass('redBorder')
            .parent('.battery-moudel')
            .addClass('redBorder')
        } else {
          $('.battery1-value')
            .siblings('.battery-dot')
            .removeClass('redBorder')
            .parent('.battery-moudel')
            .removeClass('redBorder')
        }
        $('.battery1-value').css('width', batValue1 + '%')
        return true
      } else {
        return false
      }
    },
    isShowBattery2: function (batteryXml) {
      let bat2, batValue2
      bat2 = $(batteryXml).find('BATTERY').attr('BAT2')
      batValue2 = $(batteryXml).find('BATTERY').attr('BAT2VAL')
      if ((bat2 == '0' && batValue2 != '0') || (bat2 != -1 && bat2 != null && bat2 != '')) {
        if (Number(batValue2) <= 20) {
          $('.battery2-value')
            .siblings('.battery-dot')
            .addClass('redBorder')
            .parent('.battery-moudel')
            .addClass('redBorder')
        } else {
          $('.battery2-value')
            .siblings('.battery-dot')
            .removeClass('redBorder')
            .parent('.battery-moudel')
            .removeClass('redBorder')
        }
        $('.battery2-value').css('width', batValue2 + '%')
        return true
      } else {
        return false
      }
    }
  },
  components: {
    AddVideo,
    EditVideo,
    VideoInfo,
    BookMark,
    SearchInput,
    DropMenuSelect,
    SelectMenu
  },
  beforeRouteLeave (to, from, next) {
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', 'taskInfo_T_S') // 停止websocket
      this.$createWebsocket.pageRequest('stop', 'getChannelCount')
    }
    next()
  },
  beforeDestroy () {
    Bus.$off('createNewWs')
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', 'taskInfo_T_S') // 停止websocket
      this.$createWebsocket.pageRequest('stop', 'getChannelCount')
    }
    if (this.imgTimer) {
      clearInterval(this.imgTimer)
    }
  }
}
</script>
<style  scoped lang="less">
@import '../../assets/css/rcAdaptive/peerList.less';
.peerListBox {
  float: left;
  .availableInfo {
    width: fit-content;
    cursor: pointer;
    text-align: left;
    margin-bottom: 10px;
    color: #999;
  }
  .filterModule {
    display: flex;
    .item {
      flex: 1;
      position: relative;
      .selectBox {
        width: calc(100% - 10px);
      }
    }
    .item:first-child {
      .selectBox {
        margin-left: 0;
      }
    }
    ::v-deep .tree-btn {
      padding: 0 20px;
      height: 32px;
      line-height: 32px;
      &.enBtn {
        padding: 0 5px;
      }
      @media screen and (min-width: 760px) and (max-width: 1279px) {
        &.enBtn {
          padding: 0 20px;
        }
      }
    }
  }
}
.searchBox {
  ::v-deep .el-input__inner {
    color: #fff;
    &::-webkit-input-placeholder {
      color: #666;
    }
  }
}
.selectFilter {
  width: 120px;
  margin-top: 3px;
  ::v-deep .el-select .el-input__inner {
    background: #444;
    height: 26px;
    line-height: 26px;
  }
  ::v-deep .el-input__suffix {
    line-height: 26px;
    .el-input__icon {
      line-height: 26px;
    }
  }
}

#videoBtn,
#searchInput {
  width: 100%;
  height: 100%;
  outline: none;
}
::v-deep .el-button {
  width: 100%;
}
::v-deep .el-dropdown-menu {
  width: 100px;
}
::v-deep .el-dropdown-menu__item {
  padding: 0 18px;
  .checkBox {
    margin: 2px 5px 0 0;
  }
}

#videoBtn {
  border: 0;
}
.f-s-b {
  background: #252525;
  border: none;
  padding: 3px 25px;
  border-radius: 5px;
}
.f-s-b.focus,
.f-s-b:focus,
.f-s-b:hover {
  color: #fff;
}
.peerBox {
  min-width: 92px;
}
.dropdown-menu {
  min-width: -webkit-fill-available;
  background: #252525;
}
.dropdown-menu > li > a {
  color: #fff;
}
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  background: none;
}
.dropdown-menu > li:hover {
  background: rgba(57, 170, 80, 0.25);
}
.f-s-m {
  margin-right: 13px;
}
.row {
  margin-left: 0;
  margin-right: 0;
}
@media (min-width: 300px) {
  .container {
    color: #dbdbdb;
    background-color: #252525;
    width: 100%;
    padding-right: 12px;
  }
}
@media (min-width: 300) and (max-width: 600px) {
  .container {
    width: 100%;
  }
}
.col-xs-8 {
  padding-left: 5px;
  padding-right: 5px;
  text-align: left;
}
.col-md-6 {
  padding-left: 5px;
  padding-right: 5px;
  text-align: left;
}
.col-md-4 {
  padding-left: 6px;
  padding-right: 6px;
}
.table-hover > tbody > tr:hover {
  background-color: rgba(57, 170, 80, 0.25);
}
.table > tbody > tr > td {
  line-height: 1.65;
  text-align: left;
}
.table > thead > tr > th {
  text-align: left;
}
.dropdown-menu > li > a {
  display: inline-block;
  padding: 3px 5px;
}
.f-f-m .dropdown-menu {
  min-width: 145px;
}
.imgBox {
  background: #000;
  height: 65px;
  min-height: 51px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
}
#app {
  margin-top: 0;
}
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
}
.addSourceOl input {
  width: inherit;
  height: inherit;
  width: 215px;
  color: #333;
}
.s-l-ul {
  padding-bottom: 100px;
}
.s-l-ul div.packBox:last-child {
  margin-bottom: 0;
}
.voipBox {
  cursor: pointer;
  display: inline-block;
  padding: 2px 3px;
  border-radius: 2px;
  position: absolute;
  right: 2px;
  bottom: 4px;
  font-size: 12px;
  line-height: 12px;
}
.voipBox[disabled] {
  background: gray;
}
.sourceListModule {
  padding: 0;
  margin-bottom: 55px;
  .line {
    text-decoration: underline;
  }
  .btnBox {
    margin: 7px 0 5px;
  }
  .m-r-5 {
    margin-right: 10px;
  }
  .sourceAddIcon {
    position: relative;
    top: 3px;
  }
}

.switch {
  width: 11px;
  height: 11px;
  display: inline-block;
  border-radius: 50%;
  margin-top: 5px;
  float: right;
  margin-right: 5px;
}
.deviceInfo {
  position: absolute;
  background: #444;
  z-index: 1;
  text-align: left;
  border: 1px solid #fff;
  padding: 5px;
  width: 450px;
  font-size: 13px;
}
.deviceInfo div span:nth-child(1) {
  width: 20%;
  display: inline-block;
}
.deviceInfo div span:nth-child(2) {
}
#channels div span:nth-child(1) {
  width: auto;
  padding-left: 10px;
}
.option {
  overflow: hidden;
}

.quality a {
  color: #fff;
}
.green {
  background: #33aa50;
}
.red {
  background: red;
}
.deviceInfoBox {
  position: fixed;
  z-index: 2;
}

@media screen and (min-width: 500px) and (max-width: 759px) {
  .sourceListModule {
    margin-bottom: -21px;
  }
}
</style>
