<template>
  <div
    class="addExtBox"
    v-if="info.showAlert">
    <div class="addExt-top">
      <div class="f_left sourceText">{{$t('lang.addExtSource')}}</div>
      <div
        class="f_right"
        @click="close">
        <i class="iconfont closeIcon"></i>
      </div>
    </div>
    <div class="addExt-body">
      <ul class="addUl addSourceOl">
        <li>
          <span class="f_left optionClass">{{$t('lang.Type')}}:</span>
          <span class="f_left">{{info.type}}</span>
          <div class="f_right hide">
            <input type="checkbox">
            <span>SwitchField</span>
          </div>
        </li>
        <li>
          <span class="f_left optionClass">{{$t('lang.name')}}:</span>
          <el-input
            class="f_left white"
            v-model.trim="info.name"
            autocomplete="off"
            v-filter-special-char="{changeVal}"
            size="small ">
          </el-input>
        </li>
        <li>
          <span class="f_left optionClass">URL:</span>
          <el-input
            class="f_left white"
            v-model.trim="info.url"
            autocomplete="off"
            size="small ">
          </el-input>
          <button
            class="f_right greenBtn"
            v-if="info.type === 'YouTube'"
            @click="analyzeAddUrl">{{$t(`lang.${addSourceValidate}`)}}</button>
        </li>
        <li>
          <div class="f_left optionClass"></div>
          <button
            class="addSourceBtn greenBtn f_left"
            @click="addSource()">{{$t('lang.Save')}}</button>
          <button
            class="cancelAddbtn greenBtn f_left"
            @click="close">{{$t('lang.cancel')}}</button>
        </li>
      </ul>
    </div>
  </div>

</template>
<script>
import { adAnalytice } from '@/assets/js/googleAnalyze/advanceControl'
import { Receiver, receviceParams } from '@/assets/api/common/receiver.js'
import Outil from '@/assets/js/utils.js'
export default {
  props: {
    info: Object,
    filterInfo: Function
  },
  data () {
    return {
      addSourceValidate: 'validate',
      addBtn: true

    }
  },
  created () {
  },
  methods: {
    // 关闭添加或者修改的盒子
    close () {
      // 埋点统计
      adAnalytice('adDialog', this.info.type + 'Close')
      this.info.name = ''
      this.info.url = ''
      this.info.showAlert = false
      this.info.type = ''
    },
    changeVal (val) {
      this.info.name = val
    },
    // 添加新的source
    addSource (item) {
      let data = JSON.parse(JSON.stringify(this.info))
      const { name, url } = data
      delete data.showAlert
      if (!name || (name && name.length < 3)) {
        this.$message.error(this.$t('lang.extSourceNameTips'))
        return
      }
      if (!url) {
        this.$message.error(this.$t('lang.urlRequired'))
        return
      }
      // 埋点统计
      adAnalytice('adDialog', this.info.type + 'Save')

      if (!this.addBtn) return
      this.addBtn = false
      this.axios.post('/ccp/tvucc-user/ext/addExt', data)
        .then(res => {
          this.addBtn = true
          if (res.data.errorCode == '0x0' && !res.data.result) {
            this.info.name = ''
            this.info.url = ''
            this.info.showAlert = false
            this.$message({
              type: 'success',
              message: i18n.t('lang.success')
            })
            this.filterInfo()
          } else if (res.data.errorCode == '0x0' && res.data.result) {
            this.$message({
              type: 'info',
              message: this.$t('lang.sameExtName', { name: res.data.result })
            })
          } else {
            Outil.ToTip(res.data.errorCode, 'info', res.data.errorInfo)
          }
        }).catch(() => {
          this.addBtn = true
        })
    },
    analyzeAddUrl () {
      const id = ''
      let { url, rid } = this.info
      if (url == '') {
        this.$message.error(this.$t('lang.urlRequired'))
        return
      }
      if (this.addSourceValidate === 'validating') return
      this.addSourceValidate = 'validating'
      const data = {
        rid: rid,
        data: '{"IdHex":"' + id + '","Url":"' + url + '"}',
        timeout: 14000
      }
      let params = Object.assign(data, receviceParams.youTobeAnalyze.analyzeUrl)
      this.axios.post(Receiver().youTobeAnalyze.analyzeUrl, params, { timeoutTips: true })
        .then(res => {
          if (res.data.errorCode == '0x0' && res.data.result == 'True') {
            this.$message({
              type: 'success',
              message: this.$t('lang.success')
            })
            this.addSourceValidate = 'validate'
          } else {
            this.addSourceValidate = 'validate'
            if (res.data.result == 'False') {
              this.$message({
                type: 'warning',
                message: this.$t('lang.invalidUrl')
              })
            } else if (JSON.parse(res.data.result) && JSON.parse(res.data.result).errorCode == '0x80520101') {
              this.$message({
                type: 'warning',
                message: this.$t('lang.moduleNotFound')
              })
            } else {
              Outil.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
            }
          }
        }).catch(() => {})
    }
  }
}
</script>
<style lang="less" scoped>
.addSourceOl {
  ::v-deep .el-input {
    height: 30px;
    width: 215px;
  }
  ::v-deep .el-input__inner {
    background: #fff;
    border: 1px solid #fff;
    height: 30px;
    line-height: 30px;
  }
}
</style>
