<template>
  <div class="manualControl">
    <div class="body">
      <div class="body-left min-height">
        <div class="listBox clearfix">
          <peer-list
            ref="peerList"
            :result="sourceResult"></peer-list>
        </div>
      </div>
      <!-- 右侧视频及信息区域 -->
      <div class="body-right advanceControl">
        <div class="right-top clearfix">
          <!-- 音量柱区域 -->
          <div class="audioSection f_left">
            <div class="graduateModule f_left">
              <div class="graduateModule-bg"></div>
            </div>
            <!-- 音量显示区域 -->
            <div class="audio-module">
              <audios></audios>
            </div>
          </div>
          <!-- 视频播放区域 -->
          <div class="video-module video-module-8">
            <div class="video-top">
              <div
                class="source-name left"
                :title="selectSourceObj.name">{{selectSourceObj.name}}</div>
              <div
                class="select-output"
                v-clickoutside="handleClose">
                <i
                  class="iconfont icon-share"
                  :class="{shareNotAllowed:!this.liveStatus}"
                  @click="toggleSocialList">&nbsp;&nbsp;{{$t(`lang.Output`)}}</i>
                <span
                  v-if="shareData.sharingSocial != 0 && this.liveStatus"
                  class="sharingNum globalNum">{{shareData.sharingSocial}}</span>
                <i
                  class="arrow-icon"
                  v-show="toggleSelect"
                  @click="changeVisible('handle')"
                  :class="[visibleStatus ? 'el-icon-arrow-up': 'el-icon-arrow-top']"></i>
                <el-select
                  ref="socialModule"
                  v-show="toggleSelect"
                  v-model="checkedList"
                  multiple
                  collapse-tags
                  filterable
                  @change="changeShareList"
                  @visible-change="changeVisible"
                  default-first-option
                  :popper-append-to-body="false"
                  :disabled="inputFlag"
                  class="select-module"
                  :placeholder="$t('lang.selectSocial')">
                  <el-option
                    v-for="item in outputList"
                    :key="item.id"
                    :label="item.nickName"
                    :class="judgeSocialColor(item)"
                    :value="item.id"
                    :title="item.nickName"
                    :disabled="item.shareFlag == 1">
                  </el-option>
                </el-select>
                <div
                  v-show="loadingSocial"
                  class="loadingBox">{{ `${loadingText}~` }}</div>
              </div>
              <div class="f_right liveBtn">
                <el-button
                  @click="clearQUality1($event,true)"
                  size="small"
                  :loading="allowClick"
                  :class="showButtonInfo() | BTNSTATUS">{{$t(`lang.${btnInfo.text}`)}}</el-button>
              </div>
              <div class="select-box f_right">
                {{$t(`lang.currentChannel`)}}：
                <el-select
                  v-model="selectRValue"
                  @change="changeReceiverId"
                  @visible-change="refreshRListStatus"
                  class="rListSelect"
                  :placeholder="$t('lang.option')"
                  size="small"
                  popper-class="rcSource"
                  :disabled="States.sourceControlTab === 'pool'">
                  <el-option-group
                    v-for="group in RInfo"
                    :key="group.label"
                    v-if="group.show">
                    <el-option
                      v-for="item in group.list"
                      :key="item.id"
                      :label="item.name"
                      :value="`${item.peerId}|${item.version}|${group.label}`"
                      :disabled="item.status == 0"
                      :class="judgeColor(item)"
                      :title="item.peerId">
                    </el-option>
                  </el-option-group>
                </el-select>
              </div>
            </div>
            <video-c
              width="100%"
              :executeRtcLeave='false'
              :useOldR="true"
              deviceType="R"></video-c>
          </div>
          <!-- 设置bitrate和delay，以及流量显示区域 -->
          <div class="quality-Bitrate-module mediaSourceList">
            <setting
              :useOldR="true"
              :width="chartWidth"></setting>
          </div>
          <!-- R的错误率以及Format等信息显示区域 -->
          <div class="status-module">
            <status></status>
          </div>
        </div>
        <div class="right-bottom">
          <div class="bottom-header">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="showModule">
              <el-menu-item index="1">{{$t('lang.live')}}</el-menu-item>
              <el-menu-item
                index="2"
                v-show="this.State.currentRVersion >= 7.2">{{$t('lang.record')}}</el-menu-item>
            </el-menu>
            <div class="line"></div>
          </div>
          <div class="bottom-content">
            <!-- slot列表区域 -->
            <div
              class="slot-module"
              v-show="this.showLive">
              <adapter-list></adapter-list>
            </div>
            <!-- 录制区域 -->
            <div
              class="record"
              v-show="!this.showLive">
              <record></record>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="grayBg"
      :class="{hide:this.$store.state.showBg}"></div>
    <el-dialog
      :title="$t('lang.modelTips')"
      :visible.sync="dialogVisible"
      width="485px"
      custom-class="greenColor">
      <span>{{ $t(`${titleText}`) }}</span>
      <span
        slot="footer"
        class="dialog-footer">
        <el-button @click="dialogVisible = false">{{$t('lang.cancel')}}</el-button>
        <el-button
          type="primary"
          @click="sendToPlayVideo">{{$t('lang.Ok')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import PeerList from './PeerList'
import Status from './Status'
import Setting from './setting'
import Audios from './Audios'
import VideoC from '@/components/VideoC'
import AdapterList from './AdapterList'
import Record from './Record'
import { mapState } from 'vuex'
import Tool from '@/assets/js/utils.js'
import Bus from '@/assets/js/vueBus.js'
import { adAnalytice } from '@/assets/js/googleAnalyze/advanceControl'
import {
  getRList,
  manualStartLive,
  autoStartLive,
  stopLive,
  startShare,
  stopShare,
  checkRIsManual,
  listShareAccount
} from '@/assets/api/sourceControl.js'

export default {
  name: 'manual',
  components: {
    'peer-list': PeerList,
    status: Status,
    setting: Setting,
    audios: Audios,
    VideoC,
    'adapter-list': AdapterList,
    record: Record
  },
  data () {
    return {
      activeIndex: '1',
      showLive: true,
      outputList: [],
      outputVal: '',
      btnInfo: { text: 'Take', status: 0 },
      sendStartLiveNumber: 0,
      dialogVisible: false,
      startVideoKey: 0, // 值有4个0,1,2,3 123分别对应cc上面的三种情况1.R在live T没有2.R没有在live T在live3.R和T都在live
      titleText: 'lang.titleText', // 为了Rlive pack时提示框
      isByBtn: true,
      isClear: '',
      selectSourceId: '',
      timeOut: null,
      clickTake: false,
      clickStop: false,
      listRResult: {},
      sourceResult: {},
      analyzeUrl: true,
      lastClick: '',
      currentClick: '',
      screenWidth: document.body.clientWidth,
      chartWidth: '',
      toggleSelect: false,
      selectSourceObj: {},
      RInfo: [],
      liveStatus: false,
      selectRValue: '',
      selectRId: '', // 下拉选择的R
      rid: '', // 正在live的R
      RInfoFlag: false,
      checkedList: [],
      oldCheckedList: [],
      shareData: {
        sharingSocial: 0
      },
      loadingSocial: false,
      loadingText: this.$t('lang.pushing'),
      pushInfoFirst: true,
      isSelectAutoR: false,
      inputFlag: false,
      visibleStatus: false,
      autoClickFlag: false,
      allowClick: false
    }
  },
  created () {
    // 监听切换source
    Bus.$off('changeSourceObj')
    Bus.$on('changeSourceObj', (data, flag) => {
      // 此时需要清空info信息及播放信息
      this.toggleSelect = false
      this.selectRValue = ''
      this.selectSourceObj = data
      this.getRList(data.peerId, flag)
    })
    Bus.$off('clearAdapter')
    Bus.$on('clearAdapter', () => {
      this.btnInfo.text = 'Take'
      this.btnInfo.status = 0
    })
    this.States.sourceControlTab = window.location.href.includes('pool') ? 'pool' : ''
  },
  computed: {
    ...mapState({
      // 映射
      State: state => state,
      channelNumber: state => state.channelNumber,
      ReceiverInfoObj: state => state.ReceiverInfoObj,
      GetKernelStateObj: state => state.GetKernelStateObj,
      pageRWebsocket: state => state.websocket.pageRWebsocket,
      pageRequest: state => state.websocket.pageRequest
    }),
    ...mapState('common', {
      States: state => state,
      Webrtc: state => state.webrtc
    }),
    showRemainBox () {
      if (this.ReceiverInfoObj.ProductBuildVersion) {
        if (this.ReceiverInfoObj.ProductBuildVersion.slice(0, 3) >= '7.2') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  watch: {
    'State.GetKernelStateObj': function (val) {
      if (val.WorkMode === 1 && this.selectRId.toLowerCase() == this.rid.toLowerCase()) {
        this.btnInfo.text = 'Stop'
        this.allowClick = false
        if (this.clickStop) {
          this.btnInfo.text = 'stopping'
          this.allowClick = true
        }
        this.clickTake = false
      } else {
        this.btnInfo.text = 'Take'
        this.allowClick = false
        if (this.clickTake && this.analyzeUrl) {
          this.btnInfo.text = 'loading'
          this.allowClick = true
        }
        this.clickStop = false
      }
    }
  },
  mounted () {
    window.addEventListener('resize', () => {
      let width = $('.receviceBox').css('left') == '0px' ? 0 : $('.receviceBox').width()
      $('.body-left .receviceBox').css(
        'left', -width
      )

      $('.top .receiverBtn').css(
        'left', -width
      )
      if (document.body.clientWidth > 760 && document.body.clientWidth <= 1095) {
        this.chartWidth = '100%'
      } else {
        this.chartWidth = ''
      }
    })
  },
  methods: {
    changeVisible (flag) {
      if (flag === 'handle') {
        if (!this.autoClickFlag && !this.visibleStatus) {
          this.$refs.socialModule.$el.querySelector('.el-select__tags .el-select__input').focus()
        }
        this.autoClickFlag = false
      } else {
        this.autoClickFlag = true
        this.visibleStatus = flag
        if (this.loadingSocial && !flag) {
          this.inputFlag = false
          this.$nextTick(() => {
            this.$refs.socialModule.$el.querySelector('.el-select__tags .el-select__input').focus()
            this.inputFlag = true
            this.loadingSocial = true
          })
        }
      }
    },
    // 修改选中的nickName
    changeShareList () {
      let newArr = [...new Set([...this.oldCheckedList, ...this.checkedList])]
      let arr1 = this.checkedList
      let arr2 = this.oldCheckedList
      let deleteArr = [...newArr].filter(x => [...arr1].every(y => y !== x))
      let addArr = [...newArr].filter(x => [...arr2].every(y => y !== x))
      if (addArr.length > 0) {
        this.toShare(addArr)
      }
      if (deleteArr.length > 0) {
        this.stopShare(deleteArr)
      }
    },
    // 开始推流
    toShare (addArr) {
      this.loadingSocial = true
      this.inputFlag = true
      this.loadingText = this.$t('lang.pushing')
      let params = {
        rid: this.rid,
        shareId: addArr[0]
      }
      startShare(params).then(res => {
        this.loadingSocial = false
        this.inputFlag = false
        if (res.data.errorCode === '0x0') {
          this.$message({
            type: 'success',
            message: this.$t('lang.pushSuccess')
          })
          this.oldCheckedList = this.checkedList
          this.getOutputList()
        } else {
          this.checkedList = this.oldCheckedList
          Tool.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
        }
      }).catch(() => {
        this.checkedList = this.oldCheckedList
        this.loadingSocial = false
        this.inputFlag = false
      })
    },
    stopShare (deleteArr) {
      this.loadingSocial = true
      this.inputFlag = true
      this.loadingText = this.$t('lang.stopping')
      let params = {
        rid: this.rid,
        shareId: deleteArr[0]
      }
      stopShare(params).then(res => {
        this.loadingSocial = false
        this.inputFlag = false
        if (res.data.errorCode === '0x0') {
          this.$message({
            type: 'success',
            message: this.$t('lang.stopPushSuccess')
          })
          this.oldCheckedList = this.checkedList
          this.getOutputList()
        } else {
          this.checkedList = this.oldCheckedList
          Tool.ToTip(res.data.errorCode, 'info', res.data.errorInfo)
        }
      }).catch(() => {
        this.checkedList = this.oldCheckedList
        this.loadingSocial = false
        this.inputFlag = false
      })
    },
    // 下拉修改选中的R
    changeReceiverId (v, str, flag) {
      // 埋点统计
      if (!str) { // 从视频区域的下拉框选择
        adAnalytice('adVideoSelectSource')
      }
      // 下拉获取选中的Rid及选中R的版本号
      this.selectRId = v ? v.split('|')[0] : ''
      this.State.selectRId = this.selectRId
      this.isSelectAutoR = v ? v.includes('otherAutos') : false
      this.State.currentRVersion = v ? v.split('|')[1] : ''
      this.showLive = this.State.currentRVersion >= 7.2 ? this.showLive : true
      this.activeIndex = this.State.currentRVersion >= 7.2 ? this.activeIndex : '1'
      // 当切换source时，默认选中R为空，此时需要清除所有R相关信息
      if (this.selectRId == '' || flag) {
        this.clearRInfo()
      }
      // 判断选中的R是否正在和当前R进行live
      if (this.State.selectSourceObj.status == '2' && this.State.selectSourceObj.livePeerId == this.selectRId) {
        this.rid = this.selectRId
        this.getRInfo()
      }
      // 当选中的R和当前sourcelive的时候加载声音声道的数量
      Bus.$emit('initRList')
    },
    clearRInfo () {
      // 切换r 则停止之前的websocket
      clearInterval(window.adapterInteral)
      clearInterval(window.settingInterval)
      this.$createWebsocket.pageRWebsocket('stop', 'getReceiverInfo')
      this.$createWebsocket.pageRWebsocket('stop', 'getQueryKennel')
      this.$createWebsocket.pageRWebsocket('stop', 'getAdapterInfo')
      this.$createWebsocket.pageRWebsocket('stop', 'getSettingInfo')
      this.$createWebsocket.pageRWebsocket('stop', 'getVideoVoice')
      this.$createWebsocket.pageRequest('stop', 'getPushinfo')
      this.State.GetKernelStateObj = {}
      this.State.ReceiverInfoObj = {}
      this.State.SettingObj = {}
      this.RInfoFlag = false
      $('.audio-module .audioValue').css('height', 0)
      // 清空流量信息及比特率等信息
      Bus.$emit('restoreDefaultConfig')
      Bus.$emit('clearAdapter')
      Bus.$emit('selectOtherR')
    },
    // 获取R信息的websocket
    getRInfo () {
      if (!this.RInfoFlag) {
        this.RInfoFlag = true
        // 获取R相关信息
        this.getReceiverInfo()
        this.getQueryKennel()
        this.getSettingInfo()
        this.getAdapterInfo()
        // 获取实时推流信息
        this.getPushinfo()
      }
    },
    handleClose () {
      this.toggleSelect = !!this.loadingSocial
    },
    // 切换推流下拉列表
    toggleSocialList () {
      // 正在推流时也不允许点击
      if (!this.liveStatus || this.loadingSocial && this.toggleSelect) {
        return
      }
      this.getOutputList()
    },
    // 获取推流账号列表
    getOutputList () {
      this.checkedList = []
      this.oldCheckedList = []
      let params = {
        rid: this.rid.toLowerCase(),
        tid: this.selectSourceObj.peerId.toLowerCase()
      }
      listShareAccount(params).then(res => {
        if (res.data.errorCode === '0x0') {
          this.outputList = res.data.result
          this.outputList.forEach((item) => {
            if (item.shareFlag == 2) {
              this.checkedList.push(item.id)
              this.oldCheckedList.push(item.id)
            }
          })
          this.toggleSelect = !this.toggleSelect
          if (this.toggleSelect) {
            this.$nextTick(() => {
              this.$refs.socialModule.$el.querySelector('.el-select__tags .el-select__input').focus()
            })
          }
        }
      }).catch(() => {})
    },
    // 获取与Source对应的R下拉列表
    getRList (id, flag) {
      let params = {
        peerId: id.toLowerCase(),
        condition: ''
      }
      this.RInfo = []
      // 在自动模式下是不需要rid的所以也不需要调用获取rlist接口
      if (this.States.sourceControlTab === 'poll') return
      getRList(params).then(res => {
        if (res.data.errorCode === '0x0') {
          let resultObj = res.data.result
          let liveWithAutos = { label: 'liveWithAutos', show: resultObj.liveWithAutos.length > 0, list: resultObj.liveWithAutos }
          let liveWithManuals = { label: 'liveWithManuals', show: resultObj.liveWithManuals.length > 0, list: resultObj.liveWithManuals }
          let otherAutos = { label: 'otherAutos', show: resultObj.otherAutos.length > 0, list: resultObj.otherAutos }
          let otherManuals = { label: 'otherManuals', show: resultObj.otherManuals.length > 0, list: resultObj.otherManuals }
          this.RInfo.push(liveWithAutos, liveWithManuals, otherAutos, otherManuals)
          // 当切换source时，重新获取R列表，R不需要默认选项
          this.selectRId = ''
          this.selectRVersion = ''
          this.rid = ''
          let tab = { list: [] }
          if (liveWithManuals.list.length > 0) tab = liveWithManuals
          if (liveWithAutos.list.length > 0) tab = liveWithAutos

          if (tab.list.length > 0) {
            let [peerId, version] = [tab.list[0].peerId, tab.list[0].version]
            const _str = peerId + '|' + version
            this.selectRValue = tab.list[0].name
            this.changeReceiverId(_str, 'init', flag)
          } else {
            this.changeReceiverId()
          }
        } else {
          Tool.ToTip(res.data.errorCode, 'info', res.data.errorInfo)
        }
      }).catch(() => {})
    },
    // 更新rlist状态
    refreshRListStatus (visible) {
      if (!visible) return
      let params = {
        peerId: this.selectSourceObj.peerId.toLowerCase(),
        condition: ''
      }
      this.RInfo = []
      getRList(params).then(res => {
        if (res.data.errorCode === '0x0') {
          let resultObj = res.data.result
          let liveWithAutos = { label: 'liveWithAutos', show: resultObj.liveWithAutos.length > 0, list: resultObj.liveWithAutos }
          let liveWithManuals = { label: 'liveWithManuals', show: resultObj.liveWithManuals.length > 0, list: resultObj.liveWithManuals }
          let otherAutos = { label: 'otherAutos', show: resultObj.otherAutos.length > 0, list: resultObj.otherAutos }
          let otherManuals = { label: 'otherManuals', show: resultObj.otherManuals.length > 0, list: resultObj.otherManuals }
          this.RInfo.push(liveWithAutos, liveWithManuals, otherAutos, otherManuals)
        } else {
          Tool.ToTip(res.data.errorCode, 'info', res.data.errorInfo)
        }
      }).catch(() => {})
    },
    // 根据source与R状态展示按钮状态
    showButtonInfo () {
      const info = this.State.GetKernelStateObj
      let Status = 0
      if (info.WorkMode === 1 && this.selectRId.toLowerCase() == this.rid.toLowerCase()) {
        this.btnInfo.status = 2
        Status = 2
        this.liveStatus = true
      } else {
        this.btnInfo.status = 1
        Status = 1
        this.liveStatus = false
      }
      if (this.States.sourceControlTab === 'manual' && (this.selectSourceObj.status === '0' || JSON.stringify(this.selectSourceObj) == '{}' || !this.selectRId)) {
        Status = 0
        this.btnInfo.status = 0
      }
      return Status
    },
    // 根据R状态进行UI展示
    judgeColor (item) {
      let _str = ''
      const status = item.status
      if (status == 2) {
        _str = 'redColor'
      }
      return _str
    },
    judgeSocialColor (item) {
      let _str = ''
      const shareFlag = item.shareFlag
      if (shareFlag == 2 || shareFlag == 1) {
        _str = 'redColor'
      }
      return _str
    },
    showModule (key, keyPath) {
      // m埋点统计
      if (key == 1) {
        adAnalytice('adLiveTab')
      } else {
        adAnalytice('adRecordTab')
      }

      Bus.$emit('showModule', key)
      const index = key
      if (index == 1) {
        this.activeIndex = '1'
        this.showLive = true
        this.$store.state.currentSelectTab = 0
      } else {
        this.activeIndex = '2'
        this.showLive = false
        this.$store.state.currentSelectTab = 1
      }
    },
    sendPushInfo () {
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'shareList') // 停止websocket
      }
      this.pageRequest.send({
        message: { type: 'shareNum', module: 'shareList', data: { flag: false, condition: this.rid } },
        time: 5000,
        key: 'shareList',
        success: res => {
          let shareNum = res.result.devices[0] ? res.result.devices[0].shareSize : 0
          this.$set(this.shareData, 'sharingSocial', shareNum)
        }
      })
    },
    getPushinfo () {
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'shareList') // 停止websocket
      }
      this.pageRequest.send({
        message: { type: 'shareNum', module: 'shareList', data: { flag: false, condition: this.rid } },
        time: 100,
        key: 'shareList',
        isOnce: true,
        success: res => {
          let shareNum = res.result.devices[0] ? res.result.devices[0].shareSize : 0
          this.$set(this.shareData, 'sharingSocial', shareNum)
          if (this.$createWebsocket.pageRequest) {
            this.$createWebsocket.pageRequest('stop', 'shareList') // 停止websocket
          }
          this.sendPushInfo()
        }
      })
    },
    // 获取R版本等信息接口
    getReceiverInfo () {
      this.pageRWebsocket.send({
        message: { categoryId: '2152857600', operationType: '101', rid: this.rid },
        time: 1000,
        key: 'getReceiverInfo',
        success: res => {
          let data = res.data ? JSON.parse(res.data) : {}
          this.State.ReceiverInfoObj = data

          let getReceiverInfo = this.pageRWebsocket.sendMsgs.getReceiverInfo
          if (getReceiverInfo) {
            getReceiverInfo.message = {
              categoryId: '2152857600',
              operationType: '101',
              rid: this.rid
            }
          }
        }
      })
    },
    // 获取R视频播放地址以及用什么播放插件接口
    getQueryKennel () {
      // TODO:在Manual中修改下拉R后需要修改这里的rid
      this.pageRWebsocket.send({
        message: { categoryId: '2152857600', operationType: '102', rid: this.rid },
        time: 1000,
        key: 'getQueryKennel',
        success: res => {
          let result = res.data ? JSON.parse(res.data) : {}
          this.State.GetKernelStateObj = result
          this.$store.state.selectSourceName = this.$store.state.selectSourceName ? this.$store.state.selectSourceName : result.WorkMode == 1 ? result.CurrentTName : ''
          /*
            * this.lastLivePeerId = this.currentLivePeerId;
            * this.currentLivePeerId = result.CurrentTStrIdHex;
            * if(this.lastLivePeerId != this.currentLivePeerId || this.State.selectCurrentRId != this.State.selectLastRId) {
            *     this.$store.state.selectSourceName = result.CurrentTName;
            *     this.$store.state.selectSourceId = result.CurrentTStrIdHex.toLowerCase();
            *     this.State.selectLastRId = this.State.selectCurrentRId
            * };
            */
          let obj = {}
          let WorkMode = result.WorkMode
          let liveId = result.CurrentTStrIdHex
          if (this.$store.state.selectSourceId == null || this.$store.state.selectSourceId == '') {
            this.$store.state.selectSourceId = JSON.parse(res.data).CurrentTStrIdHex.toLowerCase()
          }
          // 分别根据不同的状态吗来显示video
          if (result.WebRVideoSolution == 300) {
            $('.flv-box').hide()
            $('.webrtc').show()
          } else {
            $('.flv-box').show()
            $('.webrtc').hide()
          }
          // 在根据当前的R是否在直播显示时间
          if (WorkMode == 1) {
            obj.status = 2
            obj.text = 'Stop'
            /*
              * 这个是防止R自动live的时候T不在线.
              * if (res.data.SubMode == 1) {
              *     obj.status = 1;
              *     obj.text = 'Live';
              * }
              */
            $('#currentTime').hide()
            // 获取视频音量
            if (!this.pageRWebsocket.successObj.getVideoVoice) {
              this.pageRWebsocket.send({
                message: {
                  categoryId: '2152867840',
                  operationType: '200',
                  rid: this.rid
                },
                time: 200,
                key: 'getVideoVoice',
                success: res => {
                  Bus.$emit('changeVoice', res.data)
                  this.State.common.voiceInfo = JSON.parse(res.data)
                }
              })
            }
          } else {
            if (!this.State.GetKernelStateObj.IsPreview && !this.State.isFilePreview) {
              $('.audio-module .audioValue').css('height', 0)
              if (this.pageRWebsocket.successObj.getVideoVoice && this.pageRWebsocket.interval.getVideoVoice) {
                clearInterval(
                  this.pageRWebsocket.interval.getVideoVoice
                )
              }
            }
            obj.status = 1
            obj.text = 'Live'
            if (!this.$store.state.isPreviewClip && !this.$store.state.isFilePreview) {
              if (!this.$store.state.progressTid) {
                $('#currentTime').show()
              }
              $('.webrtc').hide()
            }
            if (this.$store.state.isPreviewClip || this.$store.state.isFilePreview) { $('#currentTime').hide() }
            $('.flv-box').hide()
            this.$store.state.isFristInitWebrtc = true
          }
          this.currentLiveTId = liveId.toLowerCase()
          this.btnInfo = obj

          let getQueryKennel = this.pageRWebsocket.sendMsgs.getQueryKennel
          if (getQueryKennel) {
            getQueryKennel.message = {
              categoryId: '2152857600',
              operationType: '102',
              rid: this.rid
            }
          }
        }
      })
    },
    // 获取R错误率format等信息
    getSettingInfo () {
      this.pageRWebsocket.send({
        message: { categoryId: '2152866048', operationType: '101', rid: this.rid },
        time: 1000,
        key: 'getSettingInfo',
        success: res => {
          let data = res.data ? JSON.parse(res.data) : {}
          this.State.SettingObj = data

          let getSettingInfo = this.pageRWebsocket.sendMsgs.getSettingInfo
          if (getSettingInfo) {
            getSettingInfo.message = {
              categoryId: '2152866048',
              operationType: '101',
              rid: this.rid
            }
          }
        }
      })
    },
    // 获取流量信息
    getAdapterInfo () {
      this.pageRWebsocket.send({
        message: { operationType: '101', categoryId: '2152865792', rid: this.rid },
        time: 1000,
        key: 'getAdapterInfo',
        success: res => {
          let data = res.data ? JSON.parse(res.data) : {}
          Bus.$emit('getAdapter', data)

          let getAdapterInfo = this.pageRWebsocket.sendMsgs.getAdapterInfo
          if (getAdapterInfo) {
            getAdapterInfo.message = {
              categoryId: '2152865792',
              operationType: '101',
              rid: this.rid
            }
          }
        }
      })
    },
    clearQUality1 (event, flag) {
      if (!this.selectRId && this.States.sourceControlTab === 'manual') return
      this.isByBtn = flag
      this.allowClick = true
      // 设置setting内bitrate和delay，以及流量显示区域信息
      Bus.$emit('playVideo')
      // 播放视频
      this.playSourceVideo(flag)
    },
    // 播放视频
    async playSourceVideo (flag) {
      if (this.clickTake || this.clickStop) return

      // 埋点统计
      let type
      if (this.btnInfo.text == 'Stop' || this.btnInfo.text == '停止') {
        type = 'adVideoStop'
      }
      if (this.btnInfo.text == 'Take' || this.btnInfo.text == '直播') {
        type = 'adVideoTake'
      }
      adAnalytice(type)
      if (this.btnInfo.text == 'Take' || !flag) {
        if (this.States.sourceControlTab === 'manual' && this.isSelectAutoR) {
          this.$confirm(this.$t('lang.isAutoRLive'), this.$t('lang.modelTips'), {
            confirmButtonText: this.$t('lang.confirm'),
            cancelButtonText: this.$t('lang.cancel'),
            center: true,
            type: 'warning',
            callback: action => {
              if (`${action}` == 'confirm') {
                this.dialogVisible = true
                if (this.State.selectSourceObj.type != 'T') {
                  if (this.GetKernelStateObj.WorkMode == 1 && this.State.selectSourceObj.status != 2) {
                    this.startVideoKey = 0
                    this.titleText = 'lang.ReceiverOnLive'
                  } else {
                    this.dialogVisible = false
                    this.sendStart(flag)
                  }
                } else {
                  // R在live,当前所选择的T没有在live
                  if (this.GetKernelStateObj.WorkMode == 1 && this.State.selectSourceObj.status != 2) {
                    this.startVideoKey = 1
                    this.titleText = 'lang.ReceiverOnLive'
                    // 当前的R没在live,当前所选择的设备在live
                  } else if (this.GetKernelStateObj.WorkMode != 1 && this.State.selectSourceObj.status == 2) {
                    this.startVideoKey = 2
                    this.titleText = 'lang.ReceiverDeviceOnLine'
                    // 当前的R和T都在live
                  } else if (this.GetKernelStateObj.WorkMode == 1 && this.State.selectSourceObj.status == 2) {
                    this.startVideoKey = 3
                    this.titleText = 'lang.ReceiverDeviceOnLine'
                  } else {
                    // 都没有在live所以直接发送send命令
                    this.dialogVisible = false
                    this.sendStart(flag)
                  }
                }
              }
            }
          })
        } else {
          this.dialogVisible = true
          if (this.State.selectSourceObj.type != 'T') {
            if (this.GetKernelStateObj.WorkMode == 1 && this.State.selectSourceObj.status != 2) {
              this.startVideoKey = 0
              this.titleText = 'lang.ReceiverOnLive'
            } else {
              this.dialogVisible = false
              this.sendStart(flag)
            }
          } else {
            // R在live,当前所选择的T没有在live
            if (this.GetKernelStateObj.WorkMode == 1 && this.State.selectSourceObj.status != 2) {
              this.startVideoKey = 1
              this.titleText = 'lang.ReceiverOnLive'
              // 当前的R没在live,当前所选择的设备在live
            } else if (this.GetKernelStateObj.WorkMode != 1 && this.State.selectSourceObj.status == 2) {
              this.startVideoKey = 2
              this.titleText = 'lang.ReceiverDeviceOnLine'
              // 当前的R和T都在live
            } else if (this.GetKernelStateObj.WorkMode == 1 && this.State.selectSourceObj.status == 2) {
              this.startVideoKey = 3
              this.titleText = 'lang.ReceiverDeviceOnLine'
            } else {
              // 都没有在live所以直接发送send命令
              this.dialogVisible = false
              this.sendStart(flag)
            }
          }
        }
      } else {
        // stop live之前先要去调用接口查询source start live的位置是在手动还是自动
        const flag = await this.judgeRISManual()
        this.dealStop(flag)
      }
      window.testNumber = 0
    },
    judgeRISManual (stopRId) {
      return new Promise((resolve, reject) => {
        const params = {
          peerId: stopRId || this.selectRId.toLowerCase()
        }

        checkRIsManual(params).then(res => {
          let flag = null
          const errorCode = res.data.errorCode
          if (errorCode === '0x0') {
            flag = res.data.result
          } else {
            Tool.ToTip(errorCode, 'warning', res.data.errorInfo)
          }
          resolve(flag)
        }).catch(() => {})
      })
    },
    dealStop (flag) {
      const currentTab = this.States.sourceControlTab
      if (currentTab === 'manual') {
        if (flag) {
          this.stopLive()
        } else {
          this.$confirm(this.$t('lang.isAutoReceiver'), this.$t('lang.modelTips'), {
            confirmButtonText: this.$t('lang.confirm'),
            cancelButtonText: this.$t('lang.cancel'),
            center: true,
            type: 'warning',
            callback: action => {
              if (`${action}` == 'confirm') {
                this.stopLive()
              }
            }
          })
        }
      } else {
        if (flag) {
          this.$confirm(this.$t('lang.isManualReceiver'), this.$t('lang.modelTips'), {
            confirmButtonText: this.$t('lang.confirm'),
            cancelButtonText: this.$t('lang.cancel'),
            center: true,
            type: 'warning',
            callback: action => {
              if (`${action}` == 'confirm') {
                this.stopLive()
              }
            }
          })
        } else {
          this.stopLive()
        }
      }
    },
    // 发送start命令
    sendStart (flag) {
      this.clickTake = true
      this.analyzeUrl = true
      this.$store.state.isFristInitWebrtc = true
      let [address, params] = ['', {}]
      if (this.States.sourceControlTab === 'pool') {
        params = {
          sid: this.State.selectSourceObj.peerId.toLowerCase()
        }
        address = autoStartLive
      } else {
        params = {
          rid: this.selectRId.toLowerCase(),
          sid: this.State.selectSourceObj.peerId.toLowerCase()
        }
        address = manualStartLive
      }
      // TODO:需要区分手动及自动start
      address(params).then(res => {
        const errorCode = res.data.errorCode
        this.sendStartLiveNumber++
        // TODO：改为只要点击过start就去创建一个websocket，后面每次start只用修改rid即可
        this.rid = this.selectRId
        this.getRInfo()
        if (res.status === 200 && res.data.errorInfo == 'Success') {
          // 当start成功后，将live的rId切换掉
          if (this.States.sourceControlTab === 'pool') {
            this.selectRId = res.data.result
            this.rid = res.data.result
          } else {
            this.rid = this.selectRId
          }
          this.$message({
            type: 'success',
            message: this.$t('lang.success')
          })
          this.isClear = false
          this.dialogVisible = false
          this.sendStartLiveNumber = 0
          Bus.$emit('playVideo')
        } else if (errorCode === '81100107') {
          this.dealPeerNotFound(flag) // 针对ext类型的
          this.clickTake = false
        } else if (errorCode === '81100105' || errorCode === '81100108') {
          setTimeout(() => {
            this.dealTPeerLiveWithOther(flag) // 真对当发生抢占的时候R那边会来不及处理
            this.clickTake = false
          }, 1000)
        } else {
          this.isClear = true
          this.dialogVisible = false
          Tool.ToTip(errorCode, 'warning', res.data.errorInfo)
          this.clickTake = false
          this.allowClick = false
        }
        Bus.$emit('clearVideo')
      }).catch(() => {})
    },
    // 停止直播
    stopLive (stopRId) {
      this.clickStop = true
      const params = {
        rid: stopRId || this.selectRId.toLowerCase(),
        sid: this.State.selectSourceObj.peerId.toLowerCase()
      }
      stopLive(params).then(res => {
        // let _str = ''
        const errorCode = res.data.errorCode
        if (errorCode == '0x0') {
          // stop成功后需要将live的rid置为空
          this.rid = ''
          this.isClear = true
          this.clearRInfo()
        } else {
          Tool.ToTip(errorCode, 'warning', res.data.errorInfo)
          this.clickStop = false
          this.allowClick = false
        }
      }).catch(() => {})
    },
    dealPeerNotFound (flag) {
      /*
      * 在cc上面添加的ext，播放时因为多了R同步数据的这一步，所以接口有可能返回设备不存在
      * 最多startlive3次，如果三次后还没有起起来，则视为startlive失败
      */
      if (this.sendStartLiveNumber < 3) {
        setTimeout(() => {
          this.sendStart(flag)
        }, 500)
      } else {
        this.sendStartLiveNumber = 0
        const _str = this.$t('lang.RnotfoundthisSource')
        this.$message({
          type: 'warning',
          message: _str
        })
        this.dialogVisible = false
      }
    },
    dealTPeerLiveWithOther (flag) {
      // 发生抢占的时候有可能R那边的返回值还没变
      if (this.sendStartLiveNumber < 3) {
        setTimeout(() => {
          this.sendStart(flag)
        }, 1000)
      } else {
        this.sendStartLiveNumber = 0
        this.$message({
          type: 'warning',
          message: this.$t('lang.Sourceliving')
        })
        this.dialogVisible = false
      }
    },
    sendToPlayVideo () {
      // 根据key分别处理
      switch (this.startVideoKey) {
        case 0:
        case 1:
          this.sendStart(this.isByBtn)
          break
        case 2:
        case 3: {
          const params = {
            peerId: this.State.selectSourceId
          }
          this.axios.get('/ccp/tvucc-device/device/getDevice', { params: params })
            .then(res => {
              if (res.status == 200 && res.data.errorInfo == 'Success') {
                const livePeerId = res.data.result.livePeerId
                this.stopLive(livePeerId)
                console.log('执行stop')
                // 延迟一秒的原因是刚发送完stop立马start可能会发生R那边没有及时响应,导致start失败。
                setTimeout(() => {
                  this.sendStart(this.isByBtn)
                }, 1000)
              }
            }).catch(() => {})
          break
        }
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(window.adapterInteral)
    clearInterval(window.settingInterval)
    next()
  },
  beforeDestroy () {
    // 停止receiver的websocket
    clearInterval(window.adapterInteral)
    clearInterval(window.settingInterval)
    this.$createWebsocket.pageRWebsocket('stop', 'getReceiverInfo')
    this.$createWebsocket.pageRWebsocket('stop', 'getQueryKennel')
    this.$createWebsocket.pageRWebsocket('stop', 'getAdapterInfo')
    this.$createWebsocket.pageRWebsocket('stop', 'getSettingInfo')
    this.$createWebsocket.pageRWebsocket('stop', 'getVideoVoice')

    this.$createWebsocket.pageRequest('stop', 'taskInfo_T')
    this.$createWebsocket.pageRequest('stop', 'taskInfo_TR')
    this.$createWebsocket.pageRequest('stop', 'getPushinfo')
  }
}
</script>
<style scoped lang="less">
@import '../../assets/css/rcAdaptive/index.less';
.arrow-icon {
  position: absolute;
  top: 50px;
  left: 255px;
  z-index: 10;
}
::v-deep .select-output .el-input .el-input__prefix,
.select-output .el-input .el-input__suffix {
  display: none !important;
}
::v-deep .el-select .el-input.is-disabled .el-input__inner:hover {
  border: 0;
}
.manualControl {
  ::v-deep .sourceModule {
    margin-top: 0;
  }
  ::v-deep .right-top {
    padding-top: 0;
  }
}
html {
  font-size: 100px;
}
.body {
  overflow: hidden;
}
.select-box {
  margin: 0 10px 0 0;
  max-width: 300px;
}
.container-fluid {
  color: #dbdbdb;
  padding-left: 0;
  padding-right: 0;
  height: 100%;
}
.header {
  height: 80px;
  background-color: #33ab4f;
  text-align: center;
}
.body-left {
  width: 425px;
  float: left;
  text-align: center;
  background-color: #252525;
  // position: fixed;
  // top: 145px;
  min-height: 1000px;
  z-index: 200;
  &.bg333 {
    background: #333;
  }
  .listBox {
    position: relative;
    z-index: 1005;
  }
}
.min-height {
  // overflow: hidden;
}
.body-right {
  width: calc(100% - 425px);
  float: left;
  background-color: #252525;
  right: 0;
}
.right-bottom {
  margin-bottom: 40px;
}
.right-top {
  height: 440px;
  padding-top: 25px;
}
.liveInfo-module {
  height: 60px;
  text-align: center;
}
.video-module {
  width: 6.64rem;
  height: 3.73rem;
  float: left;
  text-align: left;
  .video-top {
    height: 40px;
    line-height: 40px;
    .source-name {
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      margin-right: 20px;
      max-width: 165px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .rListSelect {
    width: 165px;
  }
  .select-output {
    width: 100px;
    height: 35px;
    font-size: 17px;
    float: left;
    position: relative;
    .shareBtn {
      position: absolute;
      top: 40px;
      left: 202px;
      z-index: 4;
    }
    .sharingNum.globalNum {
      bottom: 13px;
      right: 5px;
      font-size: 12px;
    }
    .icon-share::before {
      font-size: 20px;
      vertical-align: middle;
    }
    .shareNotAllowed {
      opacity: 0.6;
      cursor: not-allowed;
    }
    .select-module {
      width: 280px;
      height: 40px;
      background: #444;
      position: absolute;
      left: 0;
      top: 40px;
      z-index: 4;
      ::v-deep .el-select__input {
        color: #fff;
        &::-webkit-input-placeholder {
          color: transparent;
        }
      }
      ::v-deep .el-input__inner {
        border: none;
        background: none;
        &::-webkit-input-placeholder {
          color: #666;
        }
      }
      ::v-deep .el-select-dropdown {
        text-align: left;
        left: 0px !important;
        position: absolute !important;
        width: 280px;
        .el-select-dropdown__wrap {
          max-height: 320px !important;
          .el-select-dropdown__item {
            span {
              display: inline-block;
              max-width: 80%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        .el-checkbox {
          left: 20px;
          margin-top: 0;
        }
      }
      ::v-deep .el-tag {
        max-width: 120px;
        .el-select__tags-text {
          display: inline-block;
          max-width: 70px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .el-tag__close.el-icon-close {
          top: -7px;
        }
      }
    }
    .loadingBox {
      height: 360px;
      width: 280px;
      position: absolute;
      top: 43px;
      background-color: hsla(0, 0%, 40%, 0.6);
      z-index: 10;
      // opacity: .3;
      color: #33ab4f;
      text-align: center;
      padding-top: 100%;
    }
  }
}
.video-module-8 {
  // width: 6.64rem;
  width: 664px;
  height: 3.73rem;
}
.video-module-16 {
  width: 5.92rem;
  height: 3.33rem;
  line-height: 3.33rem;
}
.audio-module {
  height: 373px;
  float: left;
  text-align: center;
  line-height: 373px;
  margin-left: 0.1rem;
  margin-right: 8px;
  margin-top: 44px;
}
.status-module {
  width: 2.5rem;
  height: 4.5rem;
  float: left;
  text-align: center;
  line-height: 4.5rem;
  padding: 0px 0.15rem 0 0;
  min-width: 208px;
}

// media相关
.shareToggleIcon {
  position: absolute;
  top: 0;
  left: 0.2rem;
  height: auto;
  line-height: 30px;
  .i-share:before {
    font-size: 30px;
  }
  .sharingNum.globalNum {
    bottom: 23px;
  }
}
.quality-Bitrate-module {
  width: 3.52rem;
  max-width: 5rem;
  float: left;
  padding-left: 0.2rem;
  margin-right: 0.2rem;
  position: relative;
}
.quality-Bitrate-module-8 {
  width: 3.8rem;
}
.quality {
  height: 2.5rem;
  background-color: #757171;
  text-align: center;
  line-height: 2.5rem;
}
.bitrate {
  height: 1rem;
  background-color: #c9c9c9;
  text-align: center;
  line-height: 1rem;
}
.delay {
  height: 1rem;
  background-color: #ededed;
  text-align: center;
  line-height: 1rem;
}
.slot-module {
  height: 4.3rem;
  text-align: center;
  max-height: 5rem;
}
.featureControlBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
  background: #444444;
}
/*Linda add*/
.right-bottom {
  /*overflow: hidden;*/
  float: left;
  width: 100%;
  padding-left: 30px;
}
.el-menu-demo {
  overflow: hidden;
}

@media screen and (min-width: 1600px) and (max-width: 1700px) {
  .quality-Bitrate-module {
    width: 3.6rem;
  }
}
@media screen and (min-width: 1500px) and (max-width: 1600px) {
  .video-module {
    width: 6.4rem;
    height: 3.6rem;
    // line-height: 3.6rem;
  }
  .quality-Bitrate-module {
    width: 4rem;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1500px) {
  // .body-right {
  //     width: 77%;
  // }
}
.graduateModule {
  width: 47px;
  height: 373px;
  float: left;
  margin-left: 5px;
  margin-top: 44px;
}
.graduateModule-bg {
  background: url('../../assets/img/graduateG.png') no-repeat;
  height: 100%;
  background-position: right;
}
.top {
  height: 65px;
  background: #2f2f2f;
  font-size: 16px;
  // position: relative;
  // position: fixed;
  top: 80px;
  // width: 100%;
  // z-index: 100;
}
.left-box {
  float: left;
  text-align: left;
  padding-left: 20px;
  overflow: hidden;
  height: 100%;
  width: 425px;
  cursor: pointer;
  > span {
    // position: absolute;
    // top: 32px;
    position: relative;
    top: 35px;
    font-size: 18px;
    font-weight: bold;
  }
  i {
    margin-right: 5px;
    color: #33ab4f;
    &.list-change-icon1:before {
      content: '\e69a';
    }
    &.list-change-icon2:before {
      content: '\e69d';
    }
  }
  .title {
    overflow: hidden;
    width: 200%;
    margin-left: -100%;
  }
  .receiverBtn {
    color: #999;
    background: #2f2f2f;
    position: relative;
    left: -100%;
    top: 35px;
    display: inline-block;
    z-index: 2000;
    font-size: 18px;
  }
}
.right-box {
  margin-right: 32px;
  margin-top: 20px;
  .el-button {
    padding: 0;
    width: 92px;
    height: 32px;
    font-size: 12px;
    &:last-child {
      margin-left: 20px;
      background: none;
      color: #fff;
    }
  }
}
.remainBox {
  float: right;
  margin-right: 20px;
  margin-top: 32px;
}
.bg {
  background: none;
  box-shadow: none;
}
.el-select {
  line-height: normal;
}
.el-popper[x-placement^='bottom'] .popper__arrow {
  border-bottom-color: #333;
}
.el-popper[x-placement^='bottom'] .popper__arrow:after {
  border-bottom-color: #333;
}
.bottom-header .el-menu.el-menu--horizontal {
  border-color: #666;
}

// ashok添加自适应代码
::v-deep .qulityWidth {
  width: 75%;
  max-width: 375px;
  min-width: 337px;
}
@media screen and (min-width: 1600px) and (max-width: 1919px) {
  // .body-right{
  //     width: 76.2%;
  // }
  // .body-left{
  //     width: 23.8%;
  // }
  .right-top {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }

  .quality-Bitrate-module {
    float: right;
    // width: auto;
    flex: 1 1 auto;
  }
  .video-module {
    // width: auto;
    height: 3.6rem;
    line-height: 0.2rem;
  }

  .status-module {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    margin-left: 30px;
    .container-fluid {
      max-width: 100%;
    }
  }

  .container-fluid {
    width: 100%;
  }
}
// @media screen and (min-width: 300px) and (max-width: 760px) {
//     .body-left{
//         width: 100%;
//         min-width:100%;
//         min-height: auto;
//     }
//     .body-right {
//         width: 100%;
//         float: left;
//     }
// }

.timeDisplay {
  float: right;
  padding-top: 22px;
  font-style: normal;
  color: #fff;
}

@media screen and (min-width: 1280px) and (max-width: 1599px) {
  // .body-left{
  //     width: 28.8%;
  // }
  // .body-right{
  //     width: 71.2%;
  // }
  .quality-Bitrate-module {
    width: 48%;
    margin-right: 60px;
  }
  .status-module {
    width: 37%;
    float: left;
    height: auto;
    .container-fluid {
      margin-top: 0;
      max-width: 100%;
    }
  }
  .video-module-8 {
    height: auto;
    width: 664px;
    margin-bottom: 40px;
    // float: right;
  }

  // .receviceBox{
  //     left: -25.8%;
  // }
  .w_20 {
    width: 21%;
  }
  .left-box .receiverBtn {
    padding-left: 0px;
  }
  .right-bottom {
    padding-left: 15px;
  }
}

@media screen and (min-width: 760px) and (max-width: 1095px) {
  .right-top {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    padding-right: 5px;
  }

  .audioSection {
    order: 2;
    margin-left: -30px;
  }

  .video-module-8 {
    order: 1;
    flex: 0 0 100%;
    width: 100%;
    height: auto;
    margin-top: 5px;
  }
  .quality-Bitrate-module {
    width: 100%;
    order: 4;
    flex: 0 0 100%;
    margin: 10px 0;
    height: 440px;
    padding-left: 0;
    max-width: 100%;
    padding-right: 32px;
  }
  .status-module {
    order: 3;
    width: 53%;
    // float: right;
    margin-left: 55px;
    height: auto;
    .container-fluid {
      margin-top: 10px;
      margin-left: 0;
    }
    ::v-deep .quality {
      height: 25px;
      line-height: 25px;
    }
  }

  .videoBox {
    width: 100% !important;
  }
  ::v-deep .right-bottom {
    padding-left: 0;
    .configtIcon {
      margin-right: 0;
    }
  }
  ::v-deep .selectBox {
    .dropdown-menu {
      left: auto;
      right: 0;
    }
  }

  .graduateModule {
    margin-top: 14px;
  }
  .audio-module {
    margin-top: 14px;
  }
}

@media screen and (min-width: 1096px) and (max-width: 1279px) {
  .right-top {
    display: block;
    height: auto;
  }
  .quality-Bitrate-module {
    width: 48%;
    height: 460px;
    margin-right: 60px;
    min-width: 340px;
  }
  .status-module {
    width: 46%;
    float: left;
    height: auto;
    .container-fluid {
      margin-top: 0;
      margin-left: 0;
    }
  }
  .video-module-8 {
    height: auto;
    width: calc(100% - 195px);
    margin-bottom: 40px;
    // float: right;
  }
  .w_20 {
    width: 21%;
  }
  .left-box .receiverBtn {
    padding-left: 0px;
  }
}

@media screen and (min-width: 500px) and (max-width: 759px) {
  .right-top {
    height: 100%;
    padding-top: 25px;
    display: flex;
    flex-wrap: wrap;
  }
  .video-module-8 {
    width: 100%;
    height: 100%;
    order: 1;
  }
  .quality-Bitrate-module {
    width: 100%;
    padding-left: 0;
    margin-right: 0;
    order: 4;
    max-width: 100%;
  }
  .status-module {
    height: 100%;
    float: none;
    text-align: center;
    line-height: normal;
    padding: 0px 5px;
    min-width: auto;
    order: 3;
    flex: 0 0 60%;
    margin-bottom: 30px;
    margin-left: 20px;
    ::v-deep .quality {
      height: 25px;
      line-height: 25px;
    }
  }

  .audioSection {
    order: 2;
    margin-left: -20px;
  }

  .audio-module {
    height: 100%;
    text-align: center;
    line-height: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 44px;
  }

  .videoBox {
    width: 100% !important;
  }

  .right-bottom {
    padding-left: 0px;
    margin-top: 15px;
  }

  .left-box .receiverBtn {
    left: -30.8%;
    padding-left: 0px;
  }

  .body-right {
    padding: 0px 10px;
  }

  #quality {
    width: 100% !important;
  }
  ::v-deep #FeatureModule {
    // width: 80%;
  }
  ::v-deep .right-bottom {
    // position: relative;
    // .adapter-group{
    //     position: absolute;
    //     bottom: 0;
    //     right: 0;
    // }
  }
  ::v-deep .selectBox {
    .dropdown-menu {
      left: auto;
      right: 0;
    }
  }
}
</style>
