import { render, staticRenderFns } from "./EditVideo.vue?vue&type=template&id=f3e2b5ce&scoped=true"
import script from "./EditVideo.vue?vue&type=script&lang=js"
export * from "./EditVideo.vue?vue&type=script&lang=js"
import style0 from "./EditVideo.vue?vue&type=style&index=0&id=f3e2b5ce&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3e2b5ce",
  null
  
)

export default component.exports