<template>
  <div
    class="deviceInfo"
    @mouseleave="fn($event,{},'hide')">
    <div class="name option">
      <span :class="{hide:deviceInfoObj.showInfoObj.showName}">{{ $t('lang.name') }}</span>
      <span class="rName">{{deviceInfoObj.contentInfoObj.name}}</span>
      <span>-</span>
      <span>{{deviceInfoObj.contentInfoObj.id}}</span>
      <span v-if="deviceInfoObj.contentInfoObj.transportType == 2">(IS+)</span>
    </div>
    <div
      class="platform option"
      :class="{hide:deviceInfoObj.showInfoObj.showPlatform}">
      <span>{{ $t('lang.platform') }}</span>
      <span>{{deviceInfoObj.contentInfoObj.platform}}</span>
    </div>
    <div
      class="version option"
      :class="{hide:deviceInfoObj.showInfoObj.showVersion}">
      <span>{{ $t('lang.version') }}</span>
      <span>{{deviceInfoObj.contentInfoObj.version}}</span>
    </div>
    <div
      class="live option"
      :class="{hide:deviceInfoObj.showInfoObj.showLivePeerId}">
      <span>{{ $t('lang.live') }}</span>
      <div class="hide testDevice">{{ deviceInfoObj.contentInfoObj.id.toUpperCase() }} - {{ this.$store.state.GetKernelStateObj.CurrentTStrIdHex }}</div>
      <span v-if="deviceInfoObj.contentInfoObj.workMode && (deviceInfoObj.contentInfoObj.id.toUpperCase() == this.$store.state.GetKernelStateObj.CurrentTStrIdHex)">Live with this R</span>
      <span v-if="deviceInfoObj.contentInfoObj.rName && this.$store.state.ReceiverInfoObj.Name != deviceInfoObj.contentInfoObj.rName">Live with Receiver {{deviceInfoObj.contentInfoObj.rName}}</span>
    </div>
    <div
      class="battery option clearfix"
      :class="{hide:deviceInfoObj.showInfoObj.showBattery}">
      <span class="f_left">{{ $t('lang.battery') }}</span>
      <span class="f_left w_5">1:</span>
      <div class="battery1-box f_left">
        <span
          class="batterySpan f_left greenColor"
          v-if="batteryObj.isShowSpan1">N/A</span>
        <div
          class="battery-moudel f_left"
          v-else
          v-show="isShowBattery1">
          <div class="battery-dot"></div>
          <div class="battery1-value"></div>
        </div>
      </div>
      <span class="f_left w_5">2:</span>
      <div class="battery2-box f_left">
        <span
          class="batterySpan f_left greenColor"
          v-if="batteryObj.isShowSpan2">N/A</span>
        <div
          class="battery-moudel f_left"
          v-else
          v-show="isShowBattery2">
          <div class="battery-dot"></div>
          <div class="battery2-value"></div>
        </div>
      </div>
    </div>
    <div
      class="input option"
      :class="{hide:deviceInfoObj.showInfoObj.showInput}">
      <span>Input</span>
      <span>{{deviceInfoObj.contentInfoObj.input}}</span>
    </div>
    <div
      class="format option"
      :class="{hide:deviceInfoObj.showInfoObj.showFormat}">
      <span>Format</span>
      <span>{{deviceInfoObj.contentInfoObj.format}}</span>
    </div>
    <div
      class="codec option"
      :class="{hide:deviceInfoObj.showInfoObj.showCodec}">
      <span>Codec</span>
      <span>{{deviceInfoObj.contentInfoObj.codec}}</span>
    </div>
    <div
      class="uploader option"
      :class="{hide:deviceInfoObj.showInfoObj.showUploader}">
      <span>Uploader</span>
      <span>{{deviceInfoObj.contentInfoObj.Uploader}}</span>
    </div>
    <div
      class="title option"
      :class="{hide:deviceInfoObj.showInfoObj.showTitle}">
      <span>Title</span>
      <span>{{deviceInfoObj.contentInfoObj.FullTitle}}</span>
    </div>
    <div
      class="title option"
      :class="{hide:deviceInfoObj.showInfoObj.showCheckStatus}">
      <span>Check Status</span>
      <span>{{deviceInfoObj.contentInfoObj.IsCheckStatus}}</span>
    </div>
    <div
      class="title option"
      :class="{hide:deviceInfoObj.showInfoObj.showEncoder}">
      <span>Encoder</span>
      <span>{{deviceInfoObj.contentInfoObj.GridEncoderStatus}}</span>
    </div>
    <div
      class="title option"
      :class="{hide:deviceInfoObj.showInfoObj.showDirect}">
      <span>Direct</span>
      <span>{{deviceInfoObj.contentInfoObj.IsDirectConnection}}</span>
    </div>
    <div
      class="title option"
      :class="{hide:deviceInfoObj.showInfoObj.showRelay}">
      <span>Relay</span>
      <span>{{deviceInfoObj.contentInfoObj.XAlive}}</span>
    </div>
    <div
      id="channels"
      class="title option"
      :class="{hide:deviceInfoObj.showInfoObj.showChannel}">
      <div>Channels:</div>
      <div
        v-for="(item,index) in deviceInfoObj.gridChannels"
        :key="index">
        <span>{{index + 1}}.</span>
        <span>{{item.Type}}</span>
        <span>{{item.RealAddress}}</span>
        <span>Direct</span>
        <span>{{item.IsDirectConnection == true?"True":"False"}}</span>
        <span>Relay</span>
        <span>{{deviceInfoObj.contentInfoObj.XAlive}}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'videoInfo',
  props: {
    deviceInfoObj: Object,
    batteryObj: Object,
    fn: Function,
    isShowBattery1: Function,
    isShowBattery2: Function
  },
  data () {
    return {}
  },
  watch: {
    demo (val) {
      this.value = this.demo
    }
  },
  created (event) { }
}
</script>
<style lang="less" scoped>
.deviceInfo {
  background: #444;
  text-align: left;
  border: 1px solid #fff;
  padding: 5px;
  width: 450px;
  font-size: 13px;
  .rName {
    word-wrap: break-word;
    word-break: normal;
  }
}
.deviceInfo div span:nth-child(1) {
  width: 20%;
  display: inline-block;
}
</style>

<style lang="less" scoped>
.battery1-box,
.battery2-box {
  width: 15%;
  text-indent: 12px;
}
.battery-moudel {
  border: 1px solid #5cb85c;
  height: 14px;
  width: 70%;
  padding: 2px 2px;
  position: relative;
}
.battery1-box .battery-moudel {
  margin-left: 3px;
}
.battery-dot {
  position: absolute;
  top: 3px;
  right: -5px;
  border: 1px solid #5cb85c;
  border-left: 0px;
  height: 5px;
  width: 4px;
}
.battery1-value,
.battery2-value {
  display: block;
  background: #5cb85c;
  height: 8px;
  width: 70%;
}
</style>
