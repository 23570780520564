<template>
  <div class="container-fluid">
    <div class="audio-right">
      <div class="row channel">
        <div
          v-for="(item,index) in audioNumber"
          :key="item"
          class="f_left"
          :class="audioNumber|is16Channel">
          <div class="channel-bg">
            <div
              class="audioValue"
              :class="'audioValue' + (index + 1 )"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import { mapState } from 'vuex'
import Bus from '@/assets/js/vueBus.js'
import { Receiver, receviceParams } from '@/assets/api/common/receiver.js'
export default {
  name: 'Audio',
  data () {
    return {
      audioNumber: [1, 2],
      audioObj: {}
    }
  },
  computed: {
    ...mapState({
      State: state => state
    })
  },
  created () {
    Bus.$off('initRList')
    Bus.$on('initRList', () => {
      if (!this.State.selectRId) return
      this.getAudioNumber()
    })
    Bus.$off('selectOtherR')
    Bus.$on('selectOtherR', () => {
      if (!this.State.selectRId) return
      $('.audio-module .audioValue').css('height', 0)
      this.getAudioNumber()
    })
    Bus.$off('changeVoice')
    Bus.$on('changeVoice', (data) => {
      if (!this.State.selectRId) return
      this.getVolume(data)
    })
  },
  methods: {
    getAudioNumber () {
      let array = []
      let params = Object.assign({ rid: this.State.selectRId.toLowerCase() }, receviceParams.Channel.channelNumber)
      this.axios.post(Receiver().Channel.channelNumber, params).then(res => {
        if (res.status == 200 && res.data.errorCode == '0x0') {
          if (!JSON.parse(res.data.result).errorCode) {
            let result = JSON.parse(res.data.result)
            let ChannelCount = result.ChannelCount
            array = ChannelCount
            this.$store.state.channelNumber = array
            this.audioNumber = array
          }
        } else {
          this.audioNumber = 2
        }
      }).catch(() => {})
    },
    getVolume (data) {
      let result = JSON.parse(data)
      const channelList = result.DB
      if (channelList) {
        $.each(channelList, function (index, item) {
          $('.audio-right .audioValue').eq(index).css('height', (item - (-40)) / 40 * 405)
        })
      } else {
        $('.audio-right .audioValue').eq(0).css('height', (result.RightDb - (-40)) / 40 * 405)
        $('.audio-right .audioValue').eq(1).css('height', (result.LeftDb - (-40)) / 40 * 405)
      }
    }
  },
  filters: {
    is16Channel: function (audioNumber) {
      var _str = ''
      audioNumber > 8 ? _str = 'audioBox-16' : _str = 'audioBox'
      return _str
    }
  }
}
</script>
<style scoped>
.container-fluid {
  color: #dbdbdb;
  max-width: 275px;
  height: 430px;
  overflow: hidden;
}
.audio-left {
  height: 100%;
  width: 30%;
  text-align: right;
  padding-right: 10px;
}
.audio-right {
  height: 100%;
  width: 100%;
}
.channel {
  margin-left: 0;
  margin-right: 0;
  height: 100%;
}
.audioValue {
  width: 100%;
  background: url('../../assets/img/green.png') repeat-y scroll center 0
    transparent;
  position: absolute;
  bottom: 0;
  background-position: 0px 4px;
  left: 12%;
}
.channel-bg {
  height: 373px;
  background: url('../../assets/img/sound.png') repeat-y scroll center 0
    transparent;
  position: relative;
  bottom: 0;
  overflow: hidden;
  width: 5px;
  margin: 0 auto;
}
.audio-left div:nth-child(2) {
  margin-top: 82px;
}
.audio-left div:nth-child(3n) {
  margin-top: 47px;
}
.audio-left div:nth-child(4) {
  margin-top: 156px;
}
.channelNumber {
  height: 20px;
  line-height: 20px;
  margin-top: 5px;
  font-size: 12px;
}
.audioBox-16 {
  width: 8px;
}
.audioBox {
  width: 8px;
}
</style>
