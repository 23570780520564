<template>
  <div class="container-fluid">
    <div class="quality grayColor font_18">{{$t('lang.Details')}}</div>
    <div class="quality-row">
      <div class="quality">
        <span class="common">{{$t('lang.RVersion')}}</span>
        <span class="grayColor f_right w_38 text_left">{{ReceiverInfoObj.ProductBuildVersion}}</span>
      </div>
      <div class="quality">
        <span class="common">{{$t('lang.RPeerID')}}</span>
        <span class="grayColor f_right w_38 text_left">{{ReceiverInfoObj.StrId}}</span>
      </div>
      <div class="quality">
        <span class="common">{{$t('lang.ErrorRate8s')}}</span>
        <span class="grayColor f_right w_38 text_left">{{ReceiverInfoObj.ErrorRateLastNSec?ReceiverInfoObj.ErrorRateLastNSec+'%':0+'%'}}</span>
      </div>
      <div class="quality">
        <span class="common">{{$t('lang.ErrorRate1s')}}</span>
        <span class="grayColor f_right w_38 text_left">{{ReceiverInfoObj.ErrorRateLastSec?ReceiverInfoObj.ErrorRateLastSec+'%':0+'%'}}</span>
      </div>
    </div>
    <div class="quality-row">
      <div class="quality">
        <span class="common">{{$t('lang.LineQuality8s')}}</span>
        <span class="grayColor f_right w_38 text_left">{{ReceiverInfoObj.ReSendRateLastNSec?ReceiverInfoObj.ReSendRateLastNSec+'%':0+'%'}}</span>
      </div>
      <div class="quality">
        <span class="common">{{$t('lang.LineQuality1s')}}</span>
        <span class="grayColor f_right w_38 text_left">{{ReceiverInfoObj.ReSendRateLastSec?ReceiverInfoObj.ReSendRateLastSec+'%':0+'%'}}</span>
      </div>
      <div class="quality">
        <span class='f_left w_41'>{{$t('lang.TBattery')}}</span>
        <div class='f_left clearfix'>
          <div
            class="bat_item f_left"
            v-for='(itm, idx) in batteryArr'
            :key='idx'>
            <div class="f_left num">{{idx+1}}:</div>
            <div
              class="bat_img f_left pr"
              :class='itm.img'
              v-if='itm.img'>
              <div class="bat_process">
                <div
                  class="bat_process_color"
                  :style='{width: itm.batVal}'
                  :class='itm.proColor'></div>
              </div>
              <div
                class="f_left bat_warn_icon battery_isCharge"
                v-if='itm.isCharge'></div>
            </div>
            <span class="f_left bat_num">{{itm.batVal}}</span>
          </div>
        </div>
      </div>
      <div class="quality">
        <span class="common">{{$t('lang.TInput')}}</span>
        <span class="grayColor f_right w_38 text_left">{{GetKernelStateObj.CurrentTInputType?GetKernelStateObj.CurrentTInputType.ReadableName:'N/A'}}</span>
      </div>
    </div>
    <div class="quality-row">
      <div class="quality">
        <span class="common">{{$t('lang.TFormat')}}</span>
        <span class="grayColor f_right w_38 text_left">{{GetKernelStateObj.CurrentTFormat?GetKernelStateObj.CurrentTFormat.ReadableName:'N/A'}}</span>
      </div>
      <div class="quality">
        <span class="common">{{$t('lang.RFormat')}}</span>
        <span class="grayColor f_right w_38 text_left">{{GetKernelStateObj.CurrentOutputFormat2?GetKernelStateObj.CurrentOutputFormat2.ReadableName:'NTSC'}}</span>
      </div>
      <div
        class="quality"
        :class="{visibility:!ReceiverInfoObj.IsWindows}">
        <span class="common">{{$t('lang.IFB')}}</span>
        <span class="grayColor f_right w_38 text_left">{{ReceiverInfoObj.IsIFBOn?'On':'Off'}}</span>
      </div>
      <div class="quality">
        <span class="common">{{$t('lang.IFBSignal')}}</span>
        <span class="grayColor f_right w_38 text_left">{{GetKernelStateObj.IsIFBOn?'On':'Off'}}</span>
      </div>
    </div>
    <div class="quality-row">
      <div class="quality">
        <span class="common">{{$t('lang.VoIPSignal')}}</span>
        <span class="grayColor f_right w_38 text_left">{{GetKernelStateObj.IsIFBOn?'On':'Off'}}</span>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import { mapState } from 'vuex'
import { Receiver, receviceParams } from '@/assets/api/common/receiver.js'
import showBattery from '@/assets/js/common/handleBattery'

export default {
  name: 'Status',
  data () {
    return {
      totalQualityArray: []
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      SelectRId: state => state.selectRId,
      TestDataUrl: state => state.testDataUrl,
      ReceiverInfoObj: state => state.ReceiverInfoObj,
      GetKernelStateObj: state => state.GetKernelStateObj,
      SettingObj: state => state.SettingObj,
      isShowSpan1: function () {
        if (!this.GetKernelStateObj.CurrentTPackBatteryRaw) return false
        let bat1 = this.GetKernelStateObj.CurrentTPackBatteryRaw.BAT1
        if (bat1 == -1 || bat1 == null || bat1 == '') {
          return true
        } else {
          return false
        }
      },
      isShowSpan2: function () {
        if (!this.GetKernelStateObj.CurrentTPackBatteryRaw) return false
        let bat2 = this.GetKernelStateObj.CurrentTPackBatteryRaw.BAT2
        if (bat2 == -1 || bat2 == null || bat2 == '') {
          return true
        } else {
          return false
        }
      },
      batteryArr: function () {
        if (!this.GetKernelStateObj.CurrentTPackBatteryRaw) return false
        return showBattery(this.GetKernelStateObj.CurrentTPackBatteryRaw, this.State.selectSourcePlatform)
      }
    })
  },
  mounted: function () {
  },
  methods: {
    // 获取播放相关信息
    getKernelStateInfo () {
      let params = Object.assign({ rid: this.SelectRId.toLowerCase() }, receviceParams.configJson.getVideoInfo)
      this.axios.post(Receiver().configJson.getVideoInfo, params)
        .then(res => {
          if (res.status == 200 && res.data != null) {
            let result = null
            if (res.data.queryKennel) {
              result = JSON.parse(res.data.queryKennel.data)
            } else {
              result = res.data
            }
            this.getKernelState = result
            if (this.getKernelState.IsPreview) {
              this.$store.state.isPreviewClip = true
            } else {
              this.$store.state.isPreviewClip = false
            }
            if (result.WebRVideoSolution == 300 && result.WorkMode != 1 && !this.$store.state.isPreviewClip && !this.$store.state.isFilePreview) {
              on_leave()
              this.$store.state.isFristInitWebrtc = true
            }
          }
        })
    },
    // 刷新I帧
    refreshIF () {
      this.axios.post('/apis/RequestIFRame.ashx').then(res => {}).catch(() => {})
    }
  }
}
</script>
<style lang="less" scoped>
.container-fluid {
  text-align: left;
  color: #dbdbdb;
  max-width: 290px;
  margin-top: 44px;
}
.battery1-box,
.battery2-box {
  width: 27%;
  text-indent: 12px;
}
.battery-moudel {
  border: 1px solid #5cb85c;
  margin: 5px 13px 0 7px;
  height: 14px;
  width: 70%;
  padding: 2px 2px;
  position: relative;
}
.battery1-box .battery-moudel {
  margin-left: 3px;
}
.battery-dot {
  position: absolute;
  top: 3px;
  right: -5px;
  border: 1px solid #5cb85c;
  border-left: 0px;
  height: 5px;
  width: 4px;
}
.battery1-value,
.battery2-value {
  display: block;
  background: #5cb85c;
  height: 8px;
  width: 70%;
}
.quality a {
  color: #fff;
}
.common {
  display: inline-block;
  width: 52%;
}
a.common {
  text-decoration: underline;
}
.statusBtn .dropdown-menu > li > a {
  color: #fff;
  display: inline-block;
  padding: 3px 15px 3px 10px;
  max-width: 95px;
}
.statusBtn .dropdown-menu {
  min-width: 125px;
}
.statusBtn .dropdown-menu > li {
  height: 26px;
  line-height: 26px;
}
.statusBtn .dropdown-menu > li:hover {
  background-color: #455f45;
}
.quality {
  height: 26px;
  line-height: 26px;
  font-size: 12px;
}
.quality:nth-child(3),
.quality:nth-child(7) {
  margin-bottom: 10px;
}
.statusBtn {
  margin-top: 10px;
  position: relative;
  width: 100%;
}
#totalQuality {
  height: 90px;
}
.checkBox {
  margin-left: 5px;
}
#basicModes-ul li {
  position: relative;
}
.w_38 {
  width: 48%;
}
@media screen and (min-width: 1600px) and (max-width: 1919px) {
  .quality-row {
    width: 25%;
    float: left;
    .quality {
      margin-bottom: 0;
    }
  }
  .common {
    width: 42%;
  }
  .quality-row .w_38 {
    width: 57%;
  }
}
</style>
<style lang="less">
.bat_img {
  background: url('../../assets/img/icon_view.png');
  background-repeat: no-repeat;
  height: 0.26rem;
  width: 0.36rem;
  .bat_process {
    height: 0.05rem;
    width: 45%;
    position: absolute;
    top: 0.09rem;
    left: 0.05rem;
  }
  .bat_process_color {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
.bat_item {
  margin-right: 0.1rem;
  .num {
    margin-right: 0.07rem;
  }
  .bat_num {
    color: #8c8a88;
  }
  .bat_warn_icon {
    background: url('../../assets/img/icon_view.png') no-repeat;
    margin-top: 0.05rem;
    margin-left: 0.03rem;
  }
  .battery_isCharge {
    position: absolute;
    background-position: -55px -78px;
    height: 0.2rem;
    width: 0.1rem;
    left: -0.1rem;
  }
}
.battery_green_icon {
  background-position: -46px -51px;
}
.battery_gray_icon {
  background-position: -46px -3px;
}
.battery_red_icon {
  background-position: -46px -27px;
}
.battery_external_icon {
  background-position: -46px -97px;
}
.grayBgColor {
  background-color: #999;
}
.redBgColor {
  background-color: #d13c3b;
}
.greenBgColor {
  background-color: #33aa50;
}
</style>
