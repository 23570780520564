<template>
  <el-dialog
    :title="$t('lang.editExtSource')"
    :visible.sync="info.showEditAlert"
    width="540px"
    center
    :modal="false"
    :close-on-click-modal="false"
    custom-class="greenColor">
    <div class="editExtBox">
      <div class="addExt-body">
        <ul class="addSourceOl">
          <li>
            <span class="f_left optionClass">{{$t('lang.Type')}}:</span>
            <span class="f_left">{{info.type}}</span>
          </li>
          <li class="m-b-20">
            <span class="f_left optionClass">{{$t('lang.name')}}:</span>
            <el-input
              class="f_left"
              v-model.trim="info.name"
              autocomplete="off"
              size="small "
              :maxlength="125"></el-input>
          </li>
          <li class="m-b-20">
            <span class="f_left optionClass">URL:</span>
            <el-input
              class="f_left"
              v-model.trim="info.url"
              autocomplete="off"
              size="small "
              :maxlength="125"></el-input>
            <button
              class="f_right greenBtn"
              :disabled="info.type === 'YouTube'?false:true"
              @click="analyzeEditUrl">{{$t(`lang.${addSourceValidate}`)}}</button>
          </li>
          <li class="m-b-20">
            <div class="f_left optionClass"></div>
            <button
              class="addSourceBtn greenBtn f_left"
              @click="saveEdit">{{$t('lang.Save')}}</button>
            <button
              class="addSourceBtn greenBtn f_left"
              @click="deleteSource">{{$t('lang.Delete')}}</button>
            <button
              class="cancelAddbtn greenBtn f_left"
              @click="info.showEditAlert = false">{{$t('lang.cancel')}}</button>
          </li>
        </ul>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { Receiver, receviceParams } from '@/assets/api/common/receiver.js'
import utils from '@/assets/js/utils.js'
export default {
  props: {
    info: Object,
    filterInfo: Function
  },
  data () {
    return {
      addSourceValidate: 'validate'
    }
  },
  created () {
  },
  methods: {
    // 保存修改信息`
    saveEdit () {
      let { name, url, id } = this.info
      let obj = {
        name,
        url,
        peerId: id
      }
      if (!name) {
        this.$message.error(this.$t('lang.nameRequired'))
        return
      }
      if (!url) {
        this.$message.error(this.$t('lang.urlRequired'))
        return
      }
      this.axios.post('/ccp/tvucc-user/ext/updateExt', obj)
        .then(res => {
          if (res.data.errorCode == '0x0') {
            this.$message({
              type: 'success',
              message: this.$t('lang.success')
            })
            this.info.showEditAlert = false
            this.filterInfo()
          } else {
            utils.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
          }
        }).catch(() => {})
    },
    // 删除source
    deleteSource () {
      this.axios.post('/ccp/tvucc-user/ext/deleteExt', { peerId: this.info.id })
        .then(res => {
          if (res.data.errorCode == '0x0') {
            this.info.showEditAlert = false
            this.$message({
              type: 'success',
              message: this.$t('lang.success')
            })
            this.filterInfo()
          } else {
            utils.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
          }
        }).catch(() => {})
    },
    // 解析修改youtube盒子里面的路径
    analyzeEditUrl () {
      let { url, id, rid } = this.info
      if (url == '') {
        this.$message.error(this.$t('lang.urlRequired'))
        return
      }
      if (this.addSourceValidate === 'validating') return
      this.addSourceValidate = 'validating'
      const data = {
        rid: rid,
        data: '{"IdHex":"' + id + '","Url":"' + url + '"}',
        timeout: 14000
      }
      let params = Object.assign(data, receviceParams.youTobeAnalyze.analyzeUrl)
      this.axios.post(Receiver().youTobeAnalyze.analyzeUrl, params, { timeoutTips: true })
        .then(res => {
          this.addSourceValidate = 'validate'
          if (res.data.errorCode == '0x0' && res.data.result == 'True') {
            this.$message({
              type: 'success',
              message: this.$t('lang.success')
            })
          } else if (res.data.result) {
            if (res.data.result == 'False') {
              this.$message({
                type: 'warning',
                message: this.$t('lang.invalidUrl')
              })
            } else if (JSON.parse(res.data.result).errorCode == '0x80520101') {
              this.$message({
                type: 'warning',
                message: this.$t('lang.moduleNotFound')
              })
            } else if (JSON.parse(res.data.result).errorCode == '0x80521202') {
              this.$message({
                type: 'warning',
                message: this.$t('lang.idNotFound')
              })
            } else {
              this.$message({
                type: 'warning',
                message: JSON.parse(res.data.result).errorInfo
              })
            }
          } else {
            utils.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
          }
        }).catch(() => {})
    }
  }
}
</script>
<style lang="less" scoped>
.editExtBox {
  li {
    margin-bottom: 10px;
    &.m-b-20 {
      margin-bottom: 10px;
    }
  }
  ::v-deep .el-input {
    height: 30px;
    width: 300px;
  }
  ::v-deep .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
}
</style>
